import fetch from "../utils/fetch";


// 检查资金密码
export function CheckPassword (data) {
  return fetch({
    url: '/api/user/check-pay-password',
    method: 'post',
    data
  })
}
// 设置资金密码
export function setSafePassword (data) {
  return fetch({
    url: '/api/user/set-pay-password',
    method: 'post',
    data
  })
}
// 修改资金密码
export function editSafePassword (data) {
  return fetch({
    url: '/api/user/update-pay-password',
    method: 'post',
    data
  })
}
// 修改登录密码
export function editPassword (data) {
  return fetch({
    url: '/api/user/update-password',
    method: 'post',
    data
  })
}
// 客服列表
export function kefuList(data) {
  return fetch({
    url: '/api/mobile/customer-list',
    method: 'post',
    data: data
  });
}
// 帮助列表
export function helpList(data) {
  return fetch({
    url: '/api/mobile/help-lists',
    method: 'post',
    data: data
  });
}
// 新增地址
export function addAddress(data) {
  return fetch({
    url: '/api/user/shipping-address-add',
    method: 'post',
    data: data
  });
}
// 地址列表
export function getAddressList(data) {
  return fetch({
    url: '/api/user/shipping-address-lists',
    method: 'post',
    data: data
  });
}
// 删除地址
export function removeAddress(data) {
  return fetch({
    url: '/api/user/shipping-address-delete',
    method: 'post',
    data: data
  });
}
// 设为默认地址
export function addressSelect(data) {
  return fetch({
    url: '/api/user/shipping-address-select',
    method: 'post',
    data: data
  });
}
// 积分是否可以领取
export function integralCheck(data) {
  return fetch({
    url: '/api/mobile/check-integral',
    method: 'post',
    data: data
  });
}
// 积分领取
export function addIntegralLog(data) {
  return fetch({
    url: '/api/mobile/IntegralRecord-add',
    method: 'post',
    data: data
  });
}