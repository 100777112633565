<template>
  <div class="top-bar-main flex-center-between" >
    <div class="left flex-center">
      <Icon name="arrow-left" :color="isWhite ? '#000' : '#fff'" size="20" @click="back" v-show="!hideBack"></Icon>
    </div>

    <div class="title">{{title}}</div>

    <div class="flex-center right">
      <Icon name="bars" color="#000" size="20" @click="rightClick" v-show="rightIcon == 'category'"></Icon>
    </div>
  </div>
</template>
<script>
import { Icon } from 'vant'
export default {
  components: {
    Icon
  },
  props: {
    title: String,
    rightText: String,
    rightIcon: String,
    parentId: String,
    isWhite: Boolean,
    hideBack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // logUrl: require('../../assets/imgs/icon-log2.png'),
      // logUrl2: require('../../assets/imgs/icon-money4.png'),
      animateStop: false,
      clearStyle: false
    }
  },
  computed: {
    screenScale() {
      return this.$store.state.screenScale
    }
  },
  mounted() {
  },
  methods: {
    back() {
      this.$emit('back')
    },
    rightClick() {
      this.$emit('detail')
    },
  }
}
</script>