<template>
  <div class="search-page">
    <div class="top flex-center">
      <Icon name="arrow-left" size="20" @click="back"></Icon>
      <div class="flex-1 flex-center-start box">
        <div class="type flex-center">
          <Popover v-model="showPopover" trigger="click" :actions="typeList" >
            <div class="popover-type-list">
              <div class="flex-center list" :class="item.value == form.searchType ? 'active' : ''" v-for="(item, i) in typeList" :key="i" @click="changeType(item)">
                {{item.text}}
                <Icon name="success" v-if="item.value == form.searchType"></Icon>
              </div>
            </div>
            <template #reference>
              <div class="flex-center">
                <span>{{form.typeName}}</span>
                <Icon name="arrow-up" size="16" :class="showPopover ? 'show' : ''"></Icon>
              </div>
            </template>
          </Popover>
        </div>
        <div class="input">
          <Search  v-model="form.keyword" :placeholder="$t('sou-suo-shang-pin-dian-pu')" @search="submit"/>
        </div>
      </div>
    </div>

    <div class="history">
      <div class="flex-center-between">
        <div class="title">
          {{ $t('li-shi-sou-suo') }} </div>
        <div @click="removeHistory" class="icon">
          <Icon name="delete-o" size="17" ></Icon>
        </div>
      </div>
      <div class="list flex-wrap">
        <div class="tag" v-for="(item, i) in historyList" :key="i" @click="toResult(item)">{{item}}</div>
      </div>

      <div class="flex-center-between mt-10">
        <div class="title">
          {{ $t('re-men-sou-suo') }} </div>
      </div>
      <div class="list flex-wrap">
        <div class="tag" v-for="(item, i) in historyList" :key="i" @click="toResult(item)">{{item}}</div>
      </div>
    </div>

    <Popup v-model="showPopup">
      <div class="msg-modal">
        <div class="title">{{ $t('ti-shi-0') }}</div>
        <div class="msg">{{ $t('que-ding-shan-chu-li-shi-ji-lu-ma') }}</div>
        <div class="btn-style">
          <Button @click="cancel">{{ $t('qu-xiao-0') }}</Button>
          <Button class="blue" @click="toRemove">{{ $t('que-ding-0') }}</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Icon, Popover , Search, Popup, Button  } from 'vant'
export default {
  components: {
    Icon,
    Popover,
    Search,
    Popup,
    Button
  },
  data() {
    return {
      showPopover: false,
      showPopup: false,
      pageType: 1,
      historyList: [],
      form: {
        searchType: '1',
        keyword: '',
        typeName: this.$t('shang-pin-0')
      },
    }
  },
  computed: {
    typeList() {
      return [
        {
          text: this.$t('shang-pin-1'),
          value: '1'
        },
        {
          text: this.$t('dian-pu-0'),
          value: '2'
        },
      ]
    }
  },
  mounted() {
    let type = this.$route.query.type
    this.pageType = type || 1
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeType(data) {
      this.form.typeName = data.text
      this.form.searchType = data.value
      this.showPopover = false
    },
    submit() {
      if (this.form.keyword) {
        this.$router.push({
          name: 'searchResult',
          query: {
            keyword: this.form.keyword,
            type: this.form.searchType
          }
        })
      }
    },
    removeHistory() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    toRemove() {
      this.showPopup = false
    },
    toResult(data) {
      this.$router.push({
        name: 'searchResult',
        query: {
          keyword: data,
          sear: this.form.searchType
        }
      })
    }
  }
}
</script>