import fetch from "../utils/fetch";

// 行业列表
export function industryList(data) {
  return fetch({
    url: '/api/user/industry-list',
    method: 'post',
    data: data
  });
}
// 申请开店
export function openShop(data) {
  return fetch({
    url: '/api/user/merchant-application-add',
    method: 'post',
    data: data
  });
}
// 商品评论列表
export function productEvaluteList(data) {
  return fetch({
    url: '/api/user/business-product-reviews-lists',
    method: 'post',
    data: data
  });
}
// 我的评论
export function myEvalute(data) {
  return fetch({
    url: '/api/user/business-product-ratings-lists',
    method: 'post',
    data: data
  });
}
// 新增订单评论
export function addEvalute(data) {
  return fetch({
    url: '/api/user/business-product-ratings-add',
    method: 'post',
    data: data
  });
}
// 新增商品评论
export function addGoodsEvalute(data) {
  return fetch({
    url: '/api/user/business-product-reviews-add',
    method: 'post',
    data: data
  });
}
// 加入购物车
export function addCar(data) {
  return fetch({
    url: '/api/user/shopping-cart-add',
    method: 'post',
    data: data
  });
}
// 购物车列表
export function carList(data) {
  return fetch({
    url: '/api/user/shopping-cart-list',
    method: 'post',
    data: data
  });
}
// 删除购物车
export function removeCar(data) {
  return fetch({
    url: '/api/user/shopping-cart-delete',
    method: 'post',
    data: data
  });
}
// 下单
export function addOrder(data) {
  return fetch({
    url: '/api/user/order-add',
    method: 'post',
    data: data
  });
}
// 订单支付
export function orderPay(data) {
  return fetch({
    url: '/api/user/order-pay',
    method: 'post',
    data: data
  });
}
// 订单列表
export function getOrderList(data) {
  return fetch({
    url: '/api/user/order-lists',
    method: 'post',
    data: data
  });
}
// 订单取消
export function doCancelOrder(data) {
  return fetch({
    url: '/api/user/order-cancel',
    method: 'post',
    data: data
  });
}
// 种草列表
export function getShopList(data) {
  return fetch({
    url: '/api/user/shop-list',
    method: 'post',
    data: data
  });
}
// 关注列表
export function getFollowList(data) {
  return fetch({
    url: '/api/user/shop-follow-lists',
    method: 'post',
    data: data
  });
}
// 关注店铺
export function followShop(data) {
  return fetch({
    url: '/api/user/shop-follow-add',
    method: 'post',
    data: data
  });
}

// 消息列表
export function messageList(data) {
  return fetch({
    url: '/api/user/message-list',
    method: 'post',
    data: data
  });
}
// 对话列表
export function conversationList(data) {
  return fetch({
    url: '/api/user/conversation-list',
    method: 'post',
    data: data
  });
}
// 消息已读
export function messageRead(data) {
  return fetch({
    url: '/api/user/message-read',
    method: 'post',
    data: data
  });
}