<template>
  <div class="find-page">
    <div class="top flex">
      <div class="tab flex-column-center" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">
        <div class="label">{{ $t('guan-zhu') }}</div>
        <div class="desc">{{ $t('zui-xin-dong-tai') }}</div>
      </div>
      <div class="tab flex-column-center" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">
        <div class="label">{{ $t('zhong-cao') }}</div>
        <div class="desc">{{ $t('xin-pin-shou-fa') }}</div>
      </div>
      <!-- <div class="tab flex-column-center" :class="tabIndex == 3 ? 'active' : ''" @click="changeTab(3)">
        <div class="label">短视频</div>
        <div class="desc">精选好物</div>
      </div> -->
    </div>

    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <!--关注-->
      <div class="follow-box" v-if="tabIndex == 1">
        <div class="empty flex-column-center" v-if="isFollowInit && followList.length == 0">
          <img :src="emptyUrl" alt="">
          <Button v-if="!isLogin" @click="toPath('login')">{{ $t('qing-xian-deng-lu-0') }}</Button>
        </div>

        <List
          v-if="isLogin && followList.length > 0"
          v-model="loading"
          :finished="followFinished"
          :finished-text="isFollowInit && followList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoadFollow">
          <div class="list ">
            <div class="shop" v-for="(item, i) in followList" :key="i"  >
              <div class="title flex-center-between">
                <div class="flex-center ">
                  <div class="header">
                    <img :src="item.Shop.Logo" alt="">
                  </div>
                  <span class="name">{{item.Shop.Name}}</span>
                </div>
                <div>
                  <Button class="btn-style" @click.stop="toShop(item.Shop)">{{ $t('jin-dian') }}</Button>
                </div>
              </div>

              <div class="goods-list">
                <div class="list-outer">
                  <div class="list-inner flex" :style="getBoxWidth(item.goodsList.length)">
                    <div class="goods" v-for="(good, index) in item.goodsList" :key="index" @click="toGoodsDetail(good)">
                      <div class="img">
                        <img :src="good.MainImage" alt="">
                      </div>
                      <div class="name">{{good.ProductTranslation && good.ProductTranslation[0] ? good.ProductTranslation[0].Name : ''}}</div>
                      <div class="price">{{good.SalePrice}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </List>
      </div>
      <!--种草-->
      <div class="newest-goods-box" v-if="tabIndex == 2">
        <List
          v-if="shopList.length > 0"
          v-model="loading"
          :finished="newFinished"
          :finished-text="isNewInit && shopList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoadNew">
          <div class="shop-list">
            <div class="shop" v-for="(item, i) in shopList" :key="i" >
              <div class="flex-center-between title">
                <div class="header">
                  <img :src="item.Logo" alt="">
                </div>
                <div class="flex-1">
                  <div class="name">{{item.Name}}</div>
                  <div class="flex">
                    <div class="desc">{{item.User ? (item.User.Phone || item.User.Email) : ''}}</div>
                    <div class="time">{{item.CreatedAt.substring(5,10)}}</div>
                  </div>
                </div>
                <div>
                  <Button class="btn-style" @click="toShop(item)">{{ $t('jin-dian-0') }}</Button>
                </div>
              </div>
              <div class="goods-title flex-center-start">
                <span class="new">{{ $t('xin-pin') }}</span>
              </div>
              <div class="goods-list flex-wrap">
                <div class="goods" v-for="(good, index) in item.goodsList" :key="index" @click="toGoodsDetail(good)">
                  <img :src="good.MainImage" alt="">
                </div>
              </div>
              <div class="bottom-desc flex-center-between">
                <div class="flex-center desc">
                  {{$t('yue-du')}} {{item.TotalVisit || 0}}
                </div>
                <div class="flex-center star">
                  <i class="iconfont icon-hand-up"></i>
                  <span>{{$t('dian-zan')}} {{item.Good || 0}}</span>
                </div>
              </div>
            </div>
          </div>
        </List>

      </div>
      <!--短视频-->
      <div class="video-box" v-if="tabIndex == 3">
        <List
          v-if="videoList.length > 0"
          v-model="loading"
          :finished="videoFinished"
          :finished-text="isVideoInit && videoList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoadVideo">
          <div class="video-list flex">
            <div class="left">
              <div class="video" v-for="(item, i) in leftVideoList" :key="i">
                <div class="img">
                  <img :src="item.Img" alt="">
                </div>
                <div class="title">{{item.name}}</div>
                <div class="flex-center-between desc">
                  <div class="flex-center">
                    <img class="logo" :src="logoUrl" alt="">
                    <span>{{item.shopName}}</span>
                  </div>
                  <div class="flex-center">
                    <i class="iconfont icon-heart"></i>
                    <span>{{item.collect}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="video" v-for="(item, i) in rightVideoList" :key="i">
                <div class="img">
                  <img :src="item.Img" alt="">
                </div>
                <div class="title">{{item.name}}</div>
                <div class="flex-center-between desc">
                  <div class="flex-center">
                    <img class="logo" :src="logoUrl" alt="">
                    <span>{{item.shopName}}</span>
                  </div>
                  <div class="flex-center">
                    <i class="iconfont icon-heart"></i>
                    <span>{{item.collect}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </List>

      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { PullRefresh, Button, List } from 'vant'
import { getToken } from '@/utils/auth'
import { getShopList, getFollowList } from '@/api/shop'
import { shopGoods } from '@/api/index'
export default {
  components: {
    PullRefresh,
    Button,
    List
  },
  data() {
    return {
      isLogin: false,
      tabIndex: 2,
      isLoading: false,
      logoUrl: require('../../assets/imgs/logo.png'),
      emptyUrl: require('../../assets/imgs/empty.png'),
      shopList: [],
      followList: [],
      videoList: [],
      leftVideoList: [],
      rightVideoList: [],
      videoListBak: [],
      shopListBak: [],
      isFollowInit: false,
      isNewInit: false,
      isVideoInit: false,
      loading: false,
      videoFinished: false,
      followFinished: false,
      newFinished: false,
      followPage: {
        page: 1,
        limit: 10,
      },
      newPage: {
        page: 1,
        limit: 10,
      },
      videoPage: {
        page: 1,
        limit: 10,
      },
      totalPage: 1,
      followTotalPage: 1,
    }
  },
  computed: {
     screenScale() {
      return this.$store.state.screenScale || 1
    },
  },
  mounted() {
    if (getToken()) {
      this.isLogin = true
    } else {
      this.isLogin = false
    }

    this.initNewShop()
  },
  methods: {
    getBoxWidth(len) {
      return `width: ${len * 155 * this.screenScale}px;`
    },
    changeTab(i) {
      this.tabIndex = i
      if (i == 1 && !this.isFollowInit) {
        this.initFollow()
      } else if (i == 2 && !this.isNewInit) {
        this.initNewShop()
      } else if (i == 3 && !this.isVideoInit) {
        this.initVideo()
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    onLoadFollow() {
      this.followPage.page += 1
      this.initFollow(true)
    },
    initFollow(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('current', this.followPage.page)
      form.append('pageSize', this.followPage.limit)
      form.append('status', 1)
      getFollowList(form).then(res => {
        let list = res.data.Items.map(v => {
          return {
            ...v,
            goodsList: []
          }
        })
        list.forEach((v, i) => {
          this.getGoods(v.Shop.ID, i, true)
        })
        if (isAdd) {
          this.followList = this.followList.concat(list)
        } else {
          this.followList = list
        }
        this.followTotalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.followTotalPage <= this.followPage.page) {
          this.followFinished = true
        }
        this.isFollowInit = true
        this.loading = false
      }).catch(err => {
        // this.isFollowInit = true
        this.loading = false
      })
    },
    onLoadVideo() {
      this.videoPage.page += 1
      this.initVideo(true)
    },
    initVideo(isAdd) {
      this.loading = true

      this.videoList = this.videoList.concat(this.videoListBak)
      if (this.videoPage.page > 3) {
        this.videoFinished = true
      }
      this.isVideoInit = true
      setTimeout(() => {
        this.loading = false
      }, 1000)
      this.resetVideo()
    },
    resetVideo() {
      this.leftVideoList = []
      this.rightVideoList = []
      this.videoList.forEach((v, i) => {
        if (i % 2 == 0) {
          this.leftVideoList.push(v)
        } else {
          this.rightVideoList.push(v)
        }
      })
    },
    onLoadNew() {
      this.newPage.page += 1
      this.initNewShop(true)
    },
    initNewShop(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('current', this.newPage.page)
      form.append('pageSize', this.newPage.limit)
      form.append('Status', 1)
      getShopList(form).then(res => {
        let list = res.data.Items.map(v => {
          return {
            ...v,
            goodsList: []
          }
        })
        list.forEach((v, i) => {
          this.getGoods(v.ID, i)
        })
        if (isAdd) {
          this.shopList = this.shopList.concat(list)
        } else {
          this.shopList = list
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.newPage.page) {
          this.newFinished = true
        }
        this.isNewInit = true
        this.loading = false
      }).catch(err => {
        // this.isNewInit = true
        this.loading = false
      })
    },
    getGoods(shopId, i, isCollect) {
      let form = new FormData()
      form.append('pageSize', 6)
      form.append('ShopID', shopId)
      shopGoods(form).then(res => {
        let list = res.data.Items
        if (isCollect) {
          this.$set(this.followList[i], 'goodsList', list)
        } else {
          this.$set(this.shopList[i], 'goodsList', list)
        }
      })
    },
    toGoodsDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    },
    toShop(data) {
      sessionStorage.setItem('shopDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'shop',
        query: {
          id: data.ID,
        }
      })
    }
  }
}
</script>