<template>
  <div class="refund-page">
    <top-bar :title="$t('tui-huan-shou-hou')" :isWhite="true" @back="back"></top-bar>


    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <div class="refund-list">
        <List
          v-if="refundList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && refundList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list" v-for="(item, i) in refundList" :key="i" @click="toDetail(item)">
            <div class="title">
              {{ $t('tui-kuan-bian-hao') }} <span>{{item.orderId}}</span>
              </div>
            <div class="goods-info">
              <div class="goods flex-start" v-for="(good, index) in item.goodsList" :key="index">
                <div class="img">
                  <img :src="good.Img" alt="">
                </div>
                <div class="info flex-1">
                  <div class="flex-center-between">
                    <div class="goods-name flex-1">{{good.Title}}</div>
                    <div>x {{good.count}}</div>
                  </div>
                  <div class="price">{{ $t('tui-kuan-jine') }}
                    <span class="count">{{good.Price}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom flex-center-end">
              <span class="status">{{ $t('jin-tui-kuan') }}</span>
              <span class="status">{{ $t('tui-kuan-yi-wan-cheng-0') }}</span>
            </div>
          </div>
        </List>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      tabIndex: 0,
      refundList: [],
      page: {
        page: 1,
        limit: 100,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      refundListBak: []
    }
  },
  computed: {
    tabList() {
      return [
        this.$t('quan-bu-0'),
        this.$t('dai-fu-kuan'),
        this.$t('dai-shou-huo-0'),
        this.$t('yi-qu-xiao-0'),
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      this.refundList = this.refundList.concat(this.refundListBak)
      if (this.page.page > 3) {
        this.finished = true
      }
      this.isInit = true
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    toDetail(data) {
      this.$router.push({
        name: 'refundDetail'
      })
    }
  }
}
</script>