<template>
  <div class=" bank-page   ">
    <top-bar :title="$t('yin-hang-ka')"  :isWhite="true" @back="back"></top-bar>

		<div class="bank-list" >
			<div class="list flex-center-between" v-for="(item, i) in bankDataList" :key="i" @click="chooseBank(item)">
        <div class="flex-center">
          <div class="icon">
            <img :src="bankUrl" alt="">
          </div>
          <div>
            <div class="bank-name flex-center">
              {{item.BankName}}
              <span class="tag" @click="removeData(item)">{{ $t('jie-chu-bang-ding') }}</span>
            </div>
            <div class="card-no">
              {{resetBank(item.CardNumber)}}
            </div>
          </div>
        </div>
        <div class="adot">
          <img :src="checkUrl" alt="" v-if="i == 0">
          <img :src="checkUrl2" alt="" v-else >
        </div>
      </div>
		</div>

    <div class="empty" v-if="isInit && bankDataList.length == 0">
      <img :src="emptyUrl" alt="">
      <div>{{ $t('zan-wu-shu-ju') }}</div>
    </div>
    
    <div class="bottom-btn">
      <Button @click="toAdd">
        <div class="flex-center">
          <Icon name="add-o" size="18"></Icon>
          {{ $t('tian-jia-yin-hang-ka') }} </div>
      </Button>
    </div>

    <Popup v-model="showPopup" class="msg-popup">
      <div class="msg-modal">
        <div class="title">{{ $t('ti-shi') }}</div>
        <div class="msg">{{ $t('que-ren-jie-chu-bang-ding') }}</div>
        <div class="btn-style">
          <Button @click="cancel">{{ $t('qu-xiao') }}</Button>
          <Button @click="ensure" class="blue">{{ $t('que-ding') }}</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Button, Icon, Popup } from 'vant'
import topBar from '@/components/topBar'
import { bankList, removeBank } from '@/api/user'
export default {
  components: {
    topBar,
    Button,
    Icon,
    Popup
  },
  data() {
    return {
      emptyUrl: require('../../assets/imgs/empty2.png'),
      bankUrl: require('../../assets/imgs/icon-bank.png'),
      checkUrl: require('../../assets/imgs/icon-check.png'),
      checkUrl2: require('../../assets/imgs/icon-check2.png'),
      hideAdd: false,
      bankDataList: [],
      isInit: false,
      editId: '',
      showPopup: false,
      pageType: ''
    }
  },
  mounted() {
    this.pageType = this.$route.query.type
    this.init()
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('pageSize', 30)
      bankList(form).then(res => {
        this.bankDataList = res.data.Items
      })
    },
    back() {
      this.$router.go(-1)
    },
    toAdd() {
      this.$router.push({
        name: 'addBank'
      })
    },
    resetBank(cardNo) {
      if (!cardNo) {
        return ''
      }
      let newCardNo = ''
      newCardNo = '**** **** **** **** ' + cardNo.substring(cardNo.length - 4)
      return newCardNo
    },
    removeData(data) {
      this.editId = data.ID
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    ensure() {
      let form = new FormData()
      form.append('ID', this.editId)
      removeBank(form).then(res => {
        if (res.code == 0) {
          this.showPopup = false
          this.$toast.success(this.$t('shan-chu-cheng-gong-1'))
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    chooseBank(data) {
      if (this.pageType == 1) {
        sessionStorage.setItem('bankData', JSON.stringify(data))
        sessionStorage.setItem('chooseBank', 1)
        this.back()
      }
    }
  }
}
</script>