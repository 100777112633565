<template>
  <div class="goods-evalute-page">
    <top-bar :title="$t('shang-pin-ping-jia')" :isWhite="true" @back="back"></top-bar>


    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <div class="evalute-list">
        <List
          v-if="evaluteList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && evaluteList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list" v-for="(item, i) in evaluteList" :key="i">
            <div class="flex-center">
              <div class="header">
                <img :src="logoUrl" alt="">
              </div>
              <div class="flex-1">
                <div class="name">{{item.name}}</div>
                <div class="star">
                  <i class="iconfont icon-star-filled" v-for="j in item.Score" :key="j"></i>
                </div>
              </div>
              <div class="time">{{item.CreatedAt}}</div>
            </div>
            <div class="content">{{item.Content}}</div>
          </div>
        </List>
      </div>
    </PullRefresh>

  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import topBar from '@/components/topBar'
import { productEvaluteList } from '@/api/shop'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      dataId: '',
      logoUrl: require('../../assets/imgs/logo.png'),
      tabIndex: 0,
      evaluteList: [],
      showPopup: false,
      page: {
        page: 1,
        limit: 100,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      evaluteListBak: [ ]
    }
  },
  computed: {
    tabList() {
      return [
        this.$t('quan-bu'),
        this.$t('chu-li-zhong'),
        this.$t('dai-shou-huo'),
        this.$t('yi-qu-xiao'),
      ]
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
     
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      form.append('ProductID', this.dataId)
      productEvaluteList(form).then(res => {
        this.isLoading = false
        if (isAdd) {
          this.evaluteList = this.evaluteList.concat(res.data.Items)
        } else {
          this.evaluteList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
        this.isLoading = false
      })
    },
    onRefresh() {
      this.page.page = 1
      this.init()
    },
    toDetail(data) {
      this.$router.push({
        name: 'refundDetail'
      })
    },
    showConfirm() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>