<template>
  <div class="login-page">
    <top-bar :title="$t('zhu-ce-0')" :isWhite="true" @back="back"></top-bar>
    <div class="logo" v-show="step == 1">
      <img :src="logoUrl" alt="">
    </div>

    <div class="page-title">{{ $t('huan-ying-zhu-ce-woshop-kua-jing-shang-cheng') }}</div>

    <div class="login-tab flex-center" :class="`bg${tabIndex}`">
      <div class="tab flex-center" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{ $t('shou-ji-zhu-ce') }}</div>
      <div class="tab flex-center" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{ $t('you-xiang-zhu-ce') }}</div>
    </div>
    <div class="login-form">

      <div class="form-item  flex-center" v-show="tabIndex == 1">
        <div class="pre flex-center" @click="changePre">
          {{phonePre}}
          <i class="iconfont icon-down"></i>
        </div>
        <div class="flex-1">
          <div class="input clear"> 
            <input type="tel" v-model="form.phone" :placeholder="$t('qing-shu-ru-shou-ji-hao-0')">
          </div>
        </div>
      </div>

      <div class="form-item  flex-center" v-show="tabIndex == 2">
        <div class="flex-1">
          <div class="input "> 
            <input type="text" v-model="form.email" :placeholder="$t('qing-shu-ru-you-xiang')">
          </div>
        </div>
      </div>

      <div class="form-item  flex-center">
        <div class="flex-1">
          <div class="input "> 
            <input type="text" v-model="form.inviteCode" :placeholder="$t('qing-shu-ru-yao-qing-ma')">
          </div>
        </div>
      </div>

      <!-- <div class="form-item  flex-center" >
       
        <div class="flex-1">
          <div class="input clear"> 
            <input type="tel" v-model="form.code" :placeholder="请输入验证码">
          </div>
        </div>
        <div class="adot flex-center" >
          <span class="gray" v-if="isDisabled">({{timeCount}})</span>
          <span v-else @click="resend">发送验证码</span>
        </div>
      </div> -->


      <div class="form-item flex-center">
        <div class="input"> 
          <input type="password" v-model="form.password" :placeholder="$t('qing-shu-ru-mi-ma')">
        </div>
      </div>

       <Checkbox v-model="checked" checked-color="#fa3534" icon-size="14px">
        <div>
          {{ $t('wo-yi-yue-du-bing-tong-yi') }} <span>{{ $t('yong-hu-yin-si-xie-yi') }}</span>
        </div>
       </Checkbox>

      <Button class="login-btn" @click="submit">{{ $t('zhu-ce-1') }}</Button>
    </div>


    <div class="flex-center desc"> 
      <div class="register" @click="back">
        <span>{{ $t('deng-lu') }}</span> 
      </div>
    </div>
    

   <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-between">
          <icon name="arrow-left" size="20" color="#000" @click="cancel"></icon>
        </div>
        <div class="group-list login" v-for="(item, i) in countryList" :key="i">
          <div class="country-title" :id="item.id">{{item.title}}</div>
          <div class="list flex-center-start" :class="child.key == phonePre ? 'active' : ''" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
              <span>{{getCountryName(child.title)}}</span>
            </div>
            <div class="key">
              {{child.key}}
            </div>
          </div>
        </div>
        <div class="fast-link">
          <div class="link" v-for="(item, i) in linkList" :key="i" @click="toFastPage(item)">
            {{item.title}}
          </div>
        </div>
			</div>
    </Popup>
  </div>
</template>
<script>
  import { Button, Popup, Divider, Checkbox, Icon } from 'vant'
  import { phoneRegister, emailRegister,  sendSms, checkPhoneCode, userArticle, sendEmail, checkEmailCode } from '@/api/login'
  import { setToken, setUser, setUserId } from '@/utils/auth'
  import topBar from '@/components/topBar'
  export default {
    components: {
      Button,
      Popup,
      Divider,
      topBar,
      Checkbox,
      Icon
    },
    data() {
      return {
        step: 1,
        form: {
          username: '',
          phone: '',
          email: '',
          password: '',
          safePassword: '',
          safePassword2: '',
          code: '',
          inviteCode: ''
        },
        showCode: true,
        logoUrl: require('../../assets/imgs/logo.png'),
        passwordType: '',
        keyboardValue: '',
        showPassword: false,
        showSafePassword: false,
        showSafePassword2: false,
        activeIndex: 1,
        isDisabled: false,
        sendTime: '',
        showInvite: true,
        showFlag: false,
        passwordAuthList: [],
        phonePre: '+66',
        tabIndex: 1,
        showPopup: false,
        countryList: [],
        linkList: [],
        keyword: '',
        serviceData: {},
        privacyData: {},
        checked: true,
      }
    },
    computed: {
      timeCount() {
        return this.sendTime + 'S'
      },
      countryListBak() {
        return this.$store.state.countryList
      },
      reg_code() {
        return this.$store.state.reg_code
      },
      countryListBak() {
        return this.$store.state.countryList
      }
    },
    mounted() {
      let code = this.$route.query.code
      if (code) {
        this.form.inviteCode = code
      }
      this.countryList = [].concat(this.countryListBak)
      this.linkList = this.countryList.filter(v => {
        return v.id != 'common'
      })
    },
    methods: {
      back() {
        this.$router.go(-1)
      },
      toLogin() {
        this.$router.push({
          name: 'login'
        })
      },
      changeTab(index) {
        this.tabIndex = index
      },
      submit() {
        if (!this.checked) {
          this.$toast.fail(this.$t('qing-gou-xuan-yong-hu-yin-si-xie-yi'))
          return
        }
        if (this.tabIndex == 1 && !this.form.phone ) {
          this.$toast.fail(this.$t('qing-shu-ru-shou-ji-hao-0'))
          return
        }
        if (this.tabIndex == 2 && !this.form.email ) {
          this.$toast.fail(this.$t('qing-shu-ru-you-xiang'))
          return
        }
        if (!this.form.password) {
          this.$toast.fail(this.$t('qing-shu-ru-mi-ma'))
          return
        }
        let form = new FormData()
        form.append('Password', this.form.password)
        form.append('InviteCode', this.form.inviteCode)
        if (this.tabIndex == 1) {
          form.append('Phone', this.form.phone)
          form.append('CountryCode', this.phonePre)
          phoneRegister(form).then(res => {
            if (res.code == 0) {
              this.$toast.success(this.$t('zhu-ce-cheng-gong'))
              this.$router.push({
                name: 'login'
              })
            } else {
              this.$toast.fail(res.msg)
            }
          })
        } else {
          form.append('Email', this.form.email)
          emailRegister(form).then(res => {
            if (res.code == 0) {
              this.$toast.success(this.$t('zhu-ce-cheng-gong'))
              this.$router.push({
                name: 'login'
              })
            } else {
              this.$toast.fail(res.msg)
            }
          })
          
        }
      },
      toPath(path) {
        if (path) {
          this.$router.push({
            name: path
          })
        }
      },
       sendPhoneSms(){
        if (!this.form.phone) {
          this.$toast.fail(this.$t('qing-shu-ru-shou-ji-hao-0'))
          return
        }
        let form = new FormData()
        form.append('Phone', this.phonePre + this.form.phone)
        form.append('Invite', this.form.inviteCode)
        form.append('Type', 1)
        sendSms(form).then(res => {
         
          if (res.code == 0) {
            this.countDown(60)
          } else {
            this.$toast.fail(res.msg)
          }
        })
      },
      sendEmailSms(){
        if (!this.form.username) {
          this.$toast.fail(this.$t('qing-shu-ru-you-xiang'))
          return
        }
        let form = new FormData()
        form.append('Email', this.form.username)
        form.append('Invite', this.form.inviteCode)
        form.append('Type', 1)
        sendEmail(form).then(res => {
          if (res.code == 0) {
            this.countDown(60)
          } else {
            this.$toast.fail(res.msg)
          }
        })
      },
      countDown(time) {
        if (time > 0) {
          this.isDisabled = true
          time -= 1
          this.sendTime = time
          setTimeout(() => {
            this.countDown(time)
          }, 1000)
        } else {
          this.sendTime = ''
          this.isDisabled = false
        }
      },
      resend() {
        if (this.isDisabled) {
          return
        } else {
          if (this.tabIndex == 1) {
            this.sendPhoneSms()
          } else {
            this.sendEmailSms()
          }
        }
      },
      changePre() {
        this.showPopup = true
      },
      chooseCountry(data) {
        this.phonePre = data.key
        this.showPopup = false
      },
      getCountryName(name) {
        if (!name) {
          return ''
        }
        let newName = ''
        newName = name.substring(0, name.indexOf('(') - 1)
        return newName
      },
      toFastPage(key) {
        let dom = document.getElementById(key.id)
        let parentDom = document.querySelector('.country-list')
        if (dom && parentDom) {
          let domTop = dom.offsetTop
          parentDom.scrollTo({
            top: domTop - 124
          })
        }
      },
      cancel() {
        this.showPopup = false
      },
    },
  }
</script>