<template>
  <div class=" recharge-page   ">
    <top-bar :title="$t('chong-zhi')"  :isWhite="true" @back="back"></top-bar>

    <div class="top flex-column-center">
      <div>{{ $t('dang-qian-yu-e') }}</div>
      <div class="count">{{userMoney}}</div>
    </div>

    <div class="bottom-btn">
      <Button @click="toAdd">{{ $t('chong-zhi-0') }}</Button>
    </div>

    <Popup v-model="showPopup" position="bottom">
      <div class="recharge-modal flex-column-center">
        <div class="title">{{ $t('chong-zhi-jin-e') }}</div>
        <div class="input">
          <input type="tel" inputmode="decimal" v-model="form.money" :placeholder="$t('chong-zhi-jin-e-0')">
        </div>
        <div class="modal-btn">
          <Button @click="toPay">{{ $t('que-ding') }}</Button>
        </div>
      </div>
    </Popup>

     <Popup v-model="showPayPopup" position="bottom" class="bottom-popup">
      <div class="option-modal order-modal"> 
        <div class="title flex-center-between">
          <div class="left"></div>
          <div>{{ $t('shou-yin-tai') }}</div>
          <i class="iconfont icon-close" @click="cancel"></i>
        </div>

        <div class="price">{{form.money}}</div>
        <div class="pay-list">
          <div class="list flex-center-between" v-for="(item, i) in payList" :key="i" @click="changePay(item, i)">
            <div>
              <div class="flex-center">
                <img :src="item.icon" alt="">
                <span class="name">{{item.name}}</span>
              </div>
              <div class="desc" v-if="item.desc">{{item.desc}}</div>
            </div>
            <div>
              <Icon name="checked" size="18" color="#f25440" v-if="payIndex == i"/>
              <Icon name="circle"  size="18" color="#ccc" v-else/>
            </div>
          </div>
        </div>

        <div class="btn-style mt-30">
          <Button @click="doPay">{{ $t('que-ren-zhi-fu') }}</Button>
        </div>
      </div>
    </Popup>
    <Popup v-model="showQrcodePopup" position="bottom" class="bottom-popup">
      <div class="qrcode-box">
        <div class="flex-column-center">
          <div class="title">{{qrcodeTitle}}</div>
          <div class="qrcode" ref="qrcode" id="qrcode"></div>
          <div class="address">{{address}}</div>
          <div class="copy-btn" @click="copyData">{{ $t('fu-zhi-qian-bao-di-zhi') }}</div>
        </div>

        <div class="modal-form">
          <div class="label">{{ $t('shang-chuan-zhuan-zhang-jie-tu') }}</div>
          <div class="file-box clear flex-column-center" v-if="!form.Logo" @click="chooseFile">  
            <Icon name="plus" size="20px"></Icon>
            <span class="mt-5">{{ $t('xuan-ze-tu-pian') }}</span>
          </div>
          <div class="file-preview clear" v-else>
            <img :src="`/upload/${form.Logo}`" alt="">
          </div>
          <input type="file" class="hide-box" id="fileInput" @change="changeFile" accept="image/*"> 
        </div>

        <Button class="modal-btn" @click="submit">{{ $t('que-ren-zhi-fu-0') }}</Button>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Button, Icon, Popup } from 'vant'
import topBar from '@/components/topBar'
import { getRechargeInfo, uploadFile, addRecharge, userInfo } from '@/api/user'
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard';
export default {
  components: {
    topBar,
    Button,
    Icon,
    Popup
  },
  data() {
    return {
      form: {
        money: '',
        Logo: ''
      },
      payIndex: 0,
      userMoney: 0,
      showPayPopup: false,
      showQrcodePopup: false,
      showPopup: false,
      emptyUrl: require('../../assets/imgs/empty2.png'),
      hideAdd: false,
      addressList: [],
      isInit: false,
      qrcodeTitle: 'USDT-TRC20',
      address: 'aasdfasdasdfsadfs',
      payList: []
    }
  },
  computed: {
    payListBak() {
      return [
        {
          icon: require('../../assets/imgs/pay-4.png'),
          name: 'USDT-TRC20',
          desc: this.$t('usdt-dui-huan-mei-yuan')
        },
        {
          icon: require('../../assets/imgs/pay-5.png'),
          name: 'USDT-ERC20',
          desc: this.$t('usdt-dui-huan-mei-yuan-0')
        },
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getRechargeInfo().then(res => {
        this.addressList = res.data.Items

        this.payList = this.addressList.map(v => {
          let index = this.payListBak.findIndex(val => {
            return val.name == v.RechargeNetwork
          })
          let data = this.payListBak[index]
          return {
            ...v,
            ...data
          }
        })
      })

      userInfo().then(res => {
        this.userMoney = res.data.UserAccount.Balance
      })
    },
    back() {
      this.$router.go(-1)
    },
    toAdd() {
      this.showPopup = true
    },
    toPay() {
      if (!this.form.money) {
        this.$toast.fail(this.$t('qing-shu-ru-chong-zhi-jin-e'))
        return
      }
      this.showPopup = false
      this.showPayPopup = true
    },
    cancel() {
      this.showPayPopup = false
      this.showQrcodePopup = false
    },
    doPay() {
      this.showQrcodePopup = true
      this.qrcodeTitle = this.payList[this.payIndex].name
      this.address = this.payList[this.payIndex].RechargeAddress


      this.$nextTick(() => {
        document.getElementById('qrcode').innerHTML = ''
        let qrcode = new QRCode(this.$refs.qrcode, {
          text: this.address,
          width: 120,
          height: 120,
          correctLevel: QRCode.CorrectLevel.H
        })
      }, 300)
    },
    changePay(data, i) {
      this.payIndex = i
    },
    copyData() {
      const clipboard = new Clipboard('.copy-btn', {text: () => this.address});
      this.$toast.success(this.$t('fu-zhi-cheng-gong'))
    },
    chooseFile() {
      document.getElementById('fileInput').value = ''
      document.getElementById('fileInput').click()
    },
    changeFile(e) {
      let file = e.target.files[0]
      let form = new FormData()
      form.append('file', file)
      uploadFile(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('shang-chuan-cheng-gong'))
          this.form.Logo = res.data.FileName
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    submit() {
      if (!this.form.Logo) {
        this.$toast.fail(this.$t('qing-shang-chuan-zhuan-zhang-jie-tu'))
        return
      }
      let form = new FormData()
      form.append('Amount', this.form.money)
      form.append('PaymentVoucher', this.form.Logo)
      addRecharge(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('ti-jiao-cheng-gong-0'))
          this.form.Logo = ''
          this.form.money = ''
          this.cancel()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>