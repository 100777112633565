<template>
  <div  class="coupon-page">
    <top-bar :title="$t('ling-quan-zhong-xin')" :isWhite="true"  @back="back"></top-bar>
    
    <div class="coupon-list">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad">
        <div class="list flex-center" v-for="(item, i) in dataList" :key="i" > 
          <div class="count">10</div>
          <div class="flex-1">
            <div class="name">WoShop  Mall</div>
            <div class="desc">{{$t('man')}}100{{$t('jian')}}30</div>
            <div class="time">2023-12-28 12:33:44</div>
          </div>
          <div class="btn-style">
            <div v-if="item.status == 1">{{$t('li-ji-ling-qu')}}</div>
            <div v-else>
              <img :src="statusUrl" alt="" class="white">
            </div>
          </div>
        </div>
      </List>

      <div class="empty flex-column-center" v-if="dataList.length == 0 && isInit">
        <img :src="emptyUrl" alt="">
        <span class="desc">{{$t('trade.empty')}}</span>
      </div>
    </div>

    <div class="bottom-btn-style">
      <Button @click="toPath('coupon')">{{$t('wo-de-quan')}}</Button>
    </div>
  </div>
</template>
<script>
import { List, Button } from 'vant'
import topBar from '@/components/topBar'
import { couponList } from '@/api/index'
export default {
  components: {
    topBar,
    List,
    Button
  },
  data() {
    return {
      tabIndex: 0,
      dataList: [],
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
			emptyUrl: require('../../assets/imgs/empty.png'),
			statusUrl: require('../../assets/imgs/coupon-status3.png'),
    }
  },
  computed: {
    tabList() {
      return [
        this.$t('wei-shi-yong'),
        this.$t('yi-shi-yong'),
        this.$t('yi-guo-qi'),
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
      this.page.page = 1
      this.dataList = []
      this.finished = false
      this.init()
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    init(isAdd) {
      return
      this.loading = true
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      signList(form).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        this.$store.state.showLoading = false
      })
    },
  }
}
</script>