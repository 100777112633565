<template>
  <div class=" help-page">
    <top-bar :title="$t('bang-zhu')" :isWhite="true" @back="back"></top-bar>
    
    <div class="content" v-html="Content">
      
    </div>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import topBar from '@/components/topBar'
import { articleList } from '@/api/index'
export default {
  components: {
    Button,
    topBar,
    Icon
  },
  data() {
    return {
      Content: ''
    }
  },
 
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('Type', 2)
      articleList(form).then(res => {
        let list = res.data.Items
        if (list.length > 0) {
          this.Content = list[0].Content
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
   
  }
}
</script>