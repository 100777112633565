<template>
  <div class="order-list-page">
    <top-bar :title="$t('quan-bu-ding-dan')" :isWhite="true" @back="back"></top-bar>

    <div class="tabs flex-center-between">
      <div class="tab flex-center" v-for="(item, i) in tabList" :key="i" @click="changeTab(i)" :class="tabIndex == i ? 'active' : ''">{{item}}</div>
    </div>

    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <div class="order-list">
        <List
          v-if="orderList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && orderList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list" v-for="(item, i) in orderList" :key="i" @click="toDetail(item)">
            <div class="title flex-center-between">
              <div>
                {{ $t('ding-dan-hao-1') }} {{item.OrderNo}}
              </div>
              <div class="status">{{getStatusName(item.Status)}}</div>
            </div>
            <div class="shop">
              <div class="shop-name">{{item.Shop ? item.Shop.Name : ''}}</div>
              <div class="goods-list">
                <div class="goods flex-center-between" v-for="(good, index) in item.OrderProduct" :key="index">
                  <div class="img">
                    <img :src="good.BusinessProduct.MainImage" alt="">
                  </div>
                  <div class="info">
                    <div class="goods-name">{{good.BusinessProduct.ProductTranslation && good.BusinessProduct.ProductTranslation[0] ? good.BusinessProduct.ProductTranslation[0].Name : ''}}</div>
                    <div class="option">{{good.optionName}}</div>
                  </div>
                  <div class="goods-price">
                    <div>{{good.Price}}</div>
                    <div class="count">x{{good.Number}}</div>
                    <div class="evalute" v-if="item.Status == 4">
                      <Button @click.stop="toEvalute(item, good)">{{ $t('ping-jia-0') }}</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-center-end price">
                {{ $t('shi-fu-jine') }}：<span class="count">{{getTotalPrice(item)}}</span>
              </div>
              <div class="flex-center-end btn-style">
                <Button v-if="item.Status == 8">{{ $t('tui-kuan-yi-wan-cheng') }}</Button>
                <Button v-if="item.Status == 0 || item.Status == 1" @click="cancelOrder(item)">{{ $t('qu-xiao-ding-dan') }}</Button>
              </div>
            </div>
          </div>
        </List>
      </div>
    </PullRefresh>

    <Popup v-model="showPopup" class="msg-popup">
      <div class="msg-modal">
        <div class="title">{{ $t('ti-shi') }}</div>
        <div class="msg">{{ $t('que-ren-qu-xiao-ding-dan') }}</div>
        <div class="btn-style">
          <Button @click="cancel">{{ $t('qu-xiao') }}</Button>
          <Button class="blue" @click="submit">{{ $t('que-ding') }}</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import topBar from '@/components/topBar'
import { getOrderList, doCancelOrder } from '@/api/shop'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      form: {
        status: ''
      },
      tabIndex: 0,
      orderList: [],
      page: {
        page: 1,
        limit: 100,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      showPopup: false,
      editData: {}
    }
  },
  computed: {
    tabList() {
      return [
        this.$t('quan-bu'),
        this.$t('chu-li-zhong'),
        this.$t('dai-shou-huo'),
        this.$t('yi-qu-xiao'),
      ]
    }
  },
  mounted() {
    let type = this.$route.query.type
    this.form.status = type
    if (type == 0) {
      this.tabIndex = 1
    } else if (type == 1) {
      this.tabIndex = 2
    } else  {
      this.tabIndex = -1
    }
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
      switch(i) {
        case 0: this.form.status = ''; break;
        case 1: this.form.status = 0; break;
        case 2: this.form.status = 2; break;
        case 3: this.form.status = 5; break;
        default: break
      }
      this.page.page = 1
      this.init()
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true

      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      if(this.form.status !== undefined) {
        form.append(this.$t('status'), this.form.status)
      }
      getOrderList(form).then(res => {

        if (isAdd) {
          this.orderList = this.orderList.concat(res.data.Items)
        } else {
          this.orderList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    toDetail(data) {
      sessionStorage.setItem('orderDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'orderDetail'
      })
    },
    toEvalute(data, good) {
      sessionStorage.setItem('evaluteData', JSON.stringify(data))
      this.$router.push({
        name: 'goodsEvalute',
        query: {
          id: good.ID
        }
      })
    },
    getStatusName(status) {
      let name = ''

      switch(status + '') {
        case '0': name = this.$t('chu-li-zhong'); break;
        case '1': name = this.$t('dai-fa-huo'); break;
        case '2': name = this.$t('dai-shou-huo'); break;
        case '3': name = this.$t('yi-qian-shou'); break;
        case '4': name = this.$t('yi-wan-cheng'); break;
        case '5': name = this.$t('yi-qu-xiao'); break;
        case '6': name = this.$t('yi-guan-bi'); break;
        case '7': name = this.$t('tui-huo-zhong'); break;
        case '8': name = this.$t('tui-kuan-cheng-gong'); break;
        default: break;
      }
      return name
    },
    getTotalPrice(list) {
      let total = 0
      if (list.OrderProduct) {
        list.OrderProduct.forEach(v => {
          total += v.TotalAmount
        })
      }
      return total.toFixed(2)
    },
    cancelOrder(data) {
      this.editData = data
      this.showPopup = true
    },
    submit() {
      //
      let form = new FormData()
      form.append('ID', this.editData.ID)
      doCancelOrder(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('qu-xiao-cheng-gong'))
          this.page.page = 1
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>