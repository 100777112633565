<template>
  <div class=" chat-page">
    <top-bar :title="title" :isWhite="true" @back="back"></top-bar>

    <div class="message-box">
      <div class="list " v-for="(item, i) in messageArr" :key="i" :class="item.SenderID == userId ? 'flex-start-end right' : 'flex-start'">
        <div class="header" v-if="item.SenderID != userId">
          <img :src="kefuUrl" alt="">
        </div>
        <div class="content">{{item.Content}}</div>
        <div class="header" v-if="item.SenderID == userId">
          <img :src="logoUrl" alt="">
        </div>
      </div>
    </div>

    <div class="bottom-btn flex-center-between">
      <div class="input flex-1">
        <input type="text" v-model="content" :placeholder="$t('message-placeholder')">
      </div>
      <div class="chat-btn">
        <Button @click="sendMsg">{{$t('fa-song')}}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import topBar from '@/components/topBar'
import {initWebSocket} from "@/utils/common";
import { getUserId, getToken } from '@/utils/auth'
import { messageList, messageRead } from '@/api/shop'
export default {
  components: {
    Button,
    topBar,
    Icon
  },
  data() {
    return {
      uid: '',
      title: '',
      content: '',
      kefuUrl: require('../../assets/imgs/kefu.jpg'),
      logoUrl: require('../../assets/imgs/logo.png'),
      messageArr: [],
      client: null,
      webSocket: null,
      page: {
        page: 1
      }
    }
  },
  computed: {
    userId() {
      return getUserId()
    }
  },
  beforeDestroy() {
    if (this.webSocket && this.webSocket.close) {
      this.webSocket.close()
    }
  },
  mounted() {
    this.uid = this.$route.query.uid
    this.title = this.$route.query.name
    this.initMessage()
    if (this.userId) {
      this.initSocket()
    }
  },
  methods: {
    initMessage() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', 30)
      form.append('ConversationID', this.uid)
      messageList(form).then(res => {
        let list = res.data.Items.reverse()
        this.messageArr = list
        setTimeout(() => {  
          window.scrollTo({top: 2000})
        }, 300)

        this.initRead()
      })
    },
    initRead() {
      let ids = this.messageArr.filter(v => {
        return !v.IsRead && v.ReceiverID == this.userId
      }).map(v => {
        return v.ID
      })
      if (ids.length > 0) {
        messageRead({
          MessageID: ids
        })
      }
    },
    async initSocket(){ //初始化weosocket
      let client = await initWebSocket(this.userId, this.websocketonmessage)
      this.client = client
      this.webSocket = client.ws
    },
    back() {
      this.$router.go(-1)
    },
    websocketonmessage(e) {
      let redata = {}
      try {
        redata = JSON.parse(e.body)
        this.setListData(redata)
      }
      catch(e) {
      }
    },
    setListData(data) {
      this.messageArr.push(data)
      let ids = [data.ID]
      messageRead({
        MessageID: ids
      })
      setTimeout(() => {  
        window.scrollTo({top: 10000})
      }, 300)
    },
    sendMsg() {
      if (!this.content) {
        this.$toast.fail(this.$t('fa-song-nei-rong-bu-neng-wei-kong'))
        return
      }
      // 发送消息
      let message = {
        ReceiverID: parseInt(this.uid),
        Type: 0,
        Content: this.content,
        token: getToken()
      }
      this.client.send("/exchange/direct_logs/chat_queue", { "content-type": "application/json" }, JSON.stringify(message));
      this.content = ''
    
    }

  }
}
</script>