import fetch from "../utils/fetch";
import qs from "qs";


// 轮播图
export function getBannerList(data) {
  return fetch({
    url: '/api/user/banner',
    method: 'post',
    data: data
  });
}
// 消息
export function getNoticeList(data) {
  return fetch({
    url: '/api/mobile/notice-lists',
    method: 'post',
    data: data
  });
}
// 积分礼品
export function giftList(data) {
  return fetch({
    url: '/api/mobile/BonusGift-lists',
    method: 'post',
    data: data
  });
}
// 签到
export function userSign(data) {
  return fetch({
    url: '/api/mobile/check-in',
    method: 'post',
    data: data
  });
}
// 分类列表
export function getCategoryList(data) {
  return fetch({
    url: '/api/user/product-category-list',
    method: 'post',
    data: data
  });
}
// 商家产品列表
export function shopGoods(data) {
  return fetch({
    url: '/api/user/business-product-list',
    method: 'post',
    data: data
  });
}
// 内容列表
export function articleList(data) {
  return fetch({
    url: '/api/user/article-lists',
    method: 'post',
    data: data
  });
}

