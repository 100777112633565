<template>
	<div class="home-container fixed">
    <div class="banner">
      <Swipe :autoplay="3000" :show-indicators="false">
        <SwipeItem v-for="(item, i) in bannerList" :key="i">
          <div class="img">
            <img :src="item.Img" alt="">
          </div>
        </SwipeItem>
      </Swipe>
    </div>

    <div class="top-search flex-center">
      <div class="flex-center-start input" @click="toPath('search')">
        <span class="iconfont icon-search"></span>
        <span class="placeholder">{{ $t('qing-shu-ru-shang-pin-dian-pu-guan-jian-ci') }}</span>
      </div>
      <div class="flex-center icon">
        <img :src="carUrl" alt="" @click="toPath('car')">
        <img :src="chatUrl" alt="" @click="toPath('message')">
        <img :src="langUrl" alt="" @click="toPath('lang')">
      </div>
    </div>

    <div class="tabs flex-center">
      <div class="tab flex-column-center" @click="toPath('sign')">
        <div class="flex-center">
          <img :src="couponUrl" alt="">
          <span class="label">{{ $t('ji-fen-qian-dao') }}</span>
        </div>
        <div class="desc">{{ $t('qian-dao-jiang-li-ji-fen') }}</div>
      </div>
      <div class="tab flex-column-center" @click="toPath('coupon')">
        <div class="flex-center">
          <img :src="moneyUrl" alt="">
          <span class="label">{{ $t('wo-de-you-hui-quan') }}</span>
        </div>
        <div class="desc">{{ $t('ling-you-hui-quan-geng-shi-hui') }}</div>
      </div>
      <!-- <div class="tab flex-column-center">
        <div class="flex-center">
          <img :src="giftUrl" alt="">
          <span class="label">申请分销商</span>
        </div>
        <div class="desc">邀请好友赚佣金</div>
      </div> -->
    </div>

    <div class="menu-list flex-start">
      <div class="menu flex-column-center" v-for="(item, i) in menuList" :key="i" @click="toPath(item.path)">
        <div class="icon">
          <img :src="item.url" alt="">
        </div>
        <div class="label">{{item.name}}</div>
      </div>
    </div>

    <div class="goods-box">
      <div class="box-title flex-center-between">
        <div class="label">{{ $t('xin-pin-shou-fa') }}</div>
        <div class="more flex-center" @click="toPath('newGoods')">
          <span>{{ $t('cha-kan-geng-duo-0') }}</span>
          <i class="iconfont icon-right"></i>
        </div>
      </div>

      <div class="goods-content">
        <div class="goods-content-inner">
          <div class="goods-list flex" :style="getBoxWidth(goodsList.length)">
            <div class="goods" v-for="(item, i) in goodsList" :key="i" @click="toGoodsDetail(item)">
              <div class="img">
                <img :src="item.MainImage" alt="">
              </div>
              <div class="title">{{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}</div>
              <div class="price">${{item.SalePrice}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-goods-list">
      <div class="title flex-center-start">
        <span>{{ $t('cai-ni-xi-huan') }}</span>
        <span class="tag">{{ $t('jing-xuan-hao-huo') }}</span>
      </div>

      <List
				v-if="bottomGoodsList.length > 0"
				v-model="loading"
				:finished="finished"
				:finished-text="isInit && bottomGoodsList.length == 0 ? '' : $t('common.noMore')"
				@load="onLoad">
        <div class="list flex-wrap">
          <div class="goods" v-for="(item, i) in bottomGoodsList" :key="i"  @click="toGoodsDetail(item)">
            <div class="img">
              <img :src="item.MainImage" alt="">
            </div>
            <div class="label">
              {{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}
            </div>
            <div class="price">${{item.SalePrice}}</div>
          </div>
        </div>
			</List>
    </div>
	</div>
</template>
<script>
import { Button, Swipe, SwipeItem, Popup, Icon, NoticeBar, List  } from 'vant'
import {articleList, getBannerList, shopGoods} from '@/api/index'
import { getBankList, getUsdtList } from '@/api/user'
import { Dialog } from 'vant';
import moment from 'moment'
export default {
  components: {
    Button,
    Swipe,
    SwipeItem,
    Popup,
    Icon,
    NoticeBar,
    Dialog,
    List
  },
  data() {
    return {
      isLogin: false,
      showMsg: false,
      avatar: '',
      tabIndex: 1,
      userName: '13312341234',
      userMoney: '0.00',
      carUrl: require('../../assets/imgs/icon-car.png'),
      chatUrl: require('../../assets/imgs/icon-chat.png'),
      langUrl: require('../../assets/imgs/icon-lang.png'),
      couponUrl: require('../../assets/imgs/icon-coupon.svg'),
      moneyUrl: require('../../assets/imgs/icon-money.svg'),
      giftUrl: require('../../assets/imgs/icon-gift.svg'),
      bannerList: [
        {
          Img: require('../../assets/imgs/banner1.jpeg'),
        },
        {
          Img: require('../../assets/imgs/banner2.jpeg'),
        },
      ],
      noticeList: [],
      noticeMsg: '',
      goodsList: [],
      bottomGoodsList: [],
      showPopup: false,
      isInit: false,
      loading: false,
      finished: false,
      Content: "",
      page: {
        page: 1,
        limit: 100,
      },
      totalPage: 1,
    }
  },
  computed: {
    screenScale() {
      return this.$store.state.screenScale || 1
    },
    menuList() {
      return  [
        {
          url:  require('../../assets/imgs/icon-coupon.png'),
          name: this.$t('you-hui-quan-1'),
          path: 'coupon'
        },
        {
          url:  require('../../assets/imgs/icon-sign.png'),
          name: this.$t('mei-ri-qian-dao'),
          path: 'sign'
        },
        {
          url:  require('../../assets/imgs/icon-heart.png'),
          name: this.$t('xin-pin-shou-fa'),
          path: 'newGoods'
        },
        {
          url:  require('../../assets/imgs/icon-integral.png'),
          name: this.$t('ji-fen-shang-cheng'),
          path: 'integral'
        },
        {
          url:  require('../../assets/imgs/icon-classify.png'),
          name: this.$t('quan-bu-fen-lei'),
          path: 'category'
        },
      ]
    },
  },
  mounted() {
    this.init()
    this.initGoods()
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    checkPage() {
      if (this.okNum == 4) {
        this.$store.state.showLoading = false
      }
    },
    init() {
      // 轮播图
      getBannerList().then(res => {
        this.bannerList = res.data.Items
        this.okNum += 1
        this.checkPage()
      })

      // 新品首发
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      shopGoods(form).then(res => {
        this.goodsList = res.data.Items
      })
      form = new FormData()
      form.append('Type', 3)
      articleList(form).then(res => {
        let list = res.data.Items
        if (list.length > 0) {
          this.Content = list[0].Content

          // 获取当前时间戳
          const now = new Date().getTime();
          // 从LocalStorage获取上次弹窗的时间戳
          const lastPopupTime = localStorage.getItem('lastPopupTime') || 0;

          // 检查是否已经过了24小时
          if (now - lastPopupTime >= 12 * 60 * 60 * 1000) {
            // 弹出公告窗口
            Dialog.alert({
              message: this.Content,
              theme: 'round-button',
              confirmButtonText: this.$t('que-ding-0'),
            }).then(() => {
              // 保持 24 小时弹出一次
              // 更新LocalStorage中的时间戳
              localStorage.setItem('lastPopupTime', now.toString());
            });

            // 下面是被注释掉的toast代码，如果需要可以解注释并使用
            // this.$toast({
            //   message: this.Content,
            //   duration: 0,
            //   forbidClick: true,
            // })
          }
        }
      })
    },
    resetTime(time) {
      return moment(time).format('MM/DD HH:mm')
    },
    cancel() {
      this.$store.state.showWelcome = false
    },
    getBoxWidth(len) {
      return `width: ${len * 135 * this.screenScale}px;`
    },
    onLoad() {
      this.page.page += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true

      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', 10)
      form.append('Guess', 1)
      shopGoods(form).then(res => {

        if (isAdd) {
          this.bottomGoodsList = this.bottomGoodsList.concat(res.data.Items)
        } else {
          this.bottomGoodsList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    toGoodsDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    }
  }
}
</script>