<template>
  <div id="app">
    <!-- <transition :name="transitionName"> -->
      <router-view></router-view>
    <!-- </transition> -->

    <div class="safe-left" id="safeLeft"></div>
    <div class="safe-right" id="safeRight"></div>



  </div>
</template>
<script>
import { Popup, Button} from 'vant'
import { configList, userInfo } from '@/api/user'
import loadJs from "@/assets/loadjs"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { getDefaultLanguage } from '@/utils/common'
export default {
  components: {
    Popup,
    Button
  },
  data() {
    return {
      transitionName: '',
      hideKefu: false,
      message: '',
      showMsg: false,
      iconType: '',
    };
  },
  computed: {
    successMsg() {
      return this.$store.state.successMsg
    },
    errorMsg() {
      return this.$store.state.errorMsg
    },
    warningMsg() {
      return this.$store.state.warningMsg
    },
    showLoading() {
      return this.$store.state.showLoading
    },
  },
  watch: {
    $route(to, from) {
      if (to.meta.index < from.meta.index) {
        this.transitionName = 'slide-right'
      } else if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left'
      }
      let name = this.$route.name
      if (name == 'download') {
        this.hideKefu = true
      }
    },
    successMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('success')
        }
      }
    },
    warningMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('warning')
        }
      }
    },
    errorMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('error')
        } else {
          this.cancel()
        }
      }
    },
  },
  mounted() {
    this.init()

    let locale = localStorage.getItem('locale')
    const defaultLanguage = getDefaultLanguage()
    this.i18n.locale = locale ? locale : defaultLanguage

    window.addEventListener('resize', (e) => {
      let path = this.$route.path
      if (path.indexOf('/seller/')  > -1) {
        document.body.className = 'sellerBox'
        setTimeout(() => {
          document.getElementsByTagName("html")[0].style.fontSize = '16px'
        }, 315)
      }
    })


    //左右滑
    // this.resetMover()

    //计算字体缩放比例
    let screenWidth =  document.getElementById('app').offsetWidth
    if (screenWidth > 540) {
      screenWidth = 540
    }
    this.$store.state.screenScale = screenWidth / 375

    let leftDom = document.getElementById('safeLeft')
    let rightDom = document.getElementById('safeRight')
    if (leftDom) {
      leftDom.addEventListener('touchstart', (e) => {
        e.preventDefault()
      })
      leftDom.addEventListener('touchmove', (e) => {
        e.preventDefault()
      })
    }
    if (rightDom) {
      rightDom.addEventListener('touchstart', (e) => {
        e.preventDefault()
      })
      rightDom.addEventListener('touchmove', (e) => {
        e.preventDefault()
      })
    }

  },
  methods: {
    init() {
      configList().then(res => {
        let data = res.data.Items
        console.log("data",data)

        // 语言
        this.$store.state.languageList = data.filter(v => {
          return v.Name == 'language'
        })[0].Value


        // //团队分成比例
        // this.$store.state.oneLevelCommission = data.filter(v => {
        //   return v.name == 'oneLevelCommission'
        // })[0].value
        // this.$store.state.twoLevelCommission = data.filter(v => {
        //   return v.name == 'twoLevelCommission'
        // })[0].value
        // this.$store.state.threeLevelCommission = data.filter(v => {
        //   return v.name == 'threeLevelCommission'
        // })[0].value


        // 分享链接
        this.$store.state.promoteUrl = data.filter(v => {
          return v.Name == 'app_promotion'
        })[0].Value
        this.$store.state.logo = "/upload/tmp/"+ data.filter(v => {
          return v.Name == 'logo'
        })[0].Value

        // reg_code
        this.$store.state.withdraw_min = data.filter(v => {
          return v.Name == 'withdraw_min'
        })[0].Value

        let appName = data.filter(v => {
          return v.Name == 'app_name'
        })[0].Value
        this.$store.state.appName = appName
        document.title = appName
      })

    },
    showMsgModal(type) {
      this.iconType = type
      this.message = type == 'success' ? this.successMsg : (type == 'error' ? this.errorMsg : this.warningMsg)
      this.showMsg = true
      if (type != 'error') {
        setTimeout(() =>{
          this.cancel()
        }, 2000)
      }
    },
    cancel() {
      this.showMsg = false
      this.$store.state.successMsg = ''
      this.$store.state.errorMsg = ''
      this.$store.state.warningMsg = ''
    },
    resetMover() {
      let startX = 0
      let startY = 0
      window.document.addEventListener('touchstart', (e) => {
        startX = e.targetTouches[0].pageX
        startY = e.targetTouches[0].pageY
      })
      window.document.addEventListener('touchmove', (e) => {
        let moveX = e.targetTouches[0].pageX
        let moveY = e.targetTouches[0].pageY
        if (Math.abs(moveX - startX) > Math.abs(moveY - startY)) {//左右滑
          e.preventDefault()
        }
      }, { passive: false })
      //
    }
  }
}
</script>

<style>
/* 设置顶部状态栏的背景颜色 */
/* 在 iOS 14 及以上版本中无效 */
/* 在 iOS 13 及以下版本中生效 */
:root {
  --apple-status-bar-color: #000000;
}

/* 设置顶部状态栏的前景颜色 */
/* 在 iOS 14 及以上版本中生效 */
/* 在 iOS 13 及以下版本中无效 */
@media (prefers-color-scheme: dark) {
  /* 暗色模式 */
  /* 在暗色模式下的前景色 */
  :root {
    --apple-status-bar-style: #ffffff
  }
}
@media (prefers-color-scheme: light) {
  /* 亮色模式 */
  /* 在亮色模式下的前景色 */
  :root {
    --apple-status-bar-style: #000000;
  }
}
</style>
<style lang="less">
/* 页面切换动画效果 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 200ms;
  position: absolute;
  // backface-visibility: hidden;
  // perspective: 1000px;
}
.slide-right-enter,
.slide-right-enter-active {
  animation: bounce-left-in 200ms;
}
.slide-right-leave-active {
  animation: bounce-left-out 200ms;
}
.slide-left-enter,
.slide-left-enter-active {
  animation: bounce-right-in 200ms;
}
.slide-left-leave-active {
  animation: bounce-right-out 200ms;
}

@keyframes bounce-right-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-right-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes bounce-left-in {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-left-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
</style>