<template>
  <div class="new-goods-page">
     <top-bar :title="$t('xin-pin-shou-fa')" :isWhite="hideTitle" :class="hideTitle ? '' : 'clear'" @back="back"></top-bar>
     <div class="top"></div>

     <div class="goods-list">
        <List
          v-if="dataList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="$t('common.noMore')"
          @load="onLoad">
          <div class="list flex-center" v-for="(item, i) in dataList" :key="i" @click="toGoodsDetail(item)"> 
            <div class="bg">
              <img :src="item.MainImage" alt="">
            </div>
            <div class="flex-1">
              <div class="goods-title">{{ item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : '' }}</div>
              <div class="tag">{{ $t('xin-pin-shang-shi') }}</div>
              <div class="price">{{ item.SalePrice }}</div>
              <div class="flex-center-between">
                <div class="time">
                  {{ getTimeStr(item.CreatedAt) }} 
                  {{ $t('shang-xin') }} </div>
                <div class="btn-style">{{ $t('qu-chang-xian') }}</div>
              </div>
            </div>
          </div>
        </List>
        <div class="empty flex-column-center" v-if="dataList.length == 0 && isInit">
          <img :src="emptyUrl" alt="">
          <span class="desc">{{ $t('zan-wu-shu-ju') }}</span>
        </div>
     </div>
  </div>
</template>
<script>
import { List } from 'vant'
import topBar from '@/components/topBar'
import { shopGoods } from '@/api/index'
import { getTimeStr } from '@/utils/common'
export default {
  components: {
    topBar,
    List
  },
  data() {
    return {
      dataList: [],
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
			emptyUrl: require('../../assets/imgs/empty.png'),
			goodsUrl: require('../../assets/imgs/goods1.jpg'),
      hideTitle: false
    }
  },
  mounted() {
    this.init()
    let dom = document.querySelector('.new-goods-page')
    dom.addEventListener('scroll', (e) => {
      let top = dom.scrollTop
      if (top > 200) {
        this.hideTitle = true 
      } else {
        this.hideTitle = false 
      }
    })
  },
  methods: {
    getTimeStr,
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
     
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      shopGoods(form).then(res => {

        if (isAdd) {
          this.dataList = this.dataList.concat(res.data.Items)
        } else {
          this.dataList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    toGoodsDetail(data) {
      sessionStorage.setItem('goodsDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    }
  }
}
</script>