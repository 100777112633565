<template>
  <div  class="wallet-page">
    <top-bar :title="$t('wo-de-qian-bao')" :isWhite="true"  @back="back"></top-bar>
    <div class="top">
      <div class="box">
        <div class="title flex-center-start">
          {{ $t('wo-de-zi-chan') }} <img :src="vipUrl" alt="">
        </div>

        <div class="flex-center info big">
          <div class="flex-1 flex-column-center">
            <div class="label">{{ $t('yu-e') }}</div>
            <div class="count">{{userMoney}}</div>
          </div>
          <div class="flex-1 flex-column-center" @click="toPath('bank')">
            <div class="label">{{ $t('yin-hang-ka') }}</div>
            <div class="count flex-center">
              {{bankSize}}
              <span class="small">{{ $t('zhang') }}</span>
            </div>
          </div>
        </div>

        <div class="flex-center info ">
          <div class="flex-1 flex-column-center">
            <div class="label">{{ $t('you-hui-quan') }}</div>
            <div class="count">
              0
              <span class="default">{{ $t('zhang-0') }}</span>
            </div>
          </div>
          <div class="flex-1 flex-column-center">
            <div class="label">{{ $t('ji-fen-0') }}</div>
            <div class="count">{{userIntegral}}</div>
          </div>
          <div class="flex-1 flex-column-center" v-show="false">
            <div class="label">{{ $t('hui-yuan-deng-ji') }}</div>
            <div class="count">L2</div>
          </div>
        </div>
      </div>
    </div>

    <div class="menu-box flex-center">
      <div class="menu flex-1 flex-column-center" v-for="(item, i) in menuList" :key="i" @click="toPath(item.path)">
        <Icon :name="item.icon" size="22"></Icon>
        <span class="label">{{item.name}}</span>
      </div>
    </div>

    <div class="menu-line">
      <div class="menu flex-1 flex-center-between" v-for="(item, i) in menuList2" :key="i" @click="toPath(item.path)">
        <div  class="flex-center-start">
          <Icon :name="item.icon" size="18" color="#f24e00"></Icon>
          <div class="label">{{item.name}}</div>
        </div>
        <Icon name="arrow" size="16" color="#999"></Icon>
      </div>
    </div>

  </div>
</template>
<script>
import { List, Button, Icon } from 'vant'
import topBar from '@/components/topBar'
import { wallet, bankList } from '@/api/user'
export default {
  components: {
    topBar,
    List,
    Button,
    Icon
  },
  data() {
    return {
      tabIndex: 0,
      userMoney: '0.00',
      userIntegral: '0.00',
      bankSize: 0,
      tabList: [this.$t('wei-shi-yong'), this.$t('yi-shi-yong'), this.$t('yi-guo-qi')],
      dataList: [],
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
			emptyUrl: require('../../assets/imgs/empty.png'),
			vipUrl: require('../../assets/imgs/icon-vip.png'),
			statusUrl1: require('../../assets/imgs/coupon-status1.png'),
			statusUrl2: require('../../assets/imgs/coupon-status2.png'),
    }
  },
  computed: {
    menuList() {
      return [
        {
          icon: 'gold-coin-o',
          name: this.$t('yuechong-zhi'),
          path: 'recharge'
        },
        {
          icon: 'peer-pay',
          name: this.$t('yueti-xian'),
          path: 'withdraw'
        },
        {
          icon: 'gift-o',
          name: this.$t('zhuan-ji-fen'),
          path: 'integral'
        },
      ]
    },
    menuList2() {
      return [
        {
          icon: 'column',
          name: this.$t('zhang-dan-cha-xun'),
          path: 'debt'
        },
        {
          icon: 'gold-coin',
          name: this.$t('chong-zhi-ji-lu'),
          path: 'rechargeLog'
        },
        {
          icon: 'more',
          name: this.$t('ti-xian-ji-lu'),
          path: 'withdrawLog'
        },
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
      this.page.page = 1
      this.dataList = []
      this.finished = false
      this.init()
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    init() {
      wallet().then(res => {
        let data = res.data.Items[0]
        if (data) {
          this.userMoney = data.Balance.toFixed(2)
          this.userIntegral = data.Integral.toFixed(2)
        }
      })

      let form = new FormData()
      form.append('pageSize', 30)
      bankList(form).then(res => {
        this.bankSize = res.data.Items.length
      })
    },
  }
}
</script>