<template>
  <div class="setting-page">
     <top-bar :title="$t('she-zhi')" :isWhite="true"  @back="back"></top-bar>

    <div class="user-info flex-center-between" >
      <div class="header">
        <img :src="logoUrl" alt="">
      </div>
      <div class="flex-1">
        <!-- <div class="name">张三小号</div> -->
        <div class="phone">{{ userName }}</div>
      </div>
      <!-- <div class="icon">
        <Icon name="arrow" color="#999" size="16"/>
      </div> -->
    </div>

    <div class="setting-line" @click="toPath('address')">
      <div class="label">{{ $t('shou-huo-di-zhi-guan-li') }}</div>
      <div>
        <Icon name="arrow" color="#999" size="16"/>
      </div>
    </div>
    <!-- <div class="setting-line mt-10">
      <div class="label">绑定登录账号</div>
      <div>
        <Icon name="arrow" color="#999" size="16"/>
      </div>
    </div> -->
    <!-- <div class="setting-line">
      <div class="label">修改登录账号</div>
      <div>
        <Icon name="arrow" color="#999" size="16"/>
      </div>
    </div> -->
    <div class="setting-line mt-10" @click="toPath('editPassword')">
      <div class="label">{{ $t('xiu-gai-deng-lu-mi-ma') }}</div>
      <div>
        <Icon name="arrow" color="#999" size="16"/>
      </div>
    </div>
    <div class="setting-line" @click="toPath('editSafePassword')">
      <div class="label">{{ $t('zhi-fu-mi-ma-guan-li') }}</div>
      <div>
        <Icon name="arrow" color="#999" size="16"/>
      </div>
    </div>
    <div class="setting-line" @click="showConfirm">
      <div class="label">{{ $t('zhang-hao-zhu-xiao') }}</div>
      <div>
        <Icon name="arrow" color="#999" size="16"/>
      </div>
    </div>
    <div class="setting-line mt-10" @click="toPath('lang')">
      <div class="label">{{ $t('duo-yu-yan') }}</div>
      <div>
        <Icon name="arrow" color="#999" size="16"/>
      </div>
    </div>
    <div class="setting-line mt-10" @click="toPath('about')">
      <div class="label">{{ $t('guan-yu-wo-men') }}</div>
      <div>
        <Icon name="arrow" color="#999" size="16"/>
      </div>
    </div>
    <div class="setting-line center mt-20" @click="logout">
      <div class="label">{{ $t('tui-chu-deng-lu') }}</div>
    </div>

    
    <Popup v-model="showPopup" class="msg-popup">
      <div class="msg-modal">
        <div class="title">{{ $t('ti-shi') }}</div>
        <div class="msg">{{ $t('que-ren-zhu-xiao-zhang-hao') }}</div>
        <div class="btn-style">
          <Button @click="cancel">{{ $t('qu-xiao') }}</Button>
          <Button class="blue">{{ $t('que-ding') }}</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Button, Icon, Popup } from 'vant'
import topBar from '@/components/topBar'
import { userInfo } from '@/api/user'
import { removeToken } from '@/utils/auth'
export default {
  components: {
    Button,
    Icon,
    topBar,
    Popup
  },
  data() {
    return {
      logoUrl: require('../../assets/imgs/logo.png'),
      showPopup: false,
      userName: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      userInfo().then(res => {
        let data = res.data
        this.userName = data.Phone || data.Email
      })
    },
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    logout() {
      removeToken()
      this.$router.push({
        name: 'index'
      })
    },
    showConfirm() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>