<template>
  <div class="message-page">
    <top-bar :title="$t('xiao-xi')" :isWhite="true" @back="back"></top-bar>

    <div class="tabs flex-center">
      <div class="tab" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{ $t('ke-fu-xiao-xi') }}</div>
      <div class="tab" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{ $t('ping-tai-xiao-xi') }}</div>
      <div class="tab" :class="tabIndex == 3 ? 'active' : ''" @click="changeTab(3)">{{ $t('zhuan-shu-si-xin') }}</div>
    </div>

    <div class="message-box">
      <div class="list-box" v-show="tabIndex == 1">
        <div class="list flex-center-between" :class="item.UnreadMessageCount > 0 ? 'red' : ''" v-for="(item, i) in messageList" :key="i" @click="toChat(item)">
          <div class="header">
            <img :src="logoUrl" alt="">
          </div>
          <div class="flex-1 w-1">
            <div class="name">{{showName(item)}}</div>
            <div class="content">{{item.Content}}</div>
          </div>
          <div class="time">
            <div>{{item.LastMessageTime.substring(0, 10)}}</div>
            <div>{{item.LastMessageTime.substring(11)}}</div>
          </div>
        </div>
      </div>

      <div  class="notice-list" v-show="tabIndex == 2" >
        <div class="list" v-for="(item, i) in noticeList" :key="i" @click="toNotice(item)">
          <div class="logo">
            <img :src="logoUrl" alt="">
          </div>
          <div class="title">{{item.Title}}</div>
          <div class="content">{{item.Content}}</div>
        </div>
      </div>

      <div  class="notice-list" v-show="tabIndex == 3">
        <div class="list" v-for="(item, i) in letterList" :key="i" @click="toNotice(item)">
          <div class="logo">
            <img :src="logoUrl" alt="">
          </div>
          <div class="title">{{item.Title}}</div>
          <div class="content">{{item.Content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { conversationList } from '@/api/shop'
import { getUserId } from '@/utils/auth'
export default {
  components: {
    topBar
  },
  data() {
    return {
      tabIndex: 1,
      logoUrl: require('../../assets/imgs/kefu.jpg'),
      messageList: [ ],
      noticeList: [],
      letterList: [ ],
    }
  },
  computed: {
    userId() {
      return getUserId()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('pageSize', 30)
      conversationList(form).then(res => {
        this.messageList = res.data.Items
      })
    },
    // 显示名称
    showName(item) {
      let uid = getUserId()
      if (uid != item.ReceiverID) {
        if(item.ReceiverShop.ID > 0) {
          return item.ReceiverShop.Name
        } else {
          return item.ReceiveUser.Email || item.ReceiveUser.Phone
        }
      } else {
        if(item.SenderShop.ID > 0) {
          return item.SenderShop.Name
        } else {
          return item.SendUser.Email || item.SendUser.Phone
        }
      }
    },
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    toChat(data) {
      let id = ''
      let name = this.showName(data)
      if (this.userId == data.ReceiverID) {
        id = data.SenderID
      } else {
        id = data.ReceiverID
      }

      this.$router.push({
        name: 'chat',
        query: {
          uid: id,
          name: name
        }
      })
    },
    toNotice(data) {
       this.$router.push({
        name: 'noticeDetail'
      })
    }
  }
}
</script>