<template>
  <div class="order-list-page top">
    <top-bar :title="$t('ti-xian-ming-xi')" :isWhite="true" @back="back"></top-bar>



    <div class="tabs top flex-center-between">
      <div class="tab flex-center" v-for="(item, i) in tabList" :key="i" @click="changeTab(i)" :class="tabIndex == i ? 'active' : ''">{{item}}</div>
    </div>

    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <div class="commission-list">
        <List
          v-if="orderList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && orderList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list " v-for="(item, i) in orderList" :key="i">
            <div class="left">
              <div class="title">{{ $t('ti-xian-dan-hao') }}<span>{{item.ID}}</span></div>
              <div class="flex-wrap">
                <div class="status " :class="item.Status == 1 ? 'green' : 'yellow'">{{ item.Status == 0 ? $t('dai-shen-he') : (item.Status == 1 ?  $t('yi-tong-guo') :  $t('yi-ju-jue')) }}</div>
                <!-- <div class="status yellow">{{ $t('da-kuan-cheng-gong') }}</div> -->
              </div>
              <div class="info-line">
                <div class="line">{{ $t('ti-xian-shi-jian') }} {{item.CreatedAt}}</div>
                <div class="line">{{ $t('da-kuan-shi-jian') }} {{item.UpdatedAt}}</div>
                <div class="line">{{ $t('ti-xian-jine') }} {{item.Price}}</div>
              </div>
            </div>
          </div>
        </List>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import topBar from '@/components/topBar'
import { getWithdrawLog } from '@/api/user'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      tabIndex: 0,
      orderList: [],
      form: {
        status: ''
      },
      page: {
        page: 1,
        limit: 100,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      teamUrl: require('../../assets/imgs/team.jpg')
    }
  },
  computed: {
    tabList() {
      return [
        this.$t('quan-bu'),
        this.$t('dai-shen-he'),
        this.$t('yi-tong-guo'),
        this.$t('yi-ju-jue'),
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
      if (i == 0) {
        this.form.status = ''
      } else {
        this.form.status = i - 1
      }
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('Status', this.form.status)
      getWithdrawLog(form).then(res => {
        if (isAdd) {
          this.orderList = this.orderList.concat(res.data.Items)
        } else {
          this.orderList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    onRefresh() {
      this.page.page = 1
      this.init()
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    }
  }
}
</script>