<template>
  <div class="edit-info-page">
    <top-bar :title="$t('bian-ji-zi-liao')" :isWhite="true" @back="back"></top-bar>

    <div  class="form-box">
      <div class="form-item flex-center-between small">
        <div class="label">{{ $t('tou-xiang') }}</div>
        <div class="header" @click="chooseFile">
          <img :src="form.Logo ? `/upload/${form.Logo}` : headerUrl" alt="">
        </div>
      </div>

      <div class="form-item flex-center-start">
        <div class="label">{{ $t('yong-hu-ming-0') }}</div>
        <div class="input">
          <input type="text" v-model="form.userName" :placeholder="$t('qing-shu-ru-yong-hu-ming')">
        </div>
      </div>
      <div class="form-item flex-center-start">
        <div class="label">{{ $t('xing-bie') }}</div>
        <div class="input">{{ $t('nv') }}</div>
      </div>
      <div class="form-item flex-center-start">
        <div class="label">{{ $t('sheng-ri') }}</div>
        <div class="input">2012-12-12</div>
      </div>

      <Button>{{ $t('que-ren-xiu-gai-0') }}</Button>
    </div>



    <input type="file" class="hide-box" id="fileInput" @change="changeFile" accept="image/*"> 
  </div>
</template>
<script>
import { Button, Icon, Popup, Picker } from 'vant'
import topBar from '@/components/topBar'
import { uploadFile } from '@/api/user'
export default {
  components: {
    topBar,
    Button,
    Icon,
    Popup,
    Picker
  },
  data() {
    return {
      form: {
        userName: this.$t('zhang-san-0'),
        Logo: ''
      },
      headerUrl: require('../../assets/imgs/logo.png'),
      tabIndex: 0,
      evaluteList: [],
      showPopup: false,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    chooseFile() {
      document.getElementById('fileInput').value = ''
      document.getElementById('fileInput').click()
    },
     changeFile(e) {
      let file = e.target.files[0]
      let form = new FormData()
      form.append('file', file)
      uploadFile(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('shang-chuan-cheng-gong-0'))
          this.form.Logo = res.data.FileName
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    cancel() {
      this.showPopup = false
    },
    chooseType(e) {
      this.form.type = e.value
      this.form.typeName = e.text
      this.showPopup = false
    },
    changeType() {
      this.showPopup = true
    }
  }
}
</script>