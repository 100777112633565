<template>
  <div class="category-page">
    <div class="top">{{ $t('fen-lei-0') }}</div>

    <div class="category-box flex">
      <div class="left">
        <div class="left-outer">
          <div class="left-inner">
            <div class="classify flex-center" :class="tabIndex == i ? 'active' : ''" v-for="(item, i) in classifyList" :key="i" @click="changeClassify( i)">
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="banner">
          <Swipe :autoplay="3000" :show-indicators="false">
            <SwipeItem v-for="(item, i) in bannerList" :key="i">
              <div class="img">
                <img :src="item.Img" alt="">
              </div>
            </SwipeItem>
          </Swipe>
        </div>

        <div class="category-list" v-for="(item, i) in categoryList" :key="i">
          <div >
            <div class="title" v-if="item.children && item.children.length > 0">{{item.title}}</div>
            <div class="list flex-wrap" v-if="item.children && item.children.length > 0">
              <div class="category flex-column-center-start" v-for="(category, index) in item.children" :key="index" @click="toSearch(category)">
                <img :src="category.Image" alt="" v-if="category.Image">
                <span class="label">{{category.title}}</span>
              </div>
            </div>
            <div v-if="!item.children || item.children.length == 0" class="list flex-wrap">
              <div class="category flex-column-center-start"  @click="toSearch(item)">
                <img :src="item.Image" alt="" v-if="item.Image">
                <span class="label">{{item.title}}</span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {  Swipe, SwipeItem } from 'vant'
import { getCategoryList } from '@/api/index'
export default {
  components: {
    Swipe,
    SwipeItem
  },
  data() {
    return {
      tabIndex: 0,
      bannerList: [
        {
          Img: require('../../assets/imgs/banner1.jpeg'),
        },
        {
          Img: require('../../assets/imgs/banner2.jpeg'),
        },
      ],
      logoUrl: require('../../assets/imgs/logo.png'),
      allCategoryList: [],
      classifyList: [],
      categoryList: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let data = sessionStorage.getItem('categoryDataBak')
      if (data) {
        this.resetCategoryData(JSON.parse(data))
      }
      let form = new FormData()
      form.append('pageSize', 5000)
      getCategoryList(form).then(res => {
        this.resetCategoryData(res)
        sessionStorage.setItem('categoryDataBak', JSON.stringify(res))
      })
    },
    resetCategoryData(res) {
      // 推荐分类
      let showCategoryList = res.data.Items.filter(v => {
        return v.IsHot == 1 && v.IsShow == 1
      })
      let firstCategoryList  = this.resetHotCategory(showCategoryList)

      // 其他分类
      let categoryList = res.data.Items.filter(v => {
        return v.IsShow == 1
      })
      let allCategoryList = this.resetCategory(categoryList, 0)
      allCategoryList = this.clearEmptyChild(allCategoryList) // 合并没有3级的分类
      //左侧一级分类
      this.classifyList = [{
        name: this.$t('tui-jian-fen-lei')
      }].concat(allCategoryList.map(v => {
        return {
          name:v.title
        }
      }))

        // 备份所有数据
      this.allCategoryList = [{
        children: firstCategoryList
      }].concat(allCategoryList)

      this.categoryList = this.allCategoryList[this.tabIndex].children || []
    },
    clearEmptyChild(list) {
      let newList = list.map((v, i) => {
        let otherList = []
        if (v.children && v.children.length > 0) {
          v.children.forEach(val => {
            if (!val.children || val.children.length == 0) {
              otherList.push(val)
            }
          })
          v.children = v.children.filter(val => {
            return val.children && val.children.length > 0
          }).concat({
            title: '',
            children: otherList
          })
        }
        return v
      })
      return newList
    },
    resetHotCategory(list) { // 推荐分类
      let newList = [{
        title: '',
        children: []
      }]
      list.forEach(v => {
        let data = v.ProductCategoryTranslations[0]
        newList[0].children.push({
          ...v,
          title:  data ? data.Name : '',
        })
      })
      return newList
    },
    resetCategory(list, parentId) {
      let treeData = []
      list.forEach(v => {
        if (v.ParentID == parentId) {
          let otherList = list.filter(val => {
            return val.ParentID != parentId
          })
          let children = this.resetCategory(otherList, v.ID)
          if (children.length > 0) {
            v.children = children
          }
          let data = v.ProductCategoryTranslations[0]
          treeData.push({
            ...v,
            title: data ? data.Name : ''
          })
        }
      })
      return treeData
    },
    changeClassify( i) {
      this.tabIndex = i
      this.categoryList = this.allCategoryList[i].children || []
    },
    toSearch(data) {
      this.$router.push({
        name: 'searchResult',
        query: {
          categoryId: data.ID,
          type: 2
        }
      })
    }
  }
}
</script>