<template>
  <div class="car-page">
    <div class="top">{{ $t('gou-wu-che-0') }}</div>

    <div class="car-box" v-if="!showEmpty">
      <div class="title flex-center-between">
        <div>{{ $t('gong-shoppinglistlength-jian-bao-bei', [shoppingList.length]) }}</div>
        <div @click="changeManage">{{isManage ? $t('wan-cheng') : $t('guan-li')}}</div>
      </div>

      <div class="shopping-list">
        <div class="shop" v-for="(item, i) in shoppingList" :key="i">
          <div class="name flex-center-between">
            <div class="  flex-center">
              <Checkbox checked-color="#fa3534" icon-size="16" v-model="item.checkAll" @click="chooseShopAll(i)"></Checkbox>
              <span class="ml-10">{{item.shopName}}</span>
            </div>
            <div  class="small" v-if="item.isCoupon">{{ $t('ling-quan') }}</div>
          </div>

          <div class="goods-list"> 
            <div class="goods flex-center" v-for="(goods, index) in item.goodsList" :key="index">
              <div class="icon">
                <Checkbox checked-color="#fa3534"  icon-size="16" v-model="goods.checked" @click="chooseGoods(i, index)"></Checkbox>
              </div>
              <div class="flex-1 flex-center ml-10">
                <div class="img">
                  <img :src="goods.BusinessProduct.MainImage" alt="">
                </div>
                <div  class="info flex-1">
                  <div class="goods-name">{{goods.BusinessProduct.ProductTranslation && goods.BusinessProduct.ProductTranslation[0] ? goods.BusinessProduct.ProductTranslation[0].Name : ''}}</div>
                  <div class="option" v-if="goods.optionName">{{goods.optionName}}</div>
                  <div class="flex-center-between price-line">
                    <div class="price">{{goods.optionPrice || goods.BusinessProduct.SalePrice}}</div>
                    <div class="flex-center">
                      <div class="minus flex-center" @click="minusNumber(i, index)">
                        <Icon name="minus" />
                      </div>
                      <div class="count flex-center">{{goods.Number}}</div>
                      <div class="plus flex-center" @click="plusNumber(i, index)">
                        <Icon name="plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom flex-center-between">
      <div class="flex-center">
        <Checkbox checked-color="#fa3534" icon-size="16" v-model="checkAll" @click="chooseAllGoods"></Checkbox>
        <span class="ml-10">{{ $t('quan-xuan-0') }}</span>
      </div>
      <div class="flex-center" v-if="!isManage">
        {{ $t('gong-ji') }} <span class="price">{{totalPrice.toFixed(2)}}</span>
      </div>
      <div>
        <Button @click="submit">{{isManage ? $t('shan-chu') : $t('li-ji-jie-suan')}}</Button>
      </div>
    </div>

    <div class="empty flex-column-center" v-if="showEmpty">
      <img :src="emptyUrl" alt="">
      <span>{{ $t('nin-de-gou-wu-che-kong-kong-ru-ye') }}</span>
      <Button @click="toPath('index')">{{ $t('dao-chu-guang-guang') }}</Button>
    </div>
  </div>
</template>
<script>
import { Button, Checkbox, Icon } from 'vant'
import { carList, removeCar } from '@/api/shop'
export default {
  components: {
    Button,
    Checkbox,
    Icon
  },
  data() {
    return {
      emptyUrl: require('../../assets/imgs/empty2.png'),
      showEmpty: false,
      isManage: false,
      checkAll: false,
      totalPrice: 0,
      shoppingList: [ ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      carList().then(res => {
        let list = res.data.Items
        this.shoppingList = []
        list.forEach(v => {
          let shop = v.BusinessProduct.ShopID
          let shopName = v.BusinessProduct.Shop.Name
          let optionPrice = v.BusinessProductSpecificationsRelation.ID ? v.BusinessProductSpecificationsRelation.Price : ''
          if (optionPrice > 0) {
            v.SalePrice = optionPrice
          }
          let optionName = ''
          if (v.ProductSpecifications && v.ProductSpecifications.length > 0) {
            optionName = v.ProductSpecifications.map(v => {
              return v.Name + ':' + v.Value
            }).join(';')
          }
          let index = this.shoppingList.findIndex(v => {
            return v.ShopID == shop
          })
          if (index > -1) {
            this.shoppingList[index].goodsList.push({
              ...v,
              optionName: optionName,
              optionPrice: optionPrice
            })
          } else {
            let param = {
              ShopID: shop,
              shopName: shopName,
              goodsList: [{
                ...v,
                optionName: optionName,
                optionPrice: optionPrice
              }]
            }
            this.shoppingList.push(param)
          }
        })

      })
    },
    minusNumber(i, index) {
      let Number = this.shoppingList[i].goodsList[index].Number || 1
      if (Number > 1) {
        Number -= 1
      }
      this.$set(this.shoppingList[i].goodsList[index], 'Number', Number)
    },
    plusNumber(i, index) {
      let Number = this.shoppingList[i].goodsList[index].Number || 1
      Number += 1
      this.$set(this.shoppingList[i].goodsList[index], 'Number', Number)
    },
    changeManage() {
      this.isManage = !this.isManage
    },
    chooseShopAll(i) {
      let checkAll = this.shoppingList[i].checkAll
      this.shoppingList[i].goodsList.forEach((v, index) => {
        this.$set(this.shoppingList[i].goodsList[index], 'checked', checkAll)
      })
      this.resetCheckAll()
    },
    chooseGoods(i, index) {
      let len = this.shoppingList[i].goodsList.filter(v => {
        return v.checked
      }).length
      if (len == this.shoppingList[i].goodsList.length) {
        this.$set(this.shoppingList[i], 'checkAll', true)
      } else {
        this.$set(this.shoppingList[i], 'checkAll', false)
      }
      this.resetCheckAll()
    },
    chooseAllGoods() {
      this.shoppingList.forEach((v, i) => {
        this.$set(this.shoppingList[i], 'checkAll', this.checkAll)
        v.goodsList.forEach((val, index) => {
          this.$set(this.shoppingList[i].goodsList[index], 'checked', this.checkAll)
        })
      })
      this.getTotalPrice()
    },
    resetCheckAll() {
      let len = this.shoppingList.filter(v => {
        return v.checkAll
      }).length
      this.checkAll = len == this.shoppingList.length
      this.getTotalPrice()
    },
    getTotalPrice() {
      let total = 0
      let list = []
      this.shoppingList.forEach(v => {
        v.goodsList.forEach(val => {
          if (val.checked) {
            list.push(val)
          }
        })
      })
      list.forEach(v => {
        let price = v.optionPrice || v.BusinessProduct.SalePrice
        total += price * v.Number
      })
      this.totalPrice = total
    },
    submit() {
      let list = []
      this.shoppingList.forEach(v => {
        v.goodsList.forEach(val => {
          if (val.checked) {
            list.push(val)
          }
        })
      })
      let ids = list.map(v => {
        return v.ID
      })
      if (this.isManage) { // 管理
        let i = 0
        ids.forEach(v => {
          let form = new FormData()
          form.append('ID', v)
          removeCar(form).then(res => {
            if (res.code == 0) {
              i += 1
              if (i == ids.length) {
                this.$toast.success(this.$t('shan-chu-cheng-gong-1'))
                this.init()
              }
            } else {
              this.$toast.fail(res.msg)
            }
          }).catch(e => {
            i += 1
            if (i == ids.length) {
              this.$toast.success(this.$t('xi-tong-chu-cuo'))
              this.init()
            }
          })
        })
      } else { // 购买
        let orderData = this.shoppingList.map(v => {
          return {
            Shop: v.ShopID,
            goodsList: v.goodsList.map(val => {
              return {
                ...val.BusinessProduct,
                BusinessProductSpecificationsRelationID: val.BusinessProductSpecificationsRelationID,
                count: val.Number,
                optionName: val.optionName,
                optionPrice: val.optionPrice
              }
            })

          }
        })


        sessionStorage.setItem('orderData', JSON.stringify(orderData))
        this.$router.push({
          name: 'order'
        })
      }
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    }
  }
}
</script>