<template>
  <div class="order-list-page clear">
    <top-bar :title="$t('wo-de-tuan-dui')" :isWhite="true" @back="back"></top-bar>

    <!-- <div class="tabs flex-center-between">
      <div class="tab flex-center" v-for="(item, i) in tabList" :key="i" @click="changeTab(i)" :class="tabIndex == i ? 'active' : ''">{{item}}</div>
    </div> -->

    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <div class="team-list">
        <List
          v-if="orderList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && orderList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list flex-center" v-for="(item, i) in orderList" :key="i">
            <div class="header">
              <img :src="teamUrl" alt="">
            </div>
            <div class="info flex-1">
              <div class="name">
                {{ $t('yong-hu-ming-1') }} <span>{{ item.Phone || item.Email }}</span>
              </div>
              <div class="time">{{ $t('jia-ru-shi-jian') }}
                <span>{{item.CreatedAt}}</span>
              </div>
            </div>
          </div>
        </List>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import topBar from '@/components/topBar'
import { myTeam } from '@/api/user'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      tabIndex: 0,
      orderList: [],
      page: {
        page: 1,
        limit: 100,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      teamUrl: require('../../assets/imgs/team.jpg')
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      myTeam(form).then(res => {
        if (isAdd) {
          this.orderList = this.orderList.concat(res.data.Items)
        } else {
          this.orderList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  }
}
</script>