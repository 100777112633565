<template>
  <div class=" address-page   ">
    <top-bar :title="$t('shou-huo-di-zhi')"  :isWhite="true" @back="back"></top-bar>

		<div class="address-list" >
			<div class="list" v-for="(item, i) in addressList" :key="i" @click="chooseAddress(item)">
        <div class="flex-center box">
          <div class="icon">
            <Icon name="location-o" size="20px"/>
          </div>
          <div class="flex-1 ml-20">
            <div class="address">{{item.FullName}} {{item.Phone}}</div>
            <div class="address mt-5">{{item.Country}} {{item.Province}} {{item.City}} {{item.Address}}</div>
          </div>
        </div>
        <div class="flex-center-end btn-line">
          <div class="flex-center" @click.stop="setDefault(item)" v-if="item.IsSelected == 2">
            <Icon name="edit" size="16"></Icon>
            <span>{{ $t('she-wei-mo-ren-di-zhi') }}</span>
          </div>
          <div class="flex-center" @click.stop="removeData(item)">
            <Icon name="delete" size="16"></Icon>
            <span>{{ $t('shan-chu-di-zhi') }}</span>
          </div>
        </div>
      </div>
		</div>

    <div class="empty" v-if="isInit && addressList.length == 0">
      <img :src="emptyUrl" alt="">
      <div>{{ $t('zan-wu-shu-ju') }}</div>
    </div>
    
    <div class="bottom-btn">
      <Button @click="toAdd">{{ $t('tian-jia-shou-huo-di-zhi') }}</Button>
    </div>

  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import { getAddressList, removeAddress, addressSelect } from '@/api/setting'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    Button,
    Icon
  },
  data() {
    return {
      emptyUrl: require('../../assets/imgs/empty2.png'),
      hideAdd: false,
      addressList: [],
      isInit: false,
      pageType: ''
    }
  },
  mounted() {
    this.init()
    this.pageType = this.$route.query.type
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('Current', 1)
      form.append('PageSize', 20)
      getAddressList(form).then(res => {
        this.isInit = true
        this.addressList = res.data.Items
      })
    },
    back() {
      this.$router.go(-1)
    },
    toAdd() {
      this.$router.push({
        name: 'addAddress'
      })
    },
    removeData(data) {
      let form = new FormData()
      form.append('ID', data.ID)
      removeAddress(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('shan-chu-cheng-gong-1'))
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    setDefault(data) {
      let form = new FormData()
      form.append('ID', data.ID)
      addressSelect(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('she-zhi-cheng-gong'))
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    chooseAddress(data) {
      if (this.pageType == 1) {
        sessionStorage.setItem('addressData', JSON.stringify(data))
        sessionStorage.setItem('chooseAddress', 1)
        this.back()
      }
    }
  }
}
</script>