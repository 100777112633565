<template>
  <div class="order-detail-page refund">
    <top-bar title="退款详情" :isWhite="true" @back="back"></top-bar>
    <div class="top flex-center-between">
      <div class="status"> 
        退款已完成
      </div>
    </div>
    
    <div class="refund-result">
      <div class="label">退款方式：我要退款（无需退货）</div>
      <div class="desc">退款已完成</div>
      <div class="desc">完成时间：2024-01-05 00:00:02</div>
    </div>
    

    <div class="pay-info small">
      <div class="title">退款信息</div>
      <div class="flex-center-between goods" >
        <div class="img">
          <img :src="goodsUrl" alt="">
        </div>
        <div class="flex-1">
          <div class="name">[ LUV IS TRUE ] DE CHERRY TEE - BASIC FIT 100% aut</div>
          <div class="price">299.99 x1</div>
        </div>
      </div>
      <div class="line">
        <div class="label">退款原因</div>
        <div class="value">PayPal</div>
      </div>
      <div class="line">
        <div class="label">退款说明</div>
        <div class="value">999.99</div>
      </div>
      <div class="line">
        <div class="label">退款金额</div>
        <div class="value">中通</div>
      </div>
      <div class="line">
        <div class="label">申请件数</div>
        <div class="value">0</div>
      </div>
      <div class="line">
        <div class="label">申请时间</div>
        <div class="value">-10.00</div>
      </div>
      <div class="line">
        <div class="label">退款编号</div>
        <div class="value">1298310238019283</div>
      </div>
    </div>

  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      tabIndex: 0,
      orderList: [],
      page: {
        page: 1,
        limit: 100,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      orderUrl: require('../../assets/imgs/icon-order.png'),
      goodsUrl: require('../../assets/imgs/goods2.jpg'),
      orderDetail: {
        shopName: '张三小店',
        goods: [
          {
            Img: require('../../assets/imgs/goods2.jpg'),
            Title: '白色T恤女春装2022新款韩版半袖纯色修身中袖上衣内搭5分袖针织衫',
            Price: 99.9,
            optionName: '颜色：白色;尺寸：XL',
            count: 1,
          }
        ],
      }
    }
  },
  computed: {
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      this.orderList = this.orderList.concat(this.orderListBak)
      if (this.page.page > 3) {
        this.finished = true
      }
      this.isInit = true
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  }
}
</script>