<template>
  <div class="log-page">
    <top-bar :title="$t('chong-zhi-ji-lu')" :isWhite="true" @back="back"></top-bar>

    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <div class="log-list">
        <List
          v-if="dataList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && dataList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list flex-center" v-for="(item, i) in dataList" :key="i" >
            <div class="money flex-1">{{item.Amount}}</div>
            <div class="type flex-1">{{ $t('chong-zhi-0') }}</div>
            <div class="info flex-2">
              <div class="label">{{item.Status == 0 ? $t('dai-shen-he') : (item.Status == 1 ?  $t('yi-tong-guo') :  $t('yi-ju-jue'))}}</div>
              <div class="time">{{item.CreatedAt}}</div>
            </div>
          </div>
        </List>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import topBar from '@/components/topBar'
import { rechargeLog } from '@/api/user'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      tabIndex: 0,
      dataList: [],
      page: {
        page: 1,
        limit: 10,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      dataBak: []
    }
  },
  computed: {
    tabList() {
      return [
        this.$t('quan-bu'),
        this.$t('chu-li-zhong'),
        this.$t('dai-shou-huo'),
        this.$t('yi-qu-xiao'),
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      rechargeLog(form).then(res => {
        if (isAdd) {
          this.dataList = this.dataList.concat(res.data.Items)
        } else {
          this.dataList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
     
    },
    onRefresh() {
      setTimeout(() => {
        this.page.page = 1
        thia.dataList = []
        this.init()
      }, 1000);
    },
  }
}
</script>