<template>
  <div class="order-list-page top2">
    <top-bar :title="$t('yong-jin-ming-xi')" :isWhite="true" @back="back"></top-bar>

    <div class="money-line flex-center-between">
      <div>{{ $t('ti-xian-yong-jin') }}: <span>{{parseFloat(totalMoney).toFixed(2)}}</span></div>
      <div @click="toPath('withdraw')">{{ $t('qu-ti-xian') }}</div>
    </div>

    <div class="tabs top2 flex-center-between">
      <div class="tab flex-center" v-for="(item, i) in tabList" :key="i" @click="changeTab(i)" :class="tabIndex == i ? 'active' : ''">{{item}}</div>
    </div>

    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <div class="commission-list">
        <List
          v-if="orderList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && orderList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list flex-center-between" v-for="(item, i) in orderList" :key="i">
            <div class="left">
              <div class="status green">{{ $t('yi-wan-cheng') }}</div>
              <div class="info">
                <div class="line">{{ $t('zhang-hu-yu-e') }}：<span>{{item.DebtAfter}}</span></div>
                <div class="line">
                  {{ $t('xia-dan-shi-jian') }} <span>{{item.CreatedAt}}</span>
                  </div>
              </div>
            </div>
            <div class="right flex-column-center">
              <div class="count">{{item.Practical}}</div>
              <div>{{ $t('yong-jin-shou-yi') }}</div>
            </div>
          </div>
        </List>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import { payDetailList, wallet } from '@/api/user'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      tabIndex: 0,
      orderList: [],
      page: {
        page: 1,
        limit: 100,
      },
      totalMoney: 0,
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      orderListBak: [],
      teamUrl: require('../../assets/imgs/team.jpg'),
      userMoney: '0.00'
    }
  },
  computed: {
    tabList() {
      return [
        this.$t('quan-bu'),
        this.$t('dai-fu-kuan'),
        this.$t('yi-fu-kuan'),
        this.$t('wan-cheng'),
      ]
    }
  },
  mounted() {
    this.init()
    this.initWallet()
  },
  methods: {
    initWallet() {
      wallet().then(res => {
        let data = res.data.Items[0]
        if (data) {
          this.userMoney = data.Balance.toFixed(2)
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('Type', 21)
      payDetailList(form).then(res => {
        if (isAdd) {
          this.orderList = this.orderList.concat(res.data.Items)
        } else {
          this.orderList = res.data.Items
        }
        // 更新总金额
        this.orderList.forEach(item => {
          this.totalMoney += item.Practical
        })
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    }
  }
}
</script>