<template>
  <div class="open-shop-page">
    <top-bar :title="$t('tian-xie-zi-liao')" :isWhite="true" @back="back"></top-bar>
<!--
    <div class="type-box" v-show="step == 1">
      <div class="flex-center-start type-line" v-for="(item, i) in typeList" :key="i" @click="changeType(i)">
        <Icon name="checked" color="#f90" size="24" v-if="form.typeId == item.ID"></Icon>
        <Icon name="circle" color="#999" size="24" v-else></Icon>
        <span>{{item.Name}}</span>
      </div>
      <div class="btn-line">
        <Button @click="submit">确定</Button>
      </div>
      <div class="desc">选择认证类型后不可更改,请认真选择</div>
    </div> -->

    <div class="info-box" v-show="step == 2">
      <div class="form-title">{{ $t('ji-chu-xin-xi') }}</div>
      <div class="shop-form">
        <div class="form-item">
          <div class="input flex-center-between" @click="changeType">
            <span :class="form.typeName ? '' : 'gray'">{{form.typeName || $t('qing-xuan-ze-hang-ye-lei-xing')}}</span>
            <img :src="downUrl" alt="">
          </div>
        </div>

        <div class="form-item">
          <div class="input flex-center-between" @click="changeCategory">
            <span :class="form.categoryName ? '' : 'gray'">{{form.categoryName || $t('qing-xuan-ze-jing-ying-lei-mu')}}</span>
            <img :src="downUrl" alt="">
          </div>
        </div>

        <div class="flex-center-between file-line">
          <div class="label">{{ $t('dian-pu-logo') }}</div>
          <div class="file-box flex-column-center" v-if="!form.Logo" @click="chooseFile(1)">
            <Icon name="plus" size="20px"></Icon>
            <span class="mt-5">{{ $t('xuan-ze-tu-pian') }}</span>
          </div>
          <div class="file-preview" v-else>
            <img :src="`/upload/tmp/${form.Logo}`" width="77"  height="77" alt="">
          </div>
          <input type="file" class="hide-box" id="fileInput" @change="changeFile" accept="image/*">
        </div>

        <div class="desc">{{ $t('dang-qian-hang-ye-fei-shuai') }}
          {{typeRate}}%
          </div>
      </div>
      <div class="form-title">{{ $t('ren-zheng-xin-xi') }}</div>

      <div class="shop-form">
        <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.RealName" :placeholder="$t('qing-shu-ru-zhen-shi-xing-ming')">
          </div>
        </div>

         <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.Phone" :placeholder="$t('qing-shu-ru-shou-ji-hao-0')">
          </div>
        </div>

         <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.IdCard" :placeholder="$t('qing-shu-ru-shen-fen-zheng-hao')">
          </div>
        </div>

         <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.Email" :placeholder="$t('qing-shu-ru-you-xiang')">
          </div>
        </div>
      </div>

      <div class="form-title">{{ $t('shen-fen-zheng-jian') }}</div>

      <div class="shop-form">
        <div class="flex-center-start file-line">
          <div class="big-box">
            <div class="file-box flex-column-center" v-if="!form.IdCardFront" @click="chooseFile(2)">
              <Icon name="plus" size="20px"></Icon>
              <span class="mt-5">{{ $t('shen-fen-zheng-zheng-mian') }}</span>
            </div>
            <div class="file-preview" v-else>
              <img :src="`/upload/tmp/${form.IdCardFront}`" width="110" height="110" alt="">
            </div>
          </div>

          <div class="big-box">
            <div class="file-box flex-column-center" v-if="!form.IdCardBack" @click="chooseFile(3)">
              <Icon name="plus" size="20px"></Icon>
              <span class="mt-5">{{ $t('shen-fen-zheng-bei-mian') }}</span>
            </div>
            <div class="file-preview" v-else>
              <img :src="`/upload/tmp/${form.IdCardBack}`" width="110" height="110" alt="">
            </div>
          </div>

          <!-- <div class="big-box">
            <div class="file-box flex-column-center" v-if="!form.HandIdCard" @click="chooseFile(4)">
              <Icon name="plus" size="20px"></Icon>
              <span class="mt-5">手持身份证</span>
            </div>
            <div class="file-preview" v-else>
              <img :src="`/upload/${form.HandIdCard}`" alt="">
            </div>
          </div> -->

        </div>
      </div>

      <div class="form-title">{{ $t('dian-pu-xin-xi') }}</div>
      <div class="shop-form">
        <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.ShopName" :placeholder="$t('qing-shu-ru-dian-pu-ming-cheng')">
          </div>
        </div>

        <div class="form-item textarea">
          <div class="input ">
            <textarea type="text" v-model="form.ShopIntro" :placeholder="$t('qing-shu-ru-dian-pu-miao-shu')"></textarea>
          </div>
        </div>

        <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.ShopCountry" :placeholder="$t('qing-shu-ru-suo-zai-guo-jia-0')">
          </div>
        </div>

        <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.ShopProvince" :placeholder="$t('qing-shu-ru-suo-zai-sheng-fen-0')">
          </div>
        </div>

        <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.ShopCity" :placeholder="$t('qing-shu-ru-suo-zai-cheng-shi-0')">
          </div>
        </div>

        <div class="form-item">
          <div class="input ">
            <input type="text" v-model="form.ShopAddress" :placeholder="$t('qing-shu-ru-xiang-xi-di-zhi')">
          </div>
        </div>

        <div class="desc">{{ $t('1-qing-ren-zhen-tian-xie-zi-liao-zi-liao-ti-jiao-cheng-gong-hou-bu-ke-geng') }}</div>
        <div class="desc">{{ $t('2-xin-xi-jin-yong-yu-shi-ming-ren-zheng-ping-tai-bao-zhang-nin-de-xin-xi-an-quan') }}</div>
      </div>

      <div class="btn-box">
        <Button @click="submit">{{ $t('ti-jiao-shen-he') }}</Button>
      </div>
    </div>

    <Popup v-model="showPopup" position="bottom">
      <Picker
        show-toolbar
        value-key="Name"
        :columns="typeList"
        @confirm="chooseType"
        @cancel="cancel"
        :confirm-button-text="$t('common.ensure')"
        :cancel-button-text="$t('common.cancel')"
      ></Picker>
    </Popup>

    <Popup v-model="showCategoryPopup" position="bottom">
      <div class="checkbox-modal">
        <div class="title flex-center-end">
          <Icon name="close" size="26" color="#666" @click="cancel"></Icon>
        </div>
        <CheckboxGroup v-model="category" @change="chooseCategory" class="checkbox-list">
          <Checkbox :name="item.ID"  v-for="(item, i) in categoryList" :key="i">{{item.title}}</Checkbox>
        </CheckboxGroup>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Icon, Checkbox, Button, Picker, Popup, CheckboxGroup  } from 'vant'
import { industryList, openShop } from '@/api/shop'
import { getCategoryList } from '@/api/index'
import { uploadFile } from '@/api/user'
import topBar from '@/components/topBar'
export default {
   components: {
    topBar,
    Icon,
    Checkbox,
    CheckboxGroup,
    Button,
    Picker,
    Popup
  },
  data() {
    return {
      category: [],
      form: {
        typeId: '',
        typeName: '',
        categoryId: '',
        categoryName: '',
        RealName: '',
        Phone: '',
        IdCard: '',
        Email: '',
        Logo: '',
        IdCardFront: '',
        IdCardBack: '',
        HandIdCard: '',
        ShopName: '',
        ShopIntro: '',
        ShopCountry: '',
        ShopProvince: '',
        ShopCity: '',
        ShopAddress: '',
      },
      showPopup: false,
      showCategoryPopup: false,
      // title: '选择店铺类型',
      step: 2,
      typeRate: '',
      typeList: [],
      categoryList: [],
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      fileType: '', // 1: logo; 2: 身份证正面；3:身份证反面；4: 手持身份证
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      industryList().then(res => {
        this.typeList = res.data.Items
      })
      let form = new FormData()
      form.append('pageSize', 5000)
      getCategoryList(form).then(res => {
        // 推荐分类
        this.categoryList = res.data.Items.filter(v => {
          return v.ParentID == 0 && v.IsShow == 1
        }).map(v => {
          let data = v.ProductCategoryTranslations[0]
          return {
            ...v,
            title: data ? data.Name : ''
          }
        })
      })
    },
    back() {
      this.$router.go(-1)
    },
    submit() {
      if (!this.form.typeId) {
        this.$toast.fail(this.$t('qing-xuan-ze-hang-ye-lei-xing'))
        return
      }
      if (!this.form.categoryId) {
        this.$toast.fail(this.$t('qing-xuan-ze-jing-ying-lei-mu'))
        return
      }
      let form = new FormData()
      form.append('IndustryID', this.form.typeId)
      form.append('CategoryID', this.form.categoryId)
      form.append('Logo', this.form.Logo)
      form.append('RealName', this.form.RealName)
      form.append('Phone', this.form.Phone)
      form.append('IdCard', this.form.IdCard)
      form.append('Email', this.form.Email)
      form.append('IdCardFront', this.form.IdCardFront)
      form.append('IdCardBack', this.form.IdCardBack)
      form.append('HandIdCard', this.form.HandIdCard)
      form.append('ShopName', this.form.ShopName)
      form.append('ShopIntro', this.form.ShopIntro)
      form.append('ShopCountry', this.form.ShopCountry)
      form.append('ShopProvince', this.form.ShopProvince)
      form.append('ShopCity', this.form.ShopCity)
      form.append('ShopAddress', this.form.ShopAddress)
      openShop(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('shen-qing-ti-jiao-cheng-gong'))
          this.back()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    changeType() {
      this.showPopup = true
    },
    changeCategory() {
      this.showCategoryPopup = true
    },
    cancel() {
      this.showPopup = false
      this.showCategoryPopup = false
    },
    chooseCategory(e) {
      let nameList = this.categoryList.filter(v => {
        return e.indexOf(v.ID) > -1
      }).map(v => {
        return v.title
      })
      this.form.categoryId = this.category.join()
      this.form.categoryName = nameList.join()
    },
    chooseType(e) {
      if (!e) {
        this.showPopup = false
        return
      }
      this.form.typeId = e.ID
      this.form.typeName = e.Name
      this.typeRate = e.Percentage
      this.showPopup = false
    },
    chooseFile(type) {
      this.fileType = type
      document.getElementById('fileInput').value = ''
      document.getElementById('fileInput').click()
    },
    changeFile(e) {
      let file = e.target.files[0]
      let form = new FormData()
      form.append('file', file)
      uploadFile(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('shang-chuan-cheng-gong'))
          if (this.fileType == 1) {
            this.form.Logo = res.data.FileName
          } else if (this.fileType == 2) {
            this.form.IdCardFront = res.data.FileName
          } else if (this.fileType == 3) {
            this.form.IdCardBack = res.data.FileName
          } else if (this.fileType == 4) {
            this.form.HandIdCard = res.data.FileName
          }
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
  }
}
</script>