<template>
  <div class="download-page">
    <div class="top flex-center-start">
      <div class="logo">
        <img :src="logoUrl" alt="">
      </div>
      <div>
        <div class="name">Flubit</div>
        <div class="desc">App size：3M</div>
        <div class="download-btn">
          <Button>Free installation</Button>
        </div>
        <div class="flex-center tag">
          <Icon name="question" size="22" color="#0477f9"/>
          Safety Certificate
        </div>
      </div>
    </div>

    <div class="score">
      <div class="flex-center-between">
        <div class="flex-center">
          4.9
          <Icon name="star" size="15" color="#8E8F92" v-for="i in 5" :key="i" />
        </div>
        <div>18 +</div>
      </div>
      <div class="flex-center-between desc">
        <div>9,999 Ratings</div>
        <div>Age</div>
      </div>
    </div>

    <div  class="desc-box">
      <div class="desc-line">1、After downloading the Android phone, click Install</div>
      <div class="desc-line">2、Click on the Iphone phone to return to the desktop after installation. If the download is complete, click on Settings >> Universal >> description file >> Flubit >>Install</div>
    </div>

    <div class="intro">
      <div class="title">Introduction</div>
      <div class="desc">Extremely fast download</div>
    </div>

    <div class="intro">
      <div class="title">Ratings and Reviews</div>
      <div class="flex-center-between">
        <div class="flex-column-center">
          <div class="count big">4.9</div>
          <div class="gray mt-10">out of 5</div>
        </div>
        <div>
          <div class="star-line flex-center">
            <div class="star-bg">
            </div>
            <div class="line-bg">
              <div class="line w-95"></div>
            </div>
          </div>
          <div class="star-line flex-center">
            <div class="star-bg">
              <div class="white-bg w-1"></div>
            </div>
            <div class="line-bg">
              <div class="line w-5"></div>
            </div>
          </div>
          <div class="star-line flex-center">
            <div class="star-bg">
              <div class="white-bg w-2"></div>
            </div>
            <div class="line-bg">
            </div>
          </div>
          <div class="star-line flex-center">
            <div class="star-bg">
              <div class="white-bg w-3"></div>
            </div>
            <div class="line-bg">
            </div>
          </div>
          <div class="star-line flex-center">
            <div class="star-bg">
              <div class="white-bg w-4"></div>
            </div>
            <div class="line-bg">
            </div>
          </div>

          <div  class="gray">9,999 Ratings</div>
        </div>
      </div>
    </div>

    <div class="intro">
      <div class="title">New function</div>
      <div class="desc">Version V1.0#</div>
    </div>

    <div class="intro">
      <div class="title">Information</div>
      <div class="info-line flex-center-between">
        <div class="label">Sellers</div>
        <div></div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">Size</div>
        <div class="value">3M</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">Language</div>
        <div class="value">Multi-language</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">Age rating</div>
        <div class="value">18 years old and above</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">Price</div>
        <div class="value">Free</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="green">Privacy Policy</div>
        <div class="value"></div>
      </div>
    </div>

    <div class="bottom-desc">
      <div>Software Description：</div>
      <div>
        Flubit is a cross-border e-commerce platform jointly released by Flubit Flubit Foundation Inc of the United States in conjunction with a number of local e-commerce platforms to sell products from RCEP countries to the EU....
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from 'vant'
export default {
  components: {
    Icon
  },
  data() {
    return {
      logoUrl: require('../../assets/imgs/download-logo.png'),
      starUrl: require('../../assets/imgs/star5.png'),
    } 
  },
  mounted() {

  },
  methods: {

  }
}
</script>