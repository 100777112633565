<template>
  <div class="feedback-page">
    <top-bar :title="$t('tou-su-jian-yi-0')" :isWhite="true" @back="back"></top-bar>

    <div class="feedback-box">
      <div class="flex-center-between form-item" @click="changeType">
        <div class="form-label">{{ $t('lei-xing-0') }}</div>
        <div class="form-label flex-center" :class="form.typeName ? '' : 'gray'">
          {{form.typeName || '请选择'}}
          <img :src="downUrl" alt="">
        </div>
      </div>

      <div class="form-item">
        <div class="form-label">{{ $t('tou-su-jian-yi-nei-rong') }}</div>
        <div class="input">
          <textarea v-model="form.content" :placeholder="$t('qing-tian-xie-tou-su-jian-yi-nei-rong')"></textarea>
        </div>
      </div>

      <div class="form-item flex-center-start">
        <div class="form-label">{{ $t('shang-chuan-ping-zheng') }}</div>
        <div class="file-box flex-column-center" v-if="!form.Logo" @click="chooseFile">  
          <Icon name="plus" size="20px"></Icon>
          <span class="mt-5">{{ $t('xuan-ze-tu-pian-0') }}</span>
        </div>
        <div class="file-preview" v-else>
          <img :src="`/upload/${form.Logo}`" alt="">
        </div>
        <input type="file" class="hide-box" id="fileInput" @change="changeFile" accept="image/*"> 
      </div>

      <Button>{{ $t('ti-jiao-0') }}</Button>
    </div>
    
    <Popup v-model="showPopup" position="bottom" class="bottom-popup">
      <Picker
        show-toolbar
        value-key="text"
        :columns="typeList"
        @confirm="chooseType"
        @cancel="cancel"
        :confirm-button-text="$t('common.ensure')"
        :cancel-button-text="$t('common.cancel')"
      ></Picker>
    </Popup>
  </div>
</template>
<script>
import { Button, Icon, Popup, Picker } from 'vant'
import topBar from '@/components/topBar'
import { uploadFile } from '@/api/user'
export default {
  components: {
    topBar,
    Button,
    Icon,
    Popup,
    Picker
  },
  data() {
    return {
      form: {
        type: '',
        typeName: '',
        content: '',
        Logo: ''
      },
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      tabIndex: 0,
      evaluteList: [],
      showPopup: false,
      typeList: [
        {
          value: '1',
          text: this.$t('jian-yi-0')
        },
        {
          value: '2',
          text: this.$t('tou-su-0')
        },
        {
          value: '3',
          text: this.$t('gu-li-0')
        },
        {
          value: '4',
          text: this.$t('ju-bao-zhu-bo-0')
        },
        {
          value: '5',
          text: this.$t('ju-bao-ke-fu-0')
        },
      ]
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    chooseFile() {
      document.getElementById('fileInput').value = ''
      document.getElementById('fileInput').click()
    },
     changeFile(e) {
      let file = e.target.files[0]
      let form = new FormData()
      form.append('file', file)
      uploadFile(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('shang-chuan-cheng-gong-0'))
          this.form.Logo = res.data.FileName
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    cancel() {
      this.showPopup = false
    },
    chooseType(e) {
      this.form.type = e.value
      this.form.typeName = e.text
      this.showPopup = false
    },
    changeType() {
      this.showPopup = true
    }
  }
}
</script>