<template>
	<div class="log-page ">
    <top-bar :title="$t('qian-dao-ji-lu')" :isWhite="true"  @back="back"></top-bar>

    <div class="list-main">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad">
        <div class="list " v-for="(item, i) in dataList" :key="i" > 
          <div class="line">
            <div class="label">{{$t('ri-change-qian-dao')}} +{{item.Amount}} {{$t('ji-fen')}}</div>
          </div>
          <div class="line">
            <div class="desc" >{{item.CreatedAt || '2023-12-12 12:33:44'}}</div>
          </div>
        </div>
      </List>

      <div class="empty flex-column-center" v-if="dataList.length == 0 && isInit">
        <img :src="emptyUrl" alt="">
        <span class="desc">{{$t('trade.empty')}}</span>
      </div>
    </div>
   
	</div>
</template>
<script>
import { signList } from '@/api/index'
import topBar from '@/components/topBar'
import { List } from 'vant'
export default {
  components: {
    topBar,
    List
  },
  data() {
		return {
      form: {
        bit: '',
        type: '',
        account: ''
      },
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
			emptyUrl: require('../../assets/imgs/empty.png'),
      dataList: [{}, {}, {}, {}],
    
		}
	},
  mounted() {
    this.init()
  },
	methods: {
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      signList(form).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        this.$store.state.showLoading = false
      })
    },
		back() {
			this.$router.go(-1)
		},
    
    toDetail(data) {
      // sessionStorage.setItem('logData', JSON.stringify(data))
      // this.$router.push({
      //   name: 'rechargeLogDetail'
      // })
    }
	}
}
</script>