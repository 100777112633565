<template>
  <div class="mine-page">
    <div class="top">
      <div class="flex-center-between">
        <div class="flex-center flex-1">
          <div class="header">
            <img :src="headerUrl" alt="">
          </div>
          <div class="flex-1 w-1">
            <div class="name">{{userName}}</div>
            <div class="tag">{{isSeller ? $t('shang-jia-1') : $t('pu-tong-yong-hu')}}</div>
          </div>
        </div>
        <div class="flex-center right-icon" >
          <img :src="msgUrl" alt="" @click="toPath('message')">
          <i class="iconfont icon-gear" @click="toPath('setting')"></i>
        </div>
      </div>
    </div>

    <div class="tabs flex-center">
      <div class="tab flex-center-between" @click="toPath('coupon')">
        <div>
          <div class="label">{{ $t('you-hui-quan') }}</div>
          <div class="desc">{{ $t('ling-quan') }}</div>
        </div>
        <div class="icon">
          <img :src="couponUrl" alt="">
        </div>
      </div>
      <div class="tab flex-center-between" @click="toPath('integral')">
        <div>
          <div class="label">{{ $t('ji-fen-shang-cheng') }}</div>
          <div class="desc">{{ $t('ji-fen-you-hui') }}</div>
        </div>
        <div class="icon">
          <img class="big" :src="integralUrl" alt="">
        </div>
      </div>
    </div>

    <div class="menu-box">
      <div class="flex-center-between title">
        <div class="label">{{ $t('wo-de-ding-dan') }}</div>
        <div class="desc" @click="toPath('orderList')">
          <span>{{ $t('cha-kan-quan-bu') }}</span>
          <i class="iconfont icon-right"></i>
        </div>
      </div>
      <div class="list flex-start">
        <div class="menu flex-column-center" v-for="(item, i) in menuList" :key="i" @click="toPath(item.path, item.query)">
          <img :src="item.img" alt="">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>

    <div class="menu-box" v-if="isSeller">
      <div class="list flex-center-around">
        <div class="menu flex-column-center" v-for="(item, i) in menuList2" :key="i" @click="toPath(item.path)">
          <img :src="item.img" alt="">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>

    <!-- <div class="share-box" @click="toShare"></div> -->

    <div class="menu-box">
      <div class="list flex-start flex-wrap wrap">
        <div class="menu flex-column-center" v-if="isSeller"  @click="toPath('sellerDashboard')">
          <img :src="shopUrl" alt="">
          <span>{{$t('shang-jia-guan-li')}}</span>
        </div>
        <div class="menu flex-column-center"  v-if="!isSeller && isInit" @click="toPath('openShop')">
          <img :src="openUrl" alt="">
          <span>{{$t('wo-yao-kai-dian')}}</span>
        </div>
        <div class="menu flex-column-center" v-for="(item, i) in menuList3" :key="i" @click="toPath(item.path)">
          <img :src="item.img" alt="">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>

    <div class="divider-box">
      <Divider :style="{borderColor: '#dcdfe6'}">
        <div class="flex-center ">
          <span class="label">{{ $t('cai-ni-xi-huan') }}</span>
          <span class="tag">{{ $t('jing-xuan-hao-huo') }}</span>
        </div>
      </Divider>
    </div>

    <div class="common-goods-list">
      <div class="list flex-wrap">
        <List
          v-if="goodsList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && goodsList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list flex-wrap">
            <div class="goods" v-for="(item, i) in goodsList" :key="i"  @click="toGoodsDetail(item)">
              <div class="img">
                <img :src="item.MainImage" alt="">
              </div>
              <div class="label">
                {{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}
              </div>
              <div class="price">${{item.SalePrice}}</div>
            </div>
          </div>
        </List>
      </div>
    </div>

    <Popup v-model="showPopup">
      <div class="share-img">
        <div class="img">
          <img :src="shareImg" alt="">
        </div>
        <div class="download-btn flex-column-center">
          <img :src="downloadUrl" alt="">
          <span>{{ $t('chang-an-hai-bao-bao-cun-tu-pian') }}</span>
        </div>
      </div>
    </Popup>

    <div class=" share-preview" ref="sharePreview">
      <div class="img">
        <img :src="shareUrl" alt="">
      </div>
      <div class="qrcode" ref="qrcode" id="qrcode">

      </div>
    </div>
  </div>
</template>
<script>
import { Divider, List, Popup  } from 'vant'
import { shopGoods } from '@/api/index'
import { userInfo } from '@/api/user'
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import {setUser, setUserId} from "@/utils/auth";
export default {
  components: {
    Divider,
    List,
    Popup
  },
  data() {
    return {
      showPopup: false,
      userName: '',
      shareImg: '',
      headerUrl: require('../../assets/imgs/logo.png'),
      msgUrl: require('../../assets/imgs/icon-message.png'),
      couponUrl: require('../../assets/imgs/icon-wallet2.png'),
      integralUrl: require('../../assets/imgs/img-scoreGIft.png'),
      downloadUrl: require('../../assets/imgs/download.png'),
      shareUrl: require('../../assets/imgs/share.png'),
      shopUrl: require('../../assets/imgs/icon-shop.png'),
      openUrl: require('../../assets/imgs/icon-openShop.png'),
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 100,
      },
      totalPage: 1,
      goodsList: [],
      shareLink: '',
      isInit: false
    }
  },
  computed: {
    isSeller() {
      return this.$store.state.isSeller
    },
    isInitUserInfo() {
      return this.$store.state.isInitUserInfo
    },
    promoteUrl() {
      return this.$store.state.promoteUrl
    },
    menuList() {
      return [
        {
          img: require('../../assets/imgs/icon-myWaitPay.png'),
          name: this.$t('chu-li-zhong'),
          path: 'orderList',
          query: {
            type: 0
          }
        },
        {
          img: require('../../assets/imgs/icon-myWaitDeliver.png'),
          name: this.$t('dai-fa-huo'),
          path: 'orderList',
          query: {
            type: 1
          }
        },
        {
          img: require('../../assets/imgs/icon-myTakeGoods.png'),
          name: this.$t('dai-shou-huo'),
          path: 'orderList',
          query: {
            type: 2
          }
        },
        {
          img: require('../../assets/imgs/icon-myWaitComment.png'),
          name: this.$t('yi-qian-shou'),
          path: 'orderList',
          query: {
            type: 3
          }
        },
        {
          img: require('../../assets/imgs/icon-myWaitReturn.png'),
          name: this.$t('shou-hou'),
          path: 'refund'
        },
      ]
    },
    menuList2() {
      return [
        {
          img: require('../../assets/imgs/icon-user.png'),
          name: this.$t('wo-de-tuan-dui'),
          path: 'myTeam'
        },
        {
          img: require('../../assets/imgs/icon-record.png'),
          name: this.$t('yong-jin-ming-xi'),
          path: 'commission'
        },
        {
          img: require('../../assets/imgs/icon-withdraw.png'),
          name: this.$t('ti-xian-ming-xi'),
          path: 'withdrawInfo'
        },
      ]
    },
    menuList3() {
      return [
        {
          img: require('../../assets/imgs/icon-wallet.png'),
          name: this.$t('wo-de-qian-bao'),
          path: 'wallet'
        },
        {
          img: require('../../assets/imgs/icon-address.png'),
          name: this.$t('shou-huo-di-zhi'),
          path: 'address'
        },
        {
          img: require('../../assets/imgs/icon-evalute.png'),
          name: this.$t('wo-de-ping-jia'),
          path: 'myEvalute'
        },
        {
          img: require('../../assets/imgs/icon-kefu.png'),
          name: this.$t('ke-fu'),
          path: 'onlineService'
        },
        {
          img: require('../../assets/imgs/icon-sign.png'),
          name: this.$t('qian-dao'),
          path: 'sign'
        },
        {
          img: require('../../assets/imgs/icon-complain.png'),
          name: this.$t('tou-su-jian-yi'),
          // 客服
          path: 'onlineService'
        },
        {
          img: require('../../assets/imgs/icon-help.png'),
          name: this.$t('gou-mai-bang-zhu'),
          path: 'help'
        },
        {
          img: require('../../assets/imgs/icon-about.png'),
          name: this.$t('guan-yu-wo-men'),
          path: 'about'
        },
        {
          img: require('../../assets/imgs/icon-about.png'),
          name: this.$t('lian-xi-wo'),
          path: 'contact'
        },
        {
          img: require('../../assets/imgs/icon-about.png'),
          name: this.$t('she-zhi'),
          path: 'setting'
        },
      ]
    }
  },
  mounted() {
    this.initGoods()
    this.init()
  },
  methods: {
    init() {
      userInfo().then(res => {
        let data = res.data
        this.$store.state.userInfo = res.data
        this.userName = data.Phone || data.Email
        setUser(this.userName)
        let isSeller = data.IsBusiness == 1
        this.isInit = true
        this.$store.state.isSeller = isSeller
        this.$store.state.isInitUserInfo = true
        this.$store.state.shareCode = data.InvitationCode

        this.shareLink = this.promoteUrl + data.InvitationCode
        this.initShare()
      })
    },
    toPath(path, query) {
      if (path == 'kefu') {
        this.$router.push({
          name: 'chat',
          query: {
            id: '12312123123'
          }
        })
      } else {
        if (path) {
          this.$router.push({
            name: path,
            query: query || {}
          })
        }
      }
    },
    onLoad() {
      this.page.page += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true

      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', 10)
      form.append('Guess', 2)
      shopGoods(form).then(res => {

        if (isAdd) {
          this.goodsList = this.goodsList.concat(res.data.Items)
        } else {
          this.goodsList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    toGoodsDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    },
    toShare() {
      // this.shareImg = this.shareUrl

      // 生成图片
      html2canvas(this.$refs.sharePreview).then(canvas => {
        let img = canvas.toDataURL("image/png")
        this.shareImg = img
        this.showPopup = true
      })

    },
    initShare() {
      this.$nextTick(() => {
        document.getElementById('qrcode').innerHTML = ''
        let qrcode = new QRCode(this.$refs.qrcode, {
          text: this.shareLink,
          width: 130,
          height: 130,
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
  }
}
</script>