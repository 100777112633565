<template>
  <div class="sign-page">
    <top-bar :title="$t('ji-fen-qian-dao')" class="red" @back="back"></top-bar>
    <div class="top flex-column-center">
      <Button  @click="submit">{{isSign ? $t('jin-ri-yi-qian-dao:') : $t('qian-dao')}}</Button>
      <div class="sign-count">{{ $t('yi-lian-xu-qian-dao-signcontinuous-tian-zong-qian-dao-signcontinuous-tian', [signContinuous, signContinuous]) }}</div>
    </div>
   

    <div class="calenday-box">
      <div class="title flex-center" > 
        <i class="iconfont icon-left" @click="preMonth"></i>
        <span class="count">{{monthStr}}</span>
        <i class="iconfont icon-right" @click="nextMonth"></i>
      </div>

      <Calendar
        v-if="maxDate"
        :poppable="false"
        :show-title="false"
        :show-subtitle="false"
        :show-confirm="false"
        :show-mark="false"
        :readonly="true"
        :default-date="null"
        color="#1989fa"
        :min-date="minDate"
        :max-date="maxDate"
        :row-height="48"
      >
        <template slot="top-info" slot-scope="day">
          <div class="day-point" :class="getPointClass(day)"></div>
        </template>
        <template slot="bottom-info" slot-scope="day">
          <div class="point-text" :class="getPointClass(day)">{{ $t('yi-da-ka') }}</div>
        </template>
      </Calendar>

      <div class="flex-center-end log" @click="toLog">{{ $t('qian-dao-ji-lu') }}</div>
    </div>

    <div class="bottom-step ">
      <div class="title">{{ $t('lian-xu-qian-dao-you-li') }}</div>
      <div class="desc">{{ $t('mei-ri-qian-dao-ling-ji-fen') }}</div>

      <div class="step-box">

        <div class="list flex">
          <div class="step flex-column-center" v-for="(item, i) in stepList" :key="i">
            <div class="title">
              {{ $t('ling-qu') }} <br/>
              {{item.count}}{{$t('ji-fen')}}
            </div>
            <div class="icon">
              <Icon name="checked" size="16" v-if="i <= active" color="#ff4c15"></Icon>
              <Icon name="circle" size="16" color="#ff4c15" v-else></Icon>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>
<script>
import { signList, userSign, signReceive, signReceiveList } from '@/api/index'
import { userInfo } from '@/api/user'
import { Calendar, Button, Step, Steps,Icon  } from 'vant';
import topBar from '@/components/topBar'
import moment from 'moment'
export default {
  components: {
    topBar,
    Button,
    Calendar,
    Steps,
    Step,
    Icon
  },
  data() {
    return {
      active: 2,
      signData: [
        {
          Day: '2023-12-28', 
        },
        {
          Day: '2023-12-21', 
        },
        {
          Day: '2023-12-29', 
        }
      ],
      isReceive: [],
      minDate: null,
      maxDate: null,
      monthStr: '',
      isSign: false,
      isReceive: false,
      signCount: '',
      signContinuous: '',
      showPicker: false,
      currentDate: new Date(),
      beginTime: null,
      endTime: null,
      isInit: false,
      okNum: 0,
      workMoney: 0,
      isDisabled: true,
      stepList: [
        {
          count: 5
        },
        {
          count: 10
        },
        {
          count: 15
        },
        {
          count: 20
        },
        {
          count: 25
        },
      ]
    }
  },
  computed: {
    signWorkMoney() {
      return this.$store.state.signWorkMoney
    }
  },
  mounted() {
    let startDay = moment().startOf('month').format('YYYY-MM-DD')
    let endDay = moment().endOf('month').format('YYYY-MM-DD')
    this.minDate = new Date(startDay)
    this.maxDate = new Date(endDay)
    this.init()

    this.monthStr = moment().format('YYYY / MM')
  },
  methods: {
    initLog() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      signList(form).then(res => {
        this.signData = res.data.Items
        let today = moment().format('YYYY-MM-DD')
        this.isSign = this.signData.findIndex(v => {
          return v.Day == today
        }) > -1
        this.okNum += 1
        this.checkPage()
      })

      signReceiveList(form).then(res => {
        this.signReceiveData = res.data.Items
        let today = moment().format('YYYY-MM-DD')
        this.isReceive = this.signReceiveData.findIndex(v => {
          return v.Day == today
        }) > -1
        this.okNum += 1
        this.checkPage()
      })
    },
    checkPage() {
      if (this.okNum == 4) {
        this.$store.state.showLoading = false
      }
    },
    init() {
      userInfo().then(res => {
        this.signContinuous = res.data.SignContinuous
        this.signCount = res.data.UserAccount.SignMoney ? parseFloat(res.data.UserAccount.SignMoney).toFixed(2) : '0.00'
        this.beginTime = new Date(res.data.CreatedAt)
        this.endTime = new Date()
        this.isInit = true
        this.okNum += 1
        this.checkPage()
      })
    },
    back() {
      this.$router.go(-1)
    },
    getPointClass(day) {
      let name = ''
      this.signData.forEach(v => {
        let time = new Date(moment(v.Day).format('YYYY-MM-DD 00:00:00')).getTime()
        if (day.date && time == day.date.getTime()) {
          name = 'red'
        }
      })
      return name
    },
    submit() {
      if (this.isSign || this.isDisabled) {
        return
      }
      userSign().then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.$t('sign.success')
          // this.initLog()
          this.init()
        } else {
          this.$store.state.errorMsg = res.msg
        }
      })
    },
    toLog() {
      this.$router.push({
        name: 'signLog'
      })
    },
    toWel() {
      signReceive().then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.$t('sign.label4')
          this.init()  
          // this.initLog()       
        } else {
          this.$store.state.errorMsg = res.msg
        }
      })
    },
    changeTime() {
      this.showPicker = true
    },
    cancel() {
      this.showPicker = false
    },
    confirmTime() {
      this.showPicker = false

      let newMonth = this.currentDate

      let startDay = moment(newMonth).startOf('month').format('YYYY-MM-DD')
      let endDay = moment(newMonth).endOf('month').format('YYYY-MM-DD')
      this.minDate = new Date(startDay)
      this.maxDate = new Date(endDay)
      // this.initLog()

      this.monthStr = moment(newMonth).format('YYYY.MM')
    },
    formatter(day) {
      return day
    },
    preMonth() {
      let day = moment(this.monthStr).subtract(1, 'months')
      let startDay = day.startOf('month').format('YYYY-MM-DD')
      let endDay =day.endOf('month').format('YYYY-MM-DD')
      this.minDate = new Date(startDay)
      this.maxDate = new Date(endDay)
      this.monthStr = day.format('YYYY / MM')
    },
    nextMonth() {
      let day = moment(this.monthStr).add(1, 'months')
      let startDay = day.startOf('month').format('YYYY-MM-DD')
      let endDay =day.endOf('month').format('YYYY-MM-DD')
      this.minDate = new Date(startDay)
      this.maxDate = new Date(endDay)
      this.monthStr = day.format('YYYY / MM')
    }
  }
}
</script>