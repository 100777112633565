<template>
  <div class="kefu-page list">
    <iframe class="kefu-iframe" v-if="kefuUrl !== undefined" :src="kefuUrl"></iframe>
  </div>
</template>
<script>
import { Button } from 'vant'
import { getKefuList } from '@/api/user'
import {getUser, getUserId} from "@/utils/auth";
import Vue from 'vue'; // 引入 Vue
export default {
  components: {
    Button,
  },
  data() {
    return {
      kefuList: [],
      // kefuUrl: '/#/kf2'
      kefuUrl: undefined,
      LiveChat: false,
      username: "",
      session: {},
      token: "",
      uid: "",
      maximized: "maximized",
      license: ""
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    runMeiqiaScript() {
      const script = document.createElement('script');
      script.async = true;
      script.charset = 'UTF-8';
      script.src = 'https://static.meiqia.com/dist/meiqia.js';
      document.head.appendChild(script);
      window._MEIQIA = window._MEIQIA || function() {
        (window._MEIQIA.a = window._MEIQIA.a || []).push(arguments);
      };
    },

    init() {
      getKefuList().then(res => {
        this.kefuList = res.data.Items
        let that = this
        if (this.kefuList.length > 0) {
          // 检查是否是livechatinc
          if(this.kefuList[0].Link.indexOf('lc.chat') > -1) {
            that.$router.push({
              name: 'kf2'
            })
            return
          }
          if(this.kefuList[0].Type == 'other') {
            // let a = document.createElement('a')
            // a.href = this.kefuList[0].Link
            // a.target = '_blank'
            // document.body.appendChild(a)
            // a.click()
            // document.body.removeChild(a)
            this.kefuUrl = this.kefuList[0].Link
            return
          }
          if(this.kefuList[0].Link.indexOf('chatlink.html') > -1) {
            let link = this.kefuList[0].Link + '&remark=' + this.kefuList[0].Remark + '&ID=' + getUserId() + '&name=' + getUser() + '&username=' + getUser()
            this.kefuUrl = link
            // this.$router.back()
            return
          }
          // tawk
          if(this.kefuList[0].Link.indexOf('tawk.to') > -1) {
            // 等待tawkLoaded加载完成
            let tmp = setInterval(function (){
              console.log("setInterval")
              if (that.$store.state.tawkLoaded !== undefined && that.$tawkMessenger !== undefined) {
                try {
                  let user = getUser()
                  console.log("user",typeof user)
                  console.log("user",user !== undefined)
                  if(user !== undefined) {
                    that.$tawkMessenger.setAttributes({
                      name : user,
                      Email : user,
                      remark:that.kefuList[0].Remark,
                    });
                  }
                  that.$tawkMessenger.maximize();
                  clearInterval(tmp)
                  that.$tawkMessenger.$on('chatMinimized', () => {
                    that.$router.back()
                  });

                }catch (e) {
                  console.log(e)
                }
              }
            },50)
            // this.$router.back()
            return
          }
          if(this.kefuList[0].Token.length > 0) {
            this.kefuUrl = '/#/kf2'
          } else {
            this.runMeiqiaScript()
            // this.kefuUrl = this.kefuList[0].Link
            // this.kefuUrl = this.kefuList[0].Link + '&metadata={"ID":"' +getUserId()+ '","name":"'+ getUser()+ '","username":"'+ getUser()+'","comment":"'+this.kefuList[0].Remark+'"}'
            // window.location.href = this.kefuUrl
            // 使用正则表达式匹配并提取eid参数的值
            const match = this.kefuList[0].Link.match(/eid=([^&]+)/);
            const eidValue = match ? match[1] : null;
            window._MEIQIA('entId', eidValue);
            window._MEIQIA('withoutBtn');
            window._MEIQIA('showPanel');
            window._MEIQIA('language','en')
            // 传递顾客信息
            _MEIQIA('metadata', {
              ID: getUserId(), // 美洽默认字段
              name: getUser(), // 美洽默认字段
              username: getUser(), // 美洽默认字段
              comment: this.kefuList[0].Remark, // 美洽默认字段
            });
            //点击关闭按钮后的回调
            _MEIQIA('beforeCloseWindow', function() {
              // alert('点击了关闭按钮');
              // 返回
              that.$router.push({
                name: 'index'
              })
            });
          }
        }
      })
    },
    to(Type,Link){
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {
        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
          case "line":
            Link = "https://line.me/R/"+ Link
            break;
        }
        this.toPath(Link)
      }
    },
    toPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    chatwoot(Link){
      let that = this;
      if (Link.length === 0) {
        return;
      }
      let len = Link.indexOf("/widget");
      if (len === -1) {
        return;
      }
      var BASE_URL=Link.substr(0,len);
      let tokenlen = Link.indexOf("token=");
      let token = Link.substr(tokenlen+6);
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: BASE_URL
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },50);
    },

  }
}
</script>
<style scoped>
.kefu-page {
  height: 100vh;
  .kefu-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
