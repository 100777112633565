import fetch from "../utils/fetch";

// 店铺信息
export function shopInfo(data) {
  return fetch({
    url: '/api/user/shop-info',
    method: 'Post',
    data: data
  });
}
// 店铺信息设置
export function shopSetting(data) {
  return fetch({
    url: '/api/user/shop-setting',
    method: 'Post',
    data: data
  });
}
// 等级列表
export function shopLevelList(data) {
  return fetch({
    url: '/api/user/shop-level-list',
    method: 'Post',
    data: data
  });
}
// 店铺升级
export function shopUpgrade(data) {
  return fetch({
    url: '/api/user/shop-upgrade',
    method: 'Post',
    data: data
  });
}
// 店铺升级记录
export function shopUpgradeLog(data) {
  return fetch({
    url: '/api/user/shop-level-record-list',
    method: 'Post',
    data: data
  });
}
// 直通车列表
export function shopTrainList(data) {
  return fetch({
    url: '/api/user/shop-train-list',
    method: 'Post',
    data: data
  });
}
// 直通车升级
export function shopTrainUpgrade(data) {
  return fetch({
    url: '/api/user/shop-train-upgrade',
    method: 'Post',
    data: data
  });
}
// 直通车升级记录
export function shopTrainUpgradeLog(data) {
  return fetch({
    url: '/api/user/shop-train-upgrade-list',
    method: 'Post',
    data: data
  });
}
// 订单列表
export function shopOrderList(data) {
  return fetch({
    url: '/api/user/business-order-lists',
    method: 'Post',
    data: data
  });
}

// CronList 列表
export function cronList(data) {
  return fetch({
    url: '/api/user/cron-list',
    method: 'Post',
    data: data
  });
}
// 产品列表
export function productList(data) {
  return fetch({
    url: '/api/user/product-list',
    method: 'Post',
    data: data
  });
}
// 产品添加
export function addProduct(data) {
  return fetch({
    url: '/api/user/business-product-add',
    method: 'Post',
    data: data
  });
}
// 产品修改
export function editProduct(data) {
  return fetch({
    url: '/api/user/business-product-edit',
    method: 'Post',
    data: data
  });
}
// 提货付款
export function orderPay(data) {
  return fetch({
    url: '/api/user/pickup-pay',
    method: 'Post',
    data: data
  });
}
// 首页信息
export function indexInfo(data) {
  return fetch({
    url: '/api/user/index-info',
    method: 'Post',
    data: data
  });
}
// 商家身份认证
export function userVerify(data) {
  return fetch({
    url: '/api/user/identity-verification',
    method: 'Post',
    data: data
  });
}
// 产品删除
export function removeProduct(data) {
  return fetch({
    url: '/api/user/business-product-delete',
    method: 'Post',
    data: data
  });
}
// 未读数
export function unReadCount(data) {
  return fetch({
    url: '/api/common/dialogue-unread',
    method: 'Post',
    data: data
  });
}
// 消息已读
export function messageRead(data) {
  return fetch({
    url: '/api/common/dialogue-read',
    method: 'Post',
    data: data
  });
}
// 营业额
export function turnover(data) {
  return fetch({
    url: '/api/user/turnover',
    method: 'Post',
    data: data
  });
}

// 商品列表
export function getGoodsList(data) {
  return fetch({
    url: '/api/user/product-list',
    method: 'post',
    data: data
  });
}
// 批量修改价格
export function batchEditPrice(data) {
  return fetch({
    url: '/api/user/batch-edit-price',
    method: 'post',
    data: data
  });
}
// 分销统计
export function sellerStatistics(data) {
  return fetch({
    url: '/api/user/subordinate-seller-statistics',
    method: 'post',
    data: data
  });
}