<template>
  <div class="search-page">
    <div class="top flex-center">
      <Icon name="arrow-left" size="20" @click="back"></Icon>
      <div class="flex-1 flex-center-start box" :class="pageType == 2 ? 'small' : ''">
        <div class="type flex-center" v-if="pageType == 1">
          <Popover v-model="showPopover" trigger="click" :actions="typeList" >
            <div class="popover-type-list">
              <div class="flex-center list" :class="item.value == form.searchType ? 'active' : ''" v-for="(item, i) in typeList" :key="i" @click="changeType(item)"> 
                {{item.text}}
                <Icon name="success" v-if="item.value == form.searchType"></Icon>
              </div>
            </div>
            <template #reference>
              <div class="flex-center">
                <span>{{form.typeName}}</span>
                <Icon name="arrow-up" size="16" :class="showPopover ? 'show' : ''"></Icon>
              </div>
            </template>
          </Popover>
        </div>
        <div class="input">
          <Search  v-model="form.keyword" :placeholder="$t('sou-suo-shang-pin')" @search="submit"/>
        </div>
      </div>
    </div>

    <div class="tabs flex-center" v-if="showType == 1">
      <div class="tab flex-center" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{ $t('zong-he') }}</div>
      <div class="tab  flex-center" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{ $t('xiao-liang-0') }}</div>
      <div class="tab flex-center " :class="[tabIndex == 3 ? 'active' : '', priceType ? `type-${priceType}` : '']" @click="changeTab(3)">
        <div class="price">{{ $t('jia-ge') }}</div>
      </div>
    </div>

    <div class="common-goods-list">
      <div class="list flex-wrap" v-if="showType == 1">
        <List
          v-if="goodsList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && goodsList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list flex-wrap">
            <div class="goods" v-for="(item, i) in goodsList" :key="i"  @click="toGoodsDetail(item)">
              <div class="img">
                <img :src="item.MainImage" alt="">
              </div>
              <div class="label">
                {{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}
              </div>
              <div class="price">{{item.SalePrice}}</div>
            </div>
          </div>
        </List>
      </div>
      <div class="" v-if="showType == 2">
        <div class="shop-list">
          <div class="shop" >
            <div class="flex-center-between title">
              <div class="header">
                <img :src="shopData.Logo" alt="">
              </div>
              <div class="flex-1">
                <div class="name">{{shopData.Name}}</div>
                <div class="flex">
                  <div class="desc">{{shopData.User ? (shopData.User.Phone || shopData.User.Email) : ''}}</div>
                  <div class="time">{{shopData.CreatedAt.substring(5,10)}}</div>
                </div>
              </div>
              <div>
                <Button class="btn-style" @click="toShop()">{{ $t('jin-dian-0') }}</Button>
              </div>
            </div>
            <div class="goods-title flex-center-start">
              <span class="new">{{ $t('xin-pin') }}</span>
            </div>
            <div class="goods-list flex-wrap">
              <div class="goods" v-for="(good, index) in shopData.goodsList" :key="index" @click="toGoodsDetail(good)">
                <img :src="good.MainImage" alt="">
              </div>
            </div>
            <div class="bottom-desc flex-center-between">
              <div class="flex-center desc">
                {{$t('yue-du')}} {{shopData.TotalVisit || 0}}
              </div>
              <div class="flex-center star">
                <i class="iconfont icon-hand-up"></i>
                <span>{{$t('dian-zan')}} {{shopData.Good || 0}}</span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import { Icon, Popover , Search, Popup, Button, List } from 'vant'
import { shopGoods } from '@/api/index'
import { shopInfo } from '@/api/seller'
export default {
  components: {
    Icon,
    Popover,
    Search,
    Popup,
    Button,
    List
  },
  data() {
    return {
      tabIndex: 1,
      showPopover: false,
      showPopup: false,
      pageType: 1,
      categoryId: '',
      priceType: '',
      form: {
        searchType: '1',
        keyword: '',
        typeName: this.$t('shang-pin'),
        sort: ''
      },
      typeList: [
        {
          text: this.$t('shang-pin'),
          value: '1'
        },
        {
          text: this.$t('dian-pu'),
          value: '2'
        },
      ],
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 10,
      },
      totalPage: 1,
      goodsList: [],
      shopData: {
        goodsList: []
      },
      showType: '1'
    }
  },
  mounted() {
    this.pageType = this.$route.query.type
    this.categoryId = this.$route.query.categoryId
    this.form.keyword = this.$route.query.keyword
    this.initGoods()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeType(data) {
      this.form.typeName = data.text
      this.form.searchType = data.value
      this.showPopover = false
    },
    submit() {
      this.page.page = 1
      if (this.searchType == 1) {
        this.initGoods()
      } else {
        this.initShop()
      }
    },
    removeHistory() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    toRemove() {
      this.showPopup = false
    },
    changeTab(i) {
      if (i == 3) {
        if (this.tabIndex == 3 && this.priceType == 1) {
          this.priceType = 2
        } else {
          this.priceType = 1
        }
      } else {
        this.priceType = ''
      }
      this.tabIndex = i
      this.page.page = 1
      this.form.sort = i == 1 ? '' : (i == 2 ? 'sales' : (this.priceType == 1 ? 'MaxPrice' : 'MinPrice'))
      this.initGoods()
    },
    onLoad() {
      this.page.page += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true
      this.showType = '1'
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('CategoryID', this.categoryId || '')
      form.append('Title', this.form.keyword || '')
      form.append('Sort', this.form.sort)
      shopGoods(form).then(res => {

        if (isAdd) {
          this.goodsList = this.goodsList.concat(res.data.Items)
        } else {
          this.goodsList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    initShop(isAdd) {
      this.loading = true
    
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('Title', this.form.keyword || '')
      shopInfo(form).then(res => {
        this.shopData = res.data
        this.getGoods(this.shopData.ID)
        this.showType = '2'
      })
    },
    getGoods(shopId) {
      let form = new FormData()
      form.append('pageSize', 6)
      form.append('ShopID', shopId)
      shopGoods(form).then(res => {
        let list = res.data.Items
        this.$set(this.shopData, 'goodsList', list)
      })
    },
    toGoodsDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    },
    toShop() {
      sessionStorage.setItem('shopDetailData', JSON.stringify(this.shopData))
      this.$router.push({
        name: 'shop',
        query: {
          id: this.shopData.ID,
        }
      })
    }
  }
}
</script>