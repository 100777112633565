<template>
  <div class="my-evalute-page">
    <top-bar :title="$t('wo-de-ping-jia')" :isWhite="true" @back="back"></top-bar>


    <PullRefresh v-model="isLoading" @refresh="onRefresh">
      <div class="evalute-list">
        <List
          v-if="evaluteList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="isInit && evaluteList.length == 0 ? '' : $t('common.noMore')"
          @load="onLoad">
          <div class="list" v-for="(item, i) in evaluteList" :key="i">
            <div class="title flex-center-between">
              <div>
                {{ $t('ding-dan-bian-hao') }}: <span>{{item.OrderNo}}</span>
              </div>
              <!-- <div class="status">
                <Icon @click="showConfirm" v-if="item.status == 1" name="delete-o" color="#999" size="16"></Icon>
                <span v-else>{{ $t('shen-he-tong-guo') }}</span>
              </div> -->
            </div>
            <div class="goods flex-start">
              <div class="img">
                <img :src="item.BusinessProduct.MainImage" alt="">
              </div>
              <div class="goods-name">{{item.BusinessProduct.ProductTranslation && item.BusinessProduct.ProductTranslation[0] ? item.BusinessProduct.ProductTranslation[0].Name : ''}}</div>
            </div>

            <div class="evalute-line flex-center-start">
              {{ $t('shang-pin-ping-fen') }}: <Icon name="star" size="19" color="#ffca3e" v-for="i in item.ProductScore" :key="i"></Icon>
            </div>
            <div class="evalute-line flex-center-start">
              {{ $t('wu-liu-ping-fen-0') }} <Icon name="star" size="19" color="#ffca3e" v-for="i in item.LogisticsScore" :key="i"></Icon>
            </div>
            <div class="evalute-line flex-center-start">
              {{ $t('fu-wu-ping-fen-0') }} <Icon name="star" size="19" color="#ffca3e" v-for="i in item.ServiceScore" :key="i"></Icon>
            </div>
            <div class="content">
              {{item.Content}}
            </div>
          </div>
        </List>
      </div>
    </PullRefresh>

    <Popup v-model="showPopup" class="msg-popup">
      <div class="msg-modal">
        <div class="title">{{ $t('ti-shi-1') }}</div>
        <div class="msg">{{ $t('que-ren-shan-chu-gai-ping-jia') }}</div>
        <div class="btn-style">
          <Button @click="cancel">{{ $t('qu-xiao-1') }}</Button>
          <Button class="blue">{{ $t('que-ding-1') }}</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import { myEvalute } from '@/api/shop'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      tabIndex: 0,
      evaluteList: [],
      showPopup: false,
      page: {
        page: 1,
        limit: 10,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      evaluteListBak: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      myEvalute(form).then(res => {
        if (isAdd) {
          this.evaluteList = this.evaluteList.concat(res.data.Items)
        } else {
          this.evaluteList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    onRefresh() {
      this.page.page = 1
      this.init()
    },
    toDetail(data) {
      this.$router.push({
        name: 'refundDetail'
      })
    },
    showConfirm() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>