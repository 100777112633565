<template>
  <div class=" help-page">
    <top-bar title="消息详情" :isWhite="true" @back="back"></top-bar>
    
    <div class="content" v-html="Content">
      
    </div>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import topBar from '@/components/topBar'
export default {
  components: {
    Button,
    topBar,
    Icon
  },
  data() {
    return {
      Content: '<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: 宋体;">一、如何交易</span></strong></span><br></p><p style="text-align:left"><span style=";font-family:宋体;font-size:14px">&nbsp;</span></p><p style="text-align:left"><span style=";font-family:宋体;font-size:14px">1<span style="font-family:宋体">、直播时按照主播要求表达购买意向</span></span></p><p style="text-align:left"><span style=";font-family:宋体;font-size:14px">2<span style="font-family:宋体">、点击直播页面的左下角购物车图标</span></span></p>'
    }
  },
 
  mounted() {
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
   
  }
}
</script>