<template>
  <div class="feedback-page goods">
    <top-bar :title="$t('shang-pin-ping-jia')" :isWhite="true" @back="back"></top-bar>
    <div class="goods-info flex-center-start" v-for="(good, i) in detailData.OrderProduct" :key="i">
      <div class="header">
        <img :src="good.BusinessProduct.MainImage" alt="">
      </div>
      <div class="flex-1 goods-name">
       {{good.BusinessProduct.ProductTranslation && good.BusinessProduct.ProductTranslation[0] ? good.BusinessProduct.ProductTranslation[0].Name : ''}}
      </div>
    </div>
    <div class="feedback-box">

      <div class="form-item">
        <div class="input">
          <Field
            v-model="form.content"
            rows="5"
            autosize
            label=""
            type="textarea"
            maxlength="500"
            :placeholder="$t('xie-dian-xiang-yao-shuo-de-hua-ba')"
            show-word-limit
          />
        </div>
      </div>

      <div class="form-item flex-center-start">
        <div class="file-box clear flex-column-center" v-if="!form.Logo" @click="chooseFile">  
          <Icon name="plus" size="20px"></Icon>
          <span class="mt-5">选择图片</span>
        </div>
        <div class="file-preview" v-else>
          <img :src="`/upload/tmp/${form.Logo}`" alt="">
        </div>
        <input type="file" class="hide-box" id="fileInput" @change="changeFile" accept="image/*"> 
      </div>

     
    </div>
    
    <div class="evalute-form-box">
      <div class="line flex-center-start">
        <div class="label">{{ $t('shang-pin-ping-fen') }}</div>
        <div class="flex-center">
          <Icon name="star" size="20" color="#ffca3e" v-for="i in goodsScore" :key="i" @click="changeStar(i, 1)"/>
          <Icon name="star" size="20" color="#ececec" v-for="i in 5" :key="i" v-if="i > goodsScore" @click="changeStar(i, 1)"/>
        </div>
      </div>
      <div class="line flex-center-start">
        <div class="label">物流评分</div>
        <div class="flex-center">
          <Icon name="star" size="20" color="#ffca3e" v-for="i in shippingScore" :key="i" @click="changeStar(i, 2)"/>
          <Icon name="star" size="20" color="#ececec" v-for="i in 5" :key="i" v-if="i > shippingScore" @click="changeStar(i, 2)"/>
        </div>
      </div>
      <div class="line flex-center-start">
        <div class="label">服务评分</div>
        <div class="flex-center">
          <Icon name="star" size="20" color="#ffca3e" v-for="i in serviceScore" :key="i" @click="changeStar(i, 3)"/>
          <Icon name="star" size="20" color="#ececec" v-for="i in 5" :key="i" v-if="i > serviceScore" @click="changeStar(i, 3)"/>
        </div>
      </div>

       <Button @click="submit">{{ $t('ti-jiao') }}</Button>
    </div>
  </div>
</template>
<script>
import { Button, Icon, Popup, Picker, Field } from 'vant'
import topBar from '@/components/topBar'
import { uploadFile } from '@/api/user'
import { addEvalute, addGoodsEvalute } from '@/api/shop'
export default {
  components: {
    topBar,
    Button,
    Icon,
    Popup,
    Picker,
    Field
  },
  data() {
    return {
      form: {
        type: '',
        typeName: '',
        content: '',
        Logo: ''
      },
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      logoUrl: require('../../assets/imgs/logo.png'),
      tabIndex: 0,
      evaluteList: [],
      showPopup: false,
      goodsScore: 0,
      shippingScore: 0,
      serviceScore: 0,
      dataId: '',
      detailData: {
        OrderProduct: []
      },
      typeList: [
        {
          value: '1',
          text: this.$t('jian-yi')
        },
        {
          value: '2',
          text: this.$t('tou-su')
        },
        {
          value: '3',
          text: this.$t('gu-li')
        },
        {
          value: '4',
          text: this.$t('ju-bao-zhu-bo')
        },
        {
          value: '5',
          text: this.$t('ju-bao-ke-fu')
        },
      ]
    }
  },
  computed: {
  },
  mounted() {
    let data = sessionStorage.getItem('evaluteData')
    if (data) {
      this.detailData = JSON.parse(data)
    }

    this.dataId = this.$route.query.id
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    chooseFile() {
      document.getElementById('fileInput').value = ''
      document.getElementById('fileInput').click()
    },
     changeFile(e) {
      let file = e.target.files[0]
      let form = new FormData()
      form.append('file', file)
      uploadFile(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('shang-chuan-cheng-gong'))
          this.form.Logo = res.data.FileName
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    cancel() {
      this.showPopup = false
    },
    chooseType(e) {
      this.form.type = e.value
      this.form.typeName = e.text
      this.showPopup = false
    },
    changeType() {
      this.showPopup = true
    },
    changeStar(val, type) {
      if (type == 1) {
        this.goodsScore = val
      } else if (type == 2) {
        this.shippingScore = val
      } else if (type == 3) {
        this.serviceScore = val
      }
    },
    submit() {
      if (!this.form.content) {
        this.$toast.fail(this.$t('qing-shu-ru-ping-jia-nei-rong'))
        return
      }
      if (!this.goodsScore) {
        this.$toast.fail(this.$t('qing-xuan-ze-ping-fen'))
        return
      }
      let form = new FormData()
      form.append('OrderId', this.detailData.ID)
      form.append('ProductScore', this.goodsScore)
      form.append('ServiceScore', this.serviceScore)
      form.append('LogisticsScore', this.shippingScore)
      form.append('Content', this.form.content)
      form.append('Image', this.form.Logo)
      addEvalute(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('ping-jia-fa-bu-cheng-gong'))
          setTimeout(() => {
            this.back()
          }, 1000)
        } else {
          this.$toast.fail(res.msg)
        }
      })

      let form2 = new FormData()
      form2.append('ProductID', this.dataId)
      form2.append('Score', this.goodsScore)
      form2.append('Content', this.form.content)
      addGoodsEvalute(form2)
    }
  }
}
</script>