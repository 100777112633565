<template>
  <div class="integral-page">
    <top-bar :title="$t('ji-fen-zhong-xin')" :isWhite="hideTitle" :class="hideTitle ? '' : 'clear'" @back="back"></top-bar>
    <div class="top flex-center-between">
    <div class="header">
      <img :src="logoUrl" alt="">
    </div>
    <div class="flex-1">
      <div class="count">{{userIntegral}}</div>
      <div class="label">{{ $t('dang-qian-lei-ji-ji-fen') }}</div>
    </div>
    <div class="log flex-center">
      <img :src="logUrl" alt="">
      <span>{{ $t('ji-lu') }}</span>
    </div>
    </div>

    <!-- <div class="integral-list " :class="[showMore ? '' : 'hide', integralList.length < 3 ? 'small' : '']">
      <div class="list-title">{{ $t('ji-fen-ren-wu') }}</div>
      <div class="list flex-center-between" v-for="(item, i) in integralList" :key="i">
        <div class="">
          <div class="flex-center ">
            <span class="label">{{item.name}}</span>
            <span class="tag">{{item.type == 1 ? '新手任务' : '成长任务'}}</span>
          </div>
          <div class="flex-center-start count">
            <img :src="logUrl" alt="">
            {{item.count}}{{$t('ji-fen')}}
          </div>
        </div>
        <div class="btn-style">
          <Button :class="`btn-style-${item.status}`">{{item.status == 1 ? '做任务' : '已完成'}}</Button>
        </div>
      </div>

      <div class="hide-modal flex-end-center" v-if="!showMore && integralList.length >= 4">
        <div class="desc flex-center" @click="showAll">
          <span>{{ $t('cha-kan-geng-duo-1') }}</span>
          <i class="iconfont icon-down"></i>
        </div>
      </div>
    </div> -->

    <div class="goods-list">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
        >
        <div class="flex-wrap">
          <div class="list " v-for="(item, i) in dataList" :key="i" @click="toGoodsDetail(item)"> 
            <div class="bg">
              <img :src="item.MainImage" alt="">
            </div>
            <div class="flex-1">
              <div class="goods-title">{{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}</div>
              <div class="price">
                <span>{{item.SalePrice}}</span>
                <span class="small">{{item.MarketPrice}}</span>
              </div>
              <!-- <div class="tag">积分换取</div>
              <div class="flex-center-between">
                <div class="time">3件已换取</div>
              </div> -->
            </div>
          </div>
        </div>
      </List>
      <div class="empty flex-column-center" v-if="dataList.length == 0 && isInit">
        <img :src="emptyUrl" alt="">
        <span class="desc">{{ $t('zan-wu-shu-ju-0') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { List } from 'vant'
import {  shopGoods } from '@/api/index'
import {  wallet } from '@/api/user'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    List
  },
  data() {
    return {
      dataList: [],
      page: {
        page: 1,
        limit: 20
      },
      userIntegral: 0,
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
			emptyUrl: require('../../assets/imgs/empty.png'),
			goodsUrl: require('../../assets/imgs/goods1.jpg'),
			logoUrl: require('../../assets/imgs/header1.png'),
			logUrl: require('../../assets/imgs/icon-score2.png'),
      hideTitle: false,
      showMore: false,
      integralList: [
        {
          name: this.$t('mei-ri-qian-dao-0'),
          count: 5,
          type: 1,
          status: 1
        },
        {
          name: this.$t('yao-qing-zhu-ce'),
          count: 10,
          type: 1,
          status: 1
        },
      ]
    }
  },
  mounted() {
    this.init()
    this.initGoods()
    let dom = document.querySelector('.integral-page')
    dom.addEventListener('scroll', (e) => {
      let top = dom.scrollTop
      if (top > 110) {
        this.hideTitle = true 
      } else {
        this.hideTitle = false 
      }
    })
  },
  methods: {
    init() {

      wallet().then(res => {
        let data = res.data.Items[0]
        if (data) {
          this.userIntegral = parseFloat(data.Integral.toFixed(2))
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.page += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true
     
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      form.append('Guess', 1)
      shopGoods(form).then(res => {

        if (isAdd) {
          this.dataList = this.dataList.concat(res.data.Items)
        } else {
          this.dataList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    toGoodsDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    },
    showAll() {
      this.showMore = true
    }
  }
}
</script>