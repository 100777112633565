<template>
  <div class="shop-page">
    <top-bar :title="$t('dian-pu-shou-ye')" :isWhite="true" @back="back"></top-bar>
    <div class="top">
      <div class="top-info">
        <div class="flex-center">
          <div class="header">
            <img :src="detailData.Logo" alt="">
          </div>
          <div class="flex-1">
            <div class="title">{{detailData.Name}}</div>
            <div class="flex-center-start">
              <div class="tag">{{ $t('pin-pai-zhi-ying') }}</div>

            </div>
            <div class="label">
              {{$t('xin-yu-fen')}}:{{parseInt(detailData.Credit,10)}}
              {{$t('cheng-jiao-liang')}}:{{detailData.TotalDeal}}
            </div>
            <div class="label" v-show="false">
              {{$t('hao-ping')}}:{{detailData.Good}} {{$t('liu-lan-liang')}}:{{detailData.TotalVisit}}
            </div>
          </div>
          <div class="flex-column-center" @click="toFollow">
            <Icon name="like-o" size="30" color="#f90" v-if="!isCollect"/>
            <Icon name="like" size="30" color="#f90" v-else/>
            <span class="desc">{{isCollect ? $t('yi-guan-zhu') : $t('guan-zhu')}}</span>
          </div>
        </div>

        <!-- <div class="coupon-box">
          <div class="box-outer">
            <div class="box-inner" :style="getBoxWidth(couponList.length)">
              <div class="list" v-for="(item, i) in couponList" :key="i">
                <div class="">10 满200可用</div>
                <div class="">01-01至03-31可用</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="banner">
      <img :src="detailData.Banner || shopUrl" alt="">
    </div>

    <div class="tabs flex-center-around">
      <div class="tab flex3 flex-center" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{ $t('zong-he-pai-xu') }}</div>
      <div class="tab flex3  flex-center" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{ $t('xiao-liang-you-xian') }}</div>
      <div class="tab flex2 flex-center " :class="[tabIndex == 3 ? 'active' : '', priceType ? `type-${priceType}` : '']" @click="changeTab(3)">
        <div class="price">{{ $t('jia-ge') }}</div>
      </div>
      <div class="tab flex2  flex-center" :class="tabIndex == 4 ? 'active' : ''" @click="changeTab(4)">{{ $t('xin-pin') }}</div>
      <!-- <div class="tab flex2  flex-center" :class="tabIndex == 5 ? 'active' : ''" @click="changeTab(5)">分类</div> -->
    </div>

    <div class="common-goods-list">
      <List
        v-if="goodsList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="isInit && goodsList.length == 0 ? '' : $t('common.noMore')"
        @load="onLoad">
        <div class="list flex-wrap">
          <div class="goods" v-for="(item, i) in goodsList" :key="i"  @click="toGoodsDetail(item)">
            <div class="img">
              <img :src="item.MainImage" alt="">
            </div>
            <div class="label">
              {{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}
            </div>
            <div class="price">${{item.SalePrice}}</div>
          </div>
        </div>
      </List>
    </div>
  </div>
</template>
<script>
import { Icon, List } from 'vant'
import topBar from '@/components/topBar'
import {followShop, getFollowList, getShopList} from '@/api/shop'
import { shopGoods } from '@/api/index'
import {userInfo, wallet} from "@/api/user";
import {setUser} from "@/utils/auth";
export default {
  components: {
    topBar,
    Icon,
    List
  },
  data() {
    return {
      dataId:  '',
      logoUrl: require('../../assets/imgs/logo.png'),
      shopUrl: require('../../assets/imgs/shop-bg.jpeg'),
      isCollect: false,
      couponList: [{}, {}, {},{}],
      priceType: '',
      tabIndex: 1,
      userIntegral: 0,
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 100,
      },
      totalPage: 1,
      goodsList: [],
      form: {
        searchType: '1',
        keyword: '',
        typeName: this.$t('shang-pin'),
        sort: ''
      },
      detailData: {

      }
    }
  },
  computed: {
    screenScale() {
      return this.$store.state.screenScale || 1
    },
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.initGoods()
    this.initFollow()
    this.getShop()

    let data = sessionStorage.getItem('shopDetailData')
    if (data) {
      this.detailData = JSON.parse(data)
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    getShop(){
      getShopList({ID:parseInt(this.dataId,10)}).then(res => {
        this.detailData = res.data.Items[0]
      })
    },
    initFollow() {
      let form = new FormData()
      form.append('ShopID', this.dataId)
      getFollowList(form).then(res => {
        this.isCollect = res.data.Items.length > 0
      })
    },
    getBoxWidth(len) {
      return `width: ${len * 145 * this.screenScale}px;`
    },
    changeTab(i) {
      if (i == 3) {
        if (this.tabIndex == 3 && this.priceType == 1) {
          this.priceType = 2
        } else {
          this.priceType = 1
        }
      } else {
        this.priceType = ''
      }
      this.tabIndex = i
      this.page.page = 1
      this.form.sort = i == 1 ? '' : (i == 2 ? 'sales' : (this.priceType == 1 ? 'MaxPrice' : 'MinPrice'))
      this.initGoods()
    },
    onLoad() {
      this.page.page += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true

      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', 10)
      form.append('ShopID', this.dataId)
      form.append('Sort', this.form.sort)
      shopGoods(form).then(res => {
        if (isAdd) {
          this.goodsList = this.goodsList.concat(res.data.Items)
        } else {
          this.goodsList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
      wallet().then(res => {
        let data = res.data.Items[0]
        if (data) {
          // this.userMoney = data.Balance.toFixed(2)
          // this.userIntegral = data.Integral.toFixed(2)
        }
      })
    },
    toFollow() {
      if (!this.isCollect) {
        let form = new FormData()
        form.append('ShopID', this.dataId)
        followShop(form).then(res => {
          if (res.code == 0) {
            this.$toast.success(this.$t('guan-zhu-cheng-gong'))
            this.isCollect = true
          } else {
            this.$toast.fail(res.msg)
          }
        })
      }
    },
    toGoodsDetail(data) {
      sessionStorage.setItem('goodsDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    },
  }
}
</script>