<template>
  <div class=" withdraw-page   ">
    <top-bar :title="$t('ti-xian')"  :isWhite="true" @back="back"></top-bar>

    <div class="top">
      <div class="box">
        <div class="title flex-center-between">
          <div>{{ $t('ti-xian-lei-xing') }}</div>
          <div>{{ $t('yueti-qu') }}</div>
        </div>
        <div class="label">{{ $t('ti-xian-jin-e') }}</div>
        <div class="form-item flex-center">
          <div class="form-label">$</div>
          <div class="input flex-1">
            <input type="number" inputmode="decimal" v-model="form.money" :placeholder="$t('qing-shu-ru-ti-xian-jin-e')">
          </div>
        </div>
        <div class="desc">
          {{ $t('ke-ti-xian-jine') }} <span class="red">${{userMoney}}</span>
        </div>
      </div>
    </div>

    <div class="bank-line flex-center-between" @click="toBank">
      <div class="name">{{ $t('yin-hang-ka') }}</div>
      <div class="card-no flex-center" v-if="bankInfo.ID">
        <span>{{bankInfo.CardNumber}}</span>
        <Icon name="arrow" size="16"></Icon>
      </div>
      <div class="card-no flex-center" v-else>
        {{ $t('xuan-ze-yin-hang-ka') }} <Icon name="arrow" size="16"></Icon>
      </div>
    </div>

    <div class="bottom-btn flex-column-center">
      <Button @click="submit">{{ $t('ti-xian-zhi-yin-hang-ka') }}</Button>
      <div class="desc">{{ $t('zui-di-ti-xian-jin-e') }}{{withdraw_min}}</div>
    </div>

    <Popup v-model="showPopup" position="bottom">
      <div class="password-modal">
        <div class="title flex-center-between">
          <div class="icon"></div>
          <div>{{ $t('yueti-xian') }}</div>
          <div class="icon" @click="cancel">
            <Icon name="cross" size="16"></Icon>
          </div>
        </div>

        <div class="password-input-box">
          <PasswordInput v-model="form.password" :gutter="10" :focused="true"  ></PasswordInput>
          <div class="desc">{{ $t('qing-shu-ru-zhi-fu-mi-ma') }}</div>
          <div class="keyboard-box">
            <NumberKeyboard v-model="form.password" :show="showKeyboard" maxlength="6" @input="inputPassword"></NumberKeyboard>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Button, Icon, Popup,  PasswordInput, NumberKeyboard } from 'vant'
import topBar from '@/components/topBar'
import { wallet, bankList, doWithdraw } from  '@/api/user'
export default {
  components: {
    topBar,
    Button,
    Icon,
    Popup,
    PasswordInput,
    NumberKeyboard
  },
  data() {
    return {
      showPopup: false,
      showKeyboard: true,
      form: {
        money: '',
        password: ''
      },
      userMoney: 0,
      payIndex: 0,
      emptyUrl: require('../../assets/imgs/empty2.png'),
      hideAdd: false,
      addressList: [],
      isInit: false,
      bankInfo: {}
    }
  },
  computed: {
    withdraw_min(){
      return this.$store.state.withdraw_min
    }
  },
  mounted() {
    this.init()

    let bankData = sessionStorage.getItem('bankData')
    let isChoose = sessionStorage.getItem('chooseBank')
    if (isChoose == 1) {
      this.bankInfo = JSON.parse(bankData)
      sessionStorage.setItem('chooseBank', 2)
    } else {
      this.initBank()
    }
  },
  methods: {
    init() {
      wallet().then(res => {
        let data = res.data.Items[0]
        if (data) {
          this.userMoney = (data.Balance - data.Freeze).toFixed(2)
        }
      })


    },
    initBank() {
       bankList().then(res => {
        let list = res.data.Items
        if (list.length > 0) {
          this.bankInfo = list[0]
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    submit() {
      if (!this.form.money) {
        this.$toast.fail(this.$t('qing-shu-ru-ti-xian-jin-e'))
        return
      }
      if (!this.bankInfo.ID) {
        this.$toast.fail(this.$t('qing-xuan-ze-yin-hang-ka'))
        return
      }
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    inputPassword() {
      setTimeout(() => {
        if (this.form.password && this.form.password.length == 6) {
          let form = new FormData()
          form.append('Price', this.form.money)
          form.append('PayPassword', this.form.password)
          form.append('WithdrawType', 'bank')
          form.append('BankId', this.bankInfo.ID)
          doWithdraw(form).then(res => {
            if (res.code == 0) {
              this.$toast.success(this.$t('shen-qing-cheng-gong'))
              this.showPopup = false
              this.form.money = ''
              this.form.password = ''
              this.init()
            } else {
              this.$toast.fail(res.msg)
            }
          })
        }
      }, 300)
    },
    toBank() {
      this.$router.push({
        name: 'bank',
        query: {
          type: 1
        }
      })
    }
  }
}
</script>