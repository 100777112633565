<template>
  <div class="login-page">
    <top-bar :title="$t('deng-lu')" :isWhite="true" @back="back"></top-bar>
    <div class="logo" v-show="step == 1">
      <img :src="logoUrl" alt="" v-if="logo === ''">
      <img :src="logo" alt="" v-else>
    </div>

    <div class="page-title">{{ $t('huan-ying-deng-lu-woshop-kua-jing-shang-cheng') }}</div>

    <div class="login-tab flex-center" :class="`bg${tabIndex}`">
      <div class="tab flex-center" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{ $t('shou-ji-deng-lu') }}</div>
      <div class="tab flex-center" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{ $t('you-xiang-deng-lu') }}</div>
    </div>
    <div class="login-form">

      <div class="form-item  flex-center" v-show="step == 1 && tabIndex == 1">
        <div class="pre flex-center" @click="changePre">
          {{phonePre}}
          <i class="iconfont icon-down"></i>
        </div>
        <div class="flex-1">
          <div class="input clear">
            <input type="tel" v-model="form.phone" :placeholder="$t('qing-shu-ru-shou-ji-hao')">
          </div>
        </div>
      </div>

      <div class="form-item  flex-center" v-show="step == 1 && tabIndex == 2">
        <div class="flex-1">
          <div class="input ">
            <input type="text" v-model="form.email" :placeholder="$t('qing-shu-ru-you-xiang')">
          </div>
        </div>
      </div>


      <div class="form-item flex-center">
        <div class="input">
          <input type="password" v-model="form.password" :placeholder="$t('qing-shu-ru-mi-ma')">
        </div>
      </div>

       <Checkbox v-model="checked" checked-color="#fa3534" icon-size="14px">
        <div>
          {{ $t('wo-yi-yue-du-bing-tong-yi') }} <span>{{ $t('yong-hu-yin-si-xie-yi') }}</span>
        </div>
       </Checkbox>

      <Button class="login-btn" @click="submit">{{ $t('deng-lu') }}</Button>
    </div>


    <div class="flex-center-between desc">
      <div class="register" @click="toPath('register')">
        <span>{{ $t('xin-yong-hu-zhu-ce') }}</span>
      </div>
      <div class="register" @click="toPath('onlineService')">
        <span>{{ $t('wang-ji-mi-ma-0') }}</span>
      </div>
    </div>


   <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-between">
          <icon name="arrow-left" size="20" color="#000" @click="cancel"></icon>
        </div>
        <div class="group-list login" v-for="(item, i) in countryList" :key="i">
          <div class="country-title" :id="item.id">{{item.title}}</div>
          <div class="list flex-center-start" :class="child.key == phonePre ? 'active' : ''" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
              <span>{{getCountryName(child.title)}}</span>
            </div>
            <div class="key">
              {{child.key}}
            </div>
          </div>
        </div>
        <div class="fast-link">
          <div class="link" v-for="(item, i) in linkList" :key="i" @click="toFastPage(item)">
            {{item.title}}
          </div>
        </div>
			</div>
    </Popup>
  </div>
</template>
<script>
  import { Button, Popup, Icon, Divider, Checkbox  } from 'vant'
  import { userLogin } from '@/api/login'
  import { setToken, setUser, setUserId } from '@/utils/auth'
  import topBar from '@/components/topBar'
  export default {
    components: {
      Button,
      Popup,
      Icon,
      Divider,
      Checkbox,
      topBar
    },
    data() {
      return {
        tabIndex: 1,
        step: 1,
        form: {
          phone: '',
          email: '',
          password: '',
          Fingerprint: ''
        },
        keyword: '',
        phonePre: '+66',
        // searchUrl: require('../../assets/imgs/icon-search.png'),
        // langUrl: require('../../assets/imgs/icon-lang.png'),
        // kefuUrl: require('../../assets/imgs/icon-kefu2.png'),
        logoUrl: require('../../assets/imgs/logo.png'),
        showPopup: false,
        countryList: [],
        linkList: [],
        showRole: false,
        checked: true,
        userDataBak: {}
      }
    },
    computed: {
      countryListBak() {
        return this.$store.state.countryList
      },
      logo() {
        return this.$store.state.logo
      },
    },
    mounted() {
      this.countryList = [].concat(this.countryListBak)
      this.linkList = this.countryList.filter(v => {
        return v.id != 'common'
      })
    },
    methods: {
      back() {
        this.$router.push({
          name: 'index'
        })
      },
      toPath(path) {
        if (path) {
          this.$router.push({
            name: path
          })
        }
      },
      changeTab(index) {
        this.tabIndex = index
      },
      submit() {
        if (!this.checked) {
          this.$toast.fail(this.$t('qing-gou-xuan-yong-hu-yin-si-xie-yi'))
          return
        }
        if (this.tabIndex == 1 && !this.form.phone ) {
          this.$toast.fail(this.$t('qing-shu-ru-shou-ji-hao-0'))
          return
        }
        if (this.tabIndex == 2 && !this.form.email ) {
          this.$toast.fail(this.$t('qing-shu-ru-you-xiang'))
          return
        }
        if (!this.form.password) {
          this.$toast.fail(this.$t('qing-shu-ru-mi-ma'))
          return
        }
        let form = new FormData()
        form.append('Account', this.tabIndex == 1 ? this.phonePre + this.form.phone : this.form.email)
        form.append('Password', this.form.password)
        userLogin(form).then(res => {
          if (res.code == 0) {
            setToken(res.data.token)
            setUser(this.phonePre + this.form.phone)
            setUserId(res.data.id)
            this.$router.push({
              name: 'index'
            })
          } else {
            this.$toast.fail(res.msg)
          }
        })
      },
      cancel() {
        this.showPopup = false
      },
      changePre() {
        this.showPopup = true
      },
      chooseCountry(data) {
        this.phonePre = data.key
        this.showPopup = false
      },
      getCountryName(name) {
        if (!name) {
          return ''
        }
        let newName = ''
        newName = name.substring(0, name.indexOf('(') - 1)
        return newName
      },
      searchCountry() {
        if (this.keyword) {
          this.countryList = this.countryListBak.map(v => {
            let children = v.children.filter(val => {
              return val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            })
            if (children.length > 0) {
              return {
                children: children
              }
            } else {
              return {
                children: []
              }
            }
          })
        } else {
          this.countryList = [].concat(this.countryListBak)
        }
      },
      toFastPage(key) {
        let dom = document.getElementById(key.id)
        let parentDom = document.querySelector('.country-list')
        if (dom && parentDom) {
          let domTop = dom.offsetTop
          parentDom.scrollTo({
            top: domTop - 124
          })
        }
      },
    },
  }
</script>