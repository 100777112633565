<template>
	<div class="address-page">
		<top-bar :title="$t('xin-zeng-yin-hang-ka')" :isWhite="true" @back="back"></top-bar>

		<div class="default-form big">
      <div class="form-item flex-center-between">
        <div class="form-label">{{ $t('xing-ming-1') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.Name" :placeholder="$t('qing-shu-ru-xing-ming-0')">
        </div>
      </div>



			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('yin-hang-ka-hao') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.CardNumber" :placeholder="$t('qing-shu-ru-yin-hang-ka-hao')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('suo-shu-yin-hang') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.BankName" :placeholder="$t('qing-shu-ru-suo-shu-yin-hang')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('kai-hu-hang') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.OpeningBank" :placeholder="$t('qing-shu-ru-kai-hu-hang')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('zhi-hang-ming-cheng') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.BranchName" :placeholder="$t('qing-shu-ru-zhi-hang-ming-cheng')">
        </div>
      </div>

			<div class="form-desc mt-10">
				{{ $t('yue-du') }}	<span>{{ $t('zhi-fu-zhang-hu-fu-wu-xie-yi') }}</span>
				<span>{{ $t('zhi-fu-zhang-hu-yin-si-ce-lve') }}</span>
			</div>

			<div class="form-btn">
        <Button @click="submit" size="24px">{{ $t('bang-ding-yin-hang-ka') }}</Button>
      </div>

		</div>
		

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Switch as vanSwitch  } from 'vant'
import { addBank } from '@/api/user'
export default {
	components: {
    Popup,
    Button,
		topBar,
		vanSwitch 
  },
  data() {
		return {
			form: {
				Name: '',
				CardNumber: '',
				BankName: '',
				OpeningBank: '',
				BranchName: '',
			},
			checked: false,
			showRecharge: false,
      rechargeIndex: 0,
			rechargeInfo: {},
			rechargeInfoBak: {},
			addressData: []
		}
	},
  mounted() {

		this.init()
  },
	methods: {
		init() {
		},
		back() {
			this.$router.go(-1)
		},
		chooseRecharge() {
      this.showRecharge = true
    },
    changeRecharge(data, i) {
      this.rechargeIndex = i
			this.form.protocol = data.key
    },
		cancel() {
			this.showRecharge = false
		},
		submit() {
			if (!this.form.Name) {
				this.$toast.fail(this.$t('qing-shu-ru-xing-ming-1'))
				return
			}
			if (!this.form.CardNumber) {
				this.$toast.fail(this.$t('qing-shu-ru-yin-hang-ka-hao-0'))
				return
			}
			if (!this.form.BankName) {
				this.$toast.fail(this.$t('qing-shu-ru-suo-shu-yin-hang-0'))
				return
			}
			if (!this.form.OpeningBank) {
				this.$toast.fail(this.$t('qing-shu-ru-kai-hu-hang-0'))
				return
			}
			if (!this.form.BranchName) {
				this.$toast.fail(this.$t('qing-shu-ru-zhi-hang-ming-cheng-0'))
				return
			}
			let form = new FormData()
			form.append('Name', this.form.Name)
			form.append('CardNumber', this.form.CardNumber)
			form.append('BankName', this.form.BankName)
			form.append('OpeningBank', this.form.OpeningBank)
			form.append('BranchName', this.form.BranchName)
			addBank(form).then(res => {
				if (res.code == 0) {
					this.$toast.success(this.$t('xin-zeng-cheng-gong'))
					setTimeout(() => {
						this.back()
					}, 1500);
				} else {
				 this.$toast.fail(res.msg)
				}
			})
		}
	}
}
</script>