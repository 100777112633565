<template>
  <div class="order-detail-page">
    <top-bar :title="$t('ding-dan-xiang-qing-0')" :isWhite="true" @back="back"></top-bar>
    <div class="top flex-center-between">
      <div class="status">
        {{getStatusName(orderDetail.Status)}}
      </div>
      <div>
        <img :src="orderUrl" alt="">
      </div>
    </div>

    <div class="address-info flex-center">
      <div class="icon flex-center">
        <Icon name="location-o" color="#fff" size="22"/>
      </div>
      <div class="info flex-1">
        <div class="name">{{orderDetail.ShippingAddress.FullName}} {{orderDetail.ShippingAddress.Phone}}</div>
        <div class="address">{{orderDetail.ShippingAddress.Country}} {{orderDetail.ShippingAddress.Province}} {{orderDetail.ShippingAddress.City}} {{orderDetail.ShippingAddress.Address}}</div>
      </div>
    </div>

    <div class="goods-info">
      <div class="shop">{{orderDetail.Shop.Name}}</div>
      <div class="flex-center-between goods" v-for="(good, i) in orderDetail.OrderProduct" :key="i">
        <div class="img">
          <img :src="good.BusinessProduct.MainImage" alt="">
        </div>
        <div class="flex-1">
          <div class="name">{{good.BusinessProduct.ProductTranslation ? good.BusinessProduct.ProductTranslation[0].Name : ''}}</div>
          <div class="option">{{good.optionName}}</div>
        </div>
        <div class="price">
          <div>{{good.Price}}</div>
          <div class="count">x {{good.Number}}</div>
        </div>
      </div>
    </div>

    <div class="pay-info">
      <div class="line">
        <div class="label">{{ $t('zhi-fu-fang-shi-0') }}</div>
        <div class="value">{{ $t('yuezhi-fu') }}</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('shang-pin-jia-ge-0') }}</div>
        <div class="value">{{orderDetail.TotalAmount}}</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('wu-liu-gong-si') }}</div>
        <div class="value">{{ orderDetail.Logistics.Company }}</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('yun-fei-0') }}</div>
        <div class="value">0</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('you-hui-quan') }}</div>
        <div class="value">0.00</div>
      </div>
      <!-- <div class="line">
        <div class="label">优惠信息</div>
        <div class="value">满200减10</div>
      </div> -->
      <div class="line big">
        <div class="label">{{ $t('ying-fu-jin-e') }}</div>
        <div class="value">{{orderDetail.TotalAmount}}</div>
      </div>
    </div>


    <div class="pay-info">
      <div class="line">
        <div class="label">{{ $t('ding-dan-hao-0') }}</div>
        <div class="value">{{orderDetail.OrderNo}}</div>
      </div>
      <!-- <div class="line">
        <div class="label">交易编号</div>
        <div class="value">901283081293081231231</div>
      </div> -->
      <div class="line">
        <div class="label">{{ $t('chuang-jian-shi-jian') }}</div>
        <div class="value">{{orderDetail.CreatedAt}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon, Popup, PullRefresh, List } from 'vant'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PullRefresh,
    List
  },
  data() {
    return {
      tabIndex: 0,
      orderList: [],
      page: {
        page: 1,
        limit: 100,
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      orderUrl: require('../../assets/imgs/icon-order.png'),
      orderDetail: {
        ShippingAddress: {}
      }
    }
  },
  computed: {
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init() {
      let data = sessionStorage.getItem('orderDetailData')

      if (data) {
        this.orderDetail = JSON.parse(data)
      }
    },
    getStatusName(status) {
      let name = ''

      switch(status + '') {
        case '0': name = this.$t('chu-li-zhong'); break;
        case '1': name = this.$t('dai-fa-huo'); break;
        case '2': name = this.$t('dai-shou-huo'); break;
        case '3': name = this.$t('yi-qian-shou'); break;
        case '4': name = this.$t('yi-wan-cheng'); break;
        case '5': name = this.$t('yi-qu-xiao'); break;
        case '6': name = this.$t('yi-guan-bi'); break;
        case '7': name = this.$t('tui-huo-zhong'); break;
        case '8': name = this.$t('tui-kuan-cheng-gong'); break;
        default: break;
      }
      return name
    },
  }
}
</script>