<template>
  <div class="goods-detail-page">
    <div class="top flex-center-between">
      <div class="flex-center left">
        <i class="iconfont icon-left" @click="back"></i>
        <div class="tabs flex-center">
          <div class="tab" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{ $t('shang-pin') }}</div>
          <div class="tab" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{ $t('ping-jia') }}</div>
          <div class="tab" :class="tabIndex == 3 ? 'active' : ''" @click="changeTab(3)">{{ $t('xiang-qing') }}</div>
        </div>
      </div>
      <div class="icon flex-center">
        <img :src="shareUrl" alt="">
      </div>
    </div>

    <div class="goods-info">
      <div class="banner">
        <Swipe :autoplay="3000" :show-indicators="false">
          <SwipeItem v-for="(item, i) in bannerList" :key="i">
            <div class="img">
              <img :src="item" alt="">
            </div>
          </SwipeItem>
        </Swipe>
      </div>

      <div class="info">
        <div class="price">
          ${{ optionPrice || detailData.SalePrice}} <span class="small">{{ $t('yuan-jia') }}
            ${{MinPrice || detailData.MarketPrice}}
            </span>
        </div>
        <div class="goods-name">{{detailData.ProductTranslation && detailData.ProductTranslation[0] ? detailData.ProductTranslation[0].Name : ''}}</div>
      </div>

      <div class="option-box">
        <div class="flex-center-between line">
          <div class="label">{{ $t('fa-huo') }}<span class="gray">{{ $t('bao-you') }}</span></div>
          <div class="label">{{ $t('xiao-liang') }}：{{ detailData.Sales }}</div>
        </div>
        <div class="flex-center-between line" @click="chooseOption(false)" v-if="detailData.ProductParametersRelation">
          <div class="label">{{chooseOptionList.length > 0 ? $t('yi-xuan') : $t('qing-xuan-ze')}} <span class="gray">{{chooseOptionList.length > 0 ?  `${chooseOptionName}` : `${optionName}`}}</span></div>
          <div class="icon">
            <i class="iconfont icon-right"></i>
          </div>
        </div>
      </div>

      <div class="shop-box">
        <div class="flex-center shop" @click="toShop">
          <div class="logo">
            <img :src="detailData.Shop.Logo || logoUrl" alt="">
            <div class="level">
               <img :src="vipUrl" alt="">
            </div>
          </div>
          <div class="flex-1">
            <div class="name">{{detailData.Shop.Name}}</div>
            <div  class="desc">{{detailData.Shop.User ? (detailData.Shop.User.Phone || detailData.Shop.User.Email) : ''}}</div>
          </div>
          <div class="icon">
            <i class="iconfont icon-right"></i>
          </div>
        </div>
        <div class="goods-list flex-center" >
          <div class="goods flex-column-center" v-for="(item, i) in goodsList" :key="i" @click="toGoodsDetail(item)">
            <img :src="item.MainImage" alt="">
            <div class="goods-name">{{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}</div>
          </div>
        </div>

        <div class="btn-style flex-center">
          <Button @click="toPath('chat', {uid: shopUId, name: detailData.Shop.Name})">{{ $t('lian-xi-ke-fu') }}</Button>
        </div>
      </div>

      <div class="evalute-box">
        <div class="flex-center-between"  @click="toEvalute">
          <div class="title">
            {{ $t('ping-jia-0') }} {{evaluteList.length > 99 ? '99+' : evaluteList.length}}
          </div>
          <div class="icon">
            <i class="iconfont icon-right"></i>
          </div>
        </div>

        <div class="evalute-list">
          <div class="list" v-for="(item, i) in evaluteList" :key="i">
            <div class="flex-center">
              <div class="header">
                <img :src="logoUrl" alt="">
              </div>
              <div class="flex-1">
                <div class="name">{{item.name}}</div>
                <div class="star">
                  <i class="iconfont icon-star-filled" v-for="j in item.Score" :key="j"></i>
                </div>
              </div>
              <div class="time">{{item.CreatedAt}}</div>
            </div>
            <div class="content">{{item.Content}}</div>
          </div>
        </div>
      </div>

      <div class="detail-info">
        <div class="title">{{ $t('xiang-qing') }}</div>
        <div class="content" v-if="detailData.ProductTranslation && detailData.ProductTranslation[0]" v-html="detailData.ProductTranslation[0].Detail"></div>
      </div>
    </div>

    <div class="bottom flex-center-between">
      <div class="flex-center flex-1">
        <div class="menu flex-column-center" @click="toPath('chat', {uid: shopUId, name: detailData.Shop.Name})">
          <img :src="kefuUrl" alt="">
          <span>{{ $t('ke-fu') }}</span>
        </div>
        <div class="menu flex-column-center" @click="toShop">
          <img :src="shopUrl" alt="">
          <span>{{ $t('dian-pu') }}</span>
        </div>
        <div class="menu flex-column-center" @click="toPath('car')">
          <img :src="carUrl" alt="">
          <span>{{ $t('gou-wu-che-0') }}</span>
        </div>
      </div>
      <div class="flex-center btn-style">
        <Button class="yellow" @click="chooseOption(1)">{{ $t('jia-ru-gou-wu-che') }}</Button>
        <Button class="red" @click="chooseOption(2)">{{ $t('li-ji-gou-mai-0') }}</Button>
      </div>
    </div>

    <Popup v-model="showPopup" position="bottom" class="bottom-popup">
      <div class="option-modal">
        <div class="title flex-center-end">
          <i class="iconfont icon-close" @click="cancel"></i>
        </div>

        <div class="goods-info flex-center">
          <div class="img">
            <img :src="detailData.MainImage" alt="">
          </div>
          <div class="flex-1 box">
            <div class="price">${{optionPrice || detailData.SalePrice}}</div>
            <div class="count">{{ $t('ku-cun') }}
              {{optionStock || detailData.Stock}}
            </div>
            <div class="option-name" v-if="optionList.length > 0">{{chooseOptionList.length > 0 ? `${ $t('yi-xuan')} ${chooseOptionName}` : `${ $t('qing-xuan-ze')} ${optionName}`}}</div>
          </div>
        </div>

        <div class="option-list">
          <div class="options" v-for="(item, i) in optionList" :key="i">
            <div class="option-title">{{item.Name}}</div>
            <div class="flex-wrap">
              <div class="option flex-center" :class="option.checked ? 'active' : ''" v-for="(option, index) in item.options" :key="index" @click="changeOption(i, index, item, option)">
                {{option.Value}}
              </div>
            </div>
          </div>
        </div>

        <div class="goods-count flex-center-between">
          <div class="label">{{ $t('gou-mai-shu-liang-0') }}</div>
          <div class="input-box flex-center">
            <div class="minus flex-center" @click="minusCount">
              <Icon name="minus" />
            </div>
            <div class="count flex-center">{{count}}</div>
            <div class="plus flex-center" @click="plusCount">
              <Icon name="plus" />
            </div>
          </div>
        </div>

        <div class="btn-style" v-show="actionType">
          <Button @click="submit">{{ $t('que-ding') }}</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { shopGoods } from '@/api/index'
import { productEvaluteList, addCar } from '@/api/shop'
import { Button, SwipeItem, Swipe, Popup, Icon } from 'vant'
export default {
  components: {
    Button,
    Swipe,
    SwipeItem,
    Popup,
    Icon
  },
  data() {
    return {
      dataId: '',
      ShopID: '',
      shopUId: '',
      tabIndex: 1,
      showPopup: false,
      actionType: '',
      shareUrl: require('../../assets/imgs/icon-share.svg'),
      kefuUrl: require('../../assets/imgs/icon-kefu.svg'),
      shopUrl: require('../../assets/imgs/icon-shop.svg'),
      carUrl: require('../../assets/imgs/icon-car.svg'),
      vipUrl: require('../../assets/imgs/icon-vip.svg'),
      logoUrl: require('../../assets/imgs/logo.png'),
      goodUrl: require('../../assets/imgs/goods1.jpg'),
      count: 1,
      optionList: [ ],
      bannerList: [ ],
      optionName: this.$t('yan-se-chi-cun'),
      chooseOptionList: [],
      optionPriceList: [],
      chooseOptionName: '',
      optionPrice: '',
      MinPrice: '',
      optionStock: '',
      goodsList: [],
      evaluteList: [ ],
      Content: '',
      detailData: {
        ProductTranslation: [],
        ProductParametersRelation: null,
        Shop: {}
      },
      optionInfo: null
    }
  },
  watch: {
    $route(val) {
      let id = this.$route.query.id
      if (this.dataId && id != this.dataId) {
        this.dataId = this.$route.query.id
        this.ShopID = this.$route.query.shopID
        this.initDetail()
        this.initEvalute()
        this.initGoods()
      }
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.ShopID = this.$route.query.shopID
    this.initDetail()
    this.initEvalute()
    this.initGoods()
  },
  methods: {
    initGoods() {
       let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 5)
      form.append('ShopID', this.ShopID)
      shopGoods(form).then(res => {
        let list = res.data.Items
        this.goodsList = list.filter(v => {
          return v.ID != this.dataId
        }).slice(0, 4)
      })
    },
    initEvalute() {
      let form = new FormData()
      form.append('ProductID', this.dataId)
      productEvaluteList(form).then(res => {
        this.evaluteList = res.data.Items
      })
    },
    initDetail() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      form.append('ID', this.dataId)
      shopGoods(form).then(res => {
        let list = res.data.Items
        this.detailData = list[0]
        this.shopUId = this.detailData.Shop.UId
        this.bannerList = JSON.parse(this.detailData.Images)
        let optionList = this.detailData.ProductSpecifications || []
        this.optionPriceList = this.detailData.BusinessProductSpecificationsRelation || []
        this.optionList = this.resetOption(optionList)
        this.optionName = this.optionList.map(v => {
          return v.Name
        }).join()
      })
    },
    resetOption(list) {
      let newList = []
      list.forEach(v => {
        let index = newList.findIndex(val => {
          return val.Name == v.Name
        })
        if (index > -1) {
          newList[index].options.push(v)
        } else {
          newList.push({
            Name: v.Name,
            options: [v]
          })
        }
      })
      return newList
    },
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
      let top = i == 1 ? 0 : (i == 2 ? 800 : 1030)
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      })
    },
    chooseOption(type) {
      this.showPopup = true
      this.actionType = type
    },
    changeOption(i, index, classify, data) {
      let optionIndex = this.chooseOptionList.findIndex(v => {
        return v.Name == classify.Name
      })
      if (optionIndex > -1) {
        let oldData = this.chooseOptionList[optionIndex].option
        let oldIndex = this.optionList[i].options.findIndex(v => {
          return v.Value == oldData.Value
        })

        this.$set(this.optionList[i].options[oldIndex], 'checked', false)
        this.$set(this.optionList[i].options[index], 'checked', true)
        this.chooseOptionList[optionIndex].option = data
      } else {
        this.chooseOptionList.push({
          Name: classify.Name,
          option: data
        })
        this.$set(this.optionList[i].options[index], 'checked', true)
      }

      this.getOptionName()
      this.getOptionPrice()
    },
    getOptionName() {
      let nameList = []
      this.chooseOptionList.forEach(v => {
        let name = v.Name + '：' + v.option.Value
        nameList.push(name)
      })
      this.chooseOptionName = nameList.join(';')
    },
    getOptionPrice() {
      let checkedIds = this.chooseOptionList.map(v => {
        return v.option.ID
      }).sort((a, b) => {
        return a - b
      }).join('-')
      let index = this.optionPriceList.findIndex(v => {
        let priceId = v.SpecificationsID.split('-').sort((a, b) => {
          return a - b
        }).join('-')
        return priceId == checkedIds
      })
      if (index > -1) {
        this.optionPrice = this.optionPriceList[index].Price
        this.MinPrice = this.optionPriceList[index].MinPrice
        this.optionStock = this.optionPriceList[index].Stock
        this.optionInfo = this.optionPriceList[index]
      }
    },
    minusCount() {
      if (this.count > 1) {
        this.count -=1
      }
    },
    plusCount() {
      this.count += 1
    },
    cancel() {
      this.showPopup = false
    },
    submit() {
      let form = new FormData()
      if (this.actionType == 1) {// 加入购物车
        form.append('BusinessProductID', this.dataId)
        form.append('BusinessProductSpecificationsRelationID', this.optionInfo ? this.optionInfo.ID : '')
        form.append('Number', this.count)
        addCar(form).then(res => {
          if (res.code == 0) {
            this.$toast.success(this.$t('jia-ru-gou-wu-che-cheng-gong'))
            this.showPopup = false
          } else {
            this.$toast.fail(res.msg)
          }
        })
      } else { // 立即购买
        sessionStorage.setItem('orderData', JSON.stringify([{
          Shop: this.detailData.Shop,
          goodsList: [{
            ...this.detailData,
            count: this.count,
            optionName: ''
          }]
        }]))
        this.$router.push({
          name: 'order'
        })
      }
    },
    toShop() {
      let id = this.detailData.ShopID
      this.$router.push({
        name: 'shop',
        query: {
          id: id
        }
      })
    },
    toPath(path, query) {
      if (path) {
        this.$router.push({
          name: path,
          query: query ? query : {}
        })
      }
    },
    toEvalute() {
      this.$router.push({
        name: 'goodsEvaluteList',
        query: {
          id: this.dataId
        }
      })
    },
    toGoodsDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID,
          shopID: data.ShopID
        }
      })
    }
  }
}
</script>
<style scoped>
.a-spacing-mini {
  font-size: 14px !important;
}

</style>