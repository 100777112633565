<template>
	<div class="address-page">
		<top-bar :title="$t('xin-zeng-shou-huo-di-zhi')" :isWhite="true" @back="back"></top-bar>

		<div class="default-form big">
      <div class="form-item flex-center-between">
        <div class="form-label">{{ $t('shou-huo-ren') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.FullName" :placeholder="$t('qing-tian-xie-shou-huo-ren-xing-ming')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('lian-xi-dian-hua') }}</div>
        <div class="form-input">
          <input type="tel" v-model="form.Phone" :placeholder="$t('qing-shu-ru-lian-xi-dian-hua')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('suo-zai-guo-jia') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.Country" :placeholder="$t('qing-shu-ru-suo-zai-guo-jia')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('suo-zai-sheng-fen') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.Province" :placeholder="$t('qing-shu-ru-suo-zai-sheng-fen')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('suo-zai-cheng-shi') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.City" :placeholder="$t('qing-shu-ru-suo-zai-cheng-shi')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('xiang-xi-di-zhi') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.Address" :placeholder="$t('jie-dao-lou-pai-hao-deng')">
        </div>
      </div>

			<div class="form-item flex-center-between">
        <div class="form-label">{{ $t('you-zheng-bian-ma-0') }}</div>
        <div class="form-input">
          <input type="text" v-model="form.ZipCode" :placeholder="$t('qing-shu-ru-you-zheng-bian-ma')">
        </div>
      </div>

			<!-- <div class="form-item flex-center-between">
        <div class="form-label">设为默认地址</div>
        <div class="form-input">
          <vanSwitch v-model="checked"></vanSwitch>
        </div>
      </div> -->

			<div class="form-btn">
        <Button @click="submit" size="24px">{{ $t('bao-cun-di-zhi') }}</Button>
      </div>

		</div>
		

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Switch as vanSwitch  } from 'vant'
import { addAddress } from '@/api/setting'
export default {
	components: {
    Popup,
    Button,
		topBar,
		vanSwitch 
  },
  data() {
		return {
			form: {
				FullName: '',
				Country: '',
				Phone: '',
				Province: '',
				City: '',
				Address: '',
				ZipCode: '',
			},
			checked: false,
			showRecharge: false,
      rechargeIndex: 0,
			rechargeInfo: {},
			rechargeInfoBak: {},
			addressData: []
		}
	},
  mounted() {

		this.init()
  },
	methods: {
		init() {
		},
		back() {
			this.$router.go(-1)
		},
		chooseRecharge() {
      this.showRecharge = true
    },
    changeRecharge(data, i) {
      this.rechargeIndex = i
			this.form.protocol = data.key
    },
		cancel() {
			this.showRecharge = false
		},
		submit() {
			if (!this.form.FullName) {
				this.$toast.fail(this.$t('qing-tian-xie-shou-huo-ren-xing-ming-0'))
				return
			}
			if (!this.form.Phone) {
				this.$toast.fail(this.$t('qing-shu-ru-lian-xi-dian-hua-0'))
				return
			}
			if (!this.form.Country) {
				this.$toast.fail(this.$t('qing-shu-ru-suo-zai-guo-jia-0'))
				return
			}
			if (!this.form.Province) {
				this.$toast.fail(this.$t('qing-shu-ru-suo-zai-sheng-fen-0'))
				return
			}
			if (!this.form.City) {
				this.$toast.fail(this.$t('qing-shu-ru-suo-zai-cheng-shi-0'))
				return
			}
			if (!this.form.Address) {
				this.$toast.fail(this.$t('qing-shu-ru-xiang-xi-di-zhi'))
				return
			}
			if (!this.form.ZipCode) {
				this.$toast.fail(this.$t('qing-shu-ru-you-zheng-bian-ma'))
				return
			}

			let form = new FormData()
			form.append('FullName', this.form.FullName)
			form.append('Country', this.form.Country)
			form.append('Phone', this.form.Phone)
			form.append('Province', this.form.Province)
			form.append('City', this.form.City)
			form.append('Address', this.form.Address)
			form.append('ZipCode', this.form.ZipCode)
			addAddress(form).then(res => {
				if (res.code == 0) {
					this.$toast.success(this.$t('xin-zeng-cheng-gong'))
					setTimeout(() => {
						this.back()
					}, 1500);
				} else {
				 this.$toast.fail(res.msg)
				}
			})
		}
	}
}
</script>