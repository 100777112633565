<template>
  <div class="order-page">
    <top-bar :title="$t('li-ji-jie-suan')" :isWhite="true" @back="back"></top-bar>

    <div class="order-info">
      <div class="address-box flex-center">
        <div class="icon">
          <Icon name="location-o" />
        </div>
        <div class="flex-1" v-if="addressInfo && addressInfo.FullName" @click="toAddress">
          <div class="name">{{addressInfo.FullName}} {{addressInfo.Phone}}</div>
          <div class="country mt-5">{{addressInfo.Country}} {{addressInfo.Province}} {{addressInfo.City}}</div>
          <div class="address">{{addressInfo.Address}}</div>
        </div>
        <div class="flex-1 flex-center-start" v-else @click="toAddress">
          <Icon name="plus" size="18"/>
          <span> {{ $t('tian-jia-shou-huo-di-zhi') }}</span>
        </div>
      </div>

      <div class="shop-box" >
        <div class="shop" v-for="(item, i) in shoppingList" :key="i">
          <div class="shop-name flex-center-start">
            <Icon name="shop" size="16"/>
            {{item.Shop.Name}}
          </div>
          <div class="goods-list">
            <div class="goods flex-center" v-for="(good, index) in item.goodsList" :key="index">
              <div class="img">
                <img :src="good.MainImage" alt="">
              </div>
              <div class="flex-1">
                <div class="goods-name">{{good.ProductTranslation && good.ProductTranslation[0] ? good.ProductTranslation[0].Name : ''}}</div>
                <div class="option">{{good.optionName}}</div>
                <div class="price">{{good.optionPrice || good.SalePrice}}<span class="small">{{ $t('jian') }}</span></div>
                <div class="count">
                  {{ $t('gou-mai-shu-liang-1') }} {{good.count}}
                </div>
              </div>
            </div>
          </div>

          <div class="shipping-info">
            <div class="line flex-center-between">
              <div class="label">{{ $t('you-hui-quan') }}</div>
              <div class="count">0.00</div>
            </div>
            <div class="line flex-center-between">
              <div class="label">{{ $t('yun-fei-0') }}</div>
              <div class="count">{{ $t('bao-you') }}</div>
            </div>
            <div class="line flex-center-between">
              <div class="label">{{ $t('zong-ji-2') }}</div>
              <div class="count">{{getTotalPrice(item.goodsList)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom flex-center-between">
      <div class="price">{{allTotalPrice}}</div>
      <Button @click="choosePay">{{ $t('ti-jiao-ding-dan') }}</Button>
    </div>

    <Popup v-model="showPopup" position="bottom" class="bottom-popup">
      <div class="option-modal order-modal"> 
        <div class="title flex-center-between">
          <div class="left"></div>
          <div>{{ $t('shou-yin-tai') }}</div>
          <i class="iconfont icon-close" @click="cancel"></i>
        </div>

        <div class="price">{{allTotalPrice}}</div>
        <div class="pay-list">
          <div class="list flex-center-between" v-for="(item, i) in payList" :key="i" @click="changePay(item, i)">
            <div>
              <div class="flex-center">
                <img :src="item.icon" alt="">
                <span class="name">{{item.name}}</span>
              </div>
              <div class="desc" v-if="item.desc">{{item.desc}}</div>
            </div>
            <div>
              <Icon name="checked" size="18" color="#f25440" v-if="payIndex == i"/>
              <Icon name="circle"  size="18" color="#ccc" v-else/>
            </div>
          </div>
        </div>

        <div class="btn-style mt-60">
          <Button @click="toPay">{{ $t('que-ren-zhi-fu-0') }}</Button>
        </div>
      </div>
    </Popup>

    
    <Popup v-model="showPasswordPopup" position="bottom">
      <div class="password-modal">
        <div class="title red flex-center-between">
          <div class="icon"></div>
          <div class="count">{{allTotalPrice}}</div>
          <div class="icon">
            <Icon name="cross" size="16"></Icon>
          </div>
        </div>

        <div class="password-input-box">
          <PasswordInput v-model="form.password" :gutter="10" :focused="true"  ></PasswordInput>
          <div class="desc">{{ $t('qing-shu-ru-zhi-fu-mi-ma') }}</div>
          <div class="keyboard-box">
            <NumberKeyboard v-model="form.password" :show="showKeyboard" maxlength="6" @input="inputPassword"></NumberKeyboard>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Icon, Popup, PasswordInput, NumberKeyboard } from 'vant'
import topBar from '@/components/topBar'
import { getAddressList } from '@/api/setting'
import { addOrder, orderPay } from '@/api/shop'
export default {
  components: {
    topBar,
    Icon,
    Popup,
    PasswordInput,
    NumberKeyboard
  },
  data() {
    return {
      form: {
        password: ''
      },
      orderId: '',
      payIndex: 0,
      showPopup: false,
      showKeyboard: true,
      showPasswordPopup: false,
      addressInfo: {},
      shoppingList: [],
      allTotalPrice: 0,
      payList: [
        // {
        //   icon: require('../../assets/imgs/pay-1.png'),
        //   name: '支付宝'
        // },
        {
          icon: require('../../assets/imgs/pay-2.png'),
          name: this.$t('dang-qian-yu-e')
        },
        // {
        //   icon: require('../../assets/imgs/pay-3.png'),
        //   name: 'PayPal'
        // },
        // {
        //   icon: require('../../assets/imgs/pay-4.png'),
        //   name: 'USDT-TRC20',
        //   desc: 'USDT兑换美元'
        // },
        // {
        //   icon: require('../../assets/imgs/pay-5.png'),
        //   name: 'USDT-ERC20',
        //   desc: 'USDT兑换美元'
        // },
      ]
    }
  },
  mounted() {
    
    let data = sessionStorage.getItem('orderData')
    if (data) {
      this.shoppingList = JSON.parse(data)
      let total = 0
      this.shoppingList.forEach(v => {
        v.goodsList.forEach(val => {
          total += parseFloat(val.optionPrice || val.SalePrice) * val.count
        })
      })
      this.allTotalPrice = parseFloat(total).toFixed(2)
    }

    let addressData = sessionStorage.getItem('addressData')
    let isChoose = sessionStorage.getItem('chooseAddress')
    if (isChoose == 1) {
      this.addressInfo = JSON.parse(addressData)
      sessionStorage.setItem('chooseAddress', 2)
    } else {
      this.initAddress()
    }
  },
  methods: {
    initAddress() {
      getAddressList().then(res => {
        let list = res.data.Items
        if (list && list.length > 0) {
          let index = list.findIndex(v => {
            return v.IsSelected == 1
          })
          if (index === -1) {
            index = 0
          }
          this.addressInfo = list[index] || {}
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    getTotalPrice(data) {
      let total = 0
      data.forEach(v => {
        total += parseFloat(v.optionPrice || v.SalePrice) * v.count
      })
      return total.toFixed(2)
    },
    choosePay() {
      if (!this.addressInfo.ID) {
        this.$toast.fail(this.$t('qing-xuan-ze-shou-huo-di-zhi'))
        return
      }
      let list = []
      this.shoppingList.forEach(v => {
        v.goodsList.forEach(val => {
          let param = {
            BusinessProductID: val.ID,
            BusinessProductSpecificationsRelationID: val.BusinessProductSpecificationsRelation.ID,
            Number: val.count
          }
          list.push(param)
        })
      })
      // let form = new FormData()
      // form.append('ShippingAddressID', this.addressInfo.ID)
      // form.append('Lists', JSON.stringify(list))
      addOrder({
        ShippingAddressID: this.addressInfo.ID,
        Lists: list
      }).then(res => {
        if (res.code == 0) {
          this.orderId = res.data
          this.showPopup = true
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    cancel() {
      this.showPopup = false
    },
    changePay(data, i) {
      this.payIndex = i
    },
    toPay() {
      // this.showPopup = false
      this.showPasswordPopup = true
    },
    toAddress() {
      this.$router.push({
        name: 'address',
        query: {
          type: 1
        }
      })
    },
    inputPassword() {
      setTimeout(() => {
        if (this.form.password && this.form.password.length == 6) {
           this.doPay()
        }
      }, 300)
    },
    doPay( ) { // 支付
      let form = new FormData()
      form.append('OrderID', this.orderId)
      form.append('Password', this.form.password)
      orderPay(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('zhi-fu-cheng-gong'))
          this.showPasswordPopup = false
          this.showPopup = false
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>