<template>
  <div class="password-page">
     <top-bar :title="$t('zhi-fu-mi-ma-guan-li')" :isWhite="true"  @back="back"></top-bar>

    <div class="default-form">
      <div class="form-item flex-center-between" v-if="isSetPassword">
        <div class="form-label">{{ $t('jiu-mi-ma-0') }}</div>
        <div class="form-input">
          <input :type="showOldPassword ? 'text' : 'password'" v-model="form.oldPassword" :placeholder="$t('qing-shu-ru-jiu-mi-ma-0')">
        </div>
        <div class="form-adot" @click="changeShowOldPassword">
          <img :src="showOldPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>

      <div class="form-item flex-center-between">
        <div class="form-label">{{ isSetPassword ? $t('xin-zi-fu-mi-ma') : $t('zi-fu-mi-ma')}}</div>
        <div class="form-input">
          <input :type="showPassword ? 'text' : 'password'" v-model="form.password" :placeholder="isSetPassword ? $t('qing-shu-ru-xin-zhi-fu-mi-ma') : $t('qing-shu-ru-zhi-fu-mi-ma')">
        </div>
        <div class="form-adot" @click="changeShowPassword">
          <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>

      <div class="form-item flex-center-between">
        <div class="form-label">{{ $t('que-ren-mi-ma-mi-ma') }}</div>
        <div class="form-input">
          <input :type="showPassword2 ? 'text' : 'password'" v-model="form.password2" :placeholder="$t('qing-zai-ci-shu-ru-mi-ma')">
        </div>
        <div class="form-adot" @click="changeshowPassword2">
          <img :src="showPassword2 ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>

      <div class="form-btn">
        <Button @click="submit">{{ $t('que-ren-xiu-gai') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import { CheckPassword, setSafePassword, editSafePassword } from '@/api/setting'
import topBar from '@/components/topBar'
export default {
  components: {
    Button,
    Icon,
    topBar
  },
  data() {
    return {
      logoUrl: require('../../assets/imgs/logo.png'),
      eyeUrl: require('../../assets/imgs/icon-eye.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
      form: {
        oldPassword: '',
        password: '',
        password2: '',
      },
      showPassword: false,
      showPassword2: false,
      showOldPassword: false,
      isSetPassword: false
    }
  },
  mounted() {
    this.initCheck()
  },
  methods: {
    initCheck() {
      CheckPassword().then(res => {
        if (res.code === 0 && res.data) {
          this.isSetPassword = true
        } else {
          this.isSetPassword = false
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    changeShowOldPassword() {
      this.showOldPassword = !this.showOldPassword
    },
    changeShowPassword() {
      this.showPassword = !this.showPassword
    },
    changeshowPassword2() {
      this.showPassword2 = !this.showPassword2
    },
    submit() {
      if(this.isSetPassword) {
        if (!this.form.oldPassword) {
          this.$toast.fail(this.$t('qing-shu-ru-jiu-mi-ma-0'))
          return
        }
      }

      if (!this.form.password) {
        this.$toast.fail(this.isSetPassword ? this.$t('qing-shu-ru-xin-zhi-fu-mi-ma') : this.$t('qing-shu-ru-zhi-fu-mi-ma'))
        return
      }

      if (!this.form.password2) {
        this.$toast.fail(this.$t('qing-zai-ci-shu-ru-mi-ma'))
        return
      }

      let form = new FormData()
      if (this.isSetPassword) {
        form.append('OldPassword', this.form.oldPassword)
        form.append('Password', this.form.password)
        form.append('PasswordConfirm', this.form.password2)
        editSafePassword(form).then(res => {
          if (res.code == 0) {
            this.$toast.success(this.$t('xiu-gai-cheng-gong'))
          } else {
            this.$toast.fail(res.msg)
          }
        })
      } else {
        form.append('SafePassword', this.form.password)
        form.append('SafePasswordConfirm', this.form.password2)
        setSafePassword(form).then(res => {
          if (res.code == 0) {
            this.$toast.success(this.$t('she-zhi-cheng-gong'))
          } else {
            this.$toast.fail(res.msg)
          }
        })
      }
     
     
    },
  }
}
</script>